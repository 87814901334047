import React from 'react';
import { APIResource } from '../Services/APIResource/APIResource';
import { DisplayTextField } from '../Components/Display/DisplayTextField/DisplayTextField';
import DateFormatter from '../Services/DateFormatter';

export const postSaveRedirectToCertification = (resource, entity, queryParams) => {
    if(
        !(
            typeof queryParams !== 'object'
            || queryParams.get('certification-id') === undefined
            || queryParams.get('certification-id') === ''
            || queryParams.get('certification-id') === null
        )
    ){
        return '/resource/my_model_certification_campaigns/' +
            queryParams.get('certification-id') +
            '/detail';
    }
    return '/resource/' +
        resource.instanceId +
        '/' +
        entity.id +
        '/detail';
};

export const ApiResourceDefaultParams = {
    id: 'certifications',
    name: 'Certifications',
};

export default class CertificationAdmin {
    constructor() {
        this.configure();
    }

    configure() {
        let additionalLinkButton = (entity) => {
            if (!entity.model) {
                return [];
            }

            let model = entity.model;
            model = model.split('/');
            let modelId = model[model.length - 1];

            return [
                {
                    link:
                        '/resource/models/' +
                        modelId +
                        '/detail?tab=Certifications',
                    tooltip: 'Associated model',
                    icon: 'link',
                },
            ];
        };

        let resourceCertifications = new APIResource(ApiResourceDefaultParams);
        resourceCertifications
            .setFields({
                id: { title: 'ID' },
                user: {
                    title: 'Certifier',
                    type: 'user',
                    params: {
                        resource: 'users',
                        instanceId: 'users_mo',
                        displayField: 'toString',
                        editDisplayField: 'fullNameWithTeam',
                        sortField: 'lastName',
                        multi: false,
                        links: false,
                        endpoints: {
                            getAll: 'users/all-users/mo',
                        },
                    },
                },
                certifierTeam: {
                    title: 'Certifier team',
                    maxLength:255, 
                },
                certifierPosition: {
                    title: 'Certifier position',
                    maxLength:255, 
                },
                model: {
                    title: 'Model',
                    type: 'model',
                    params: {
                        resource: 'models',
                        instanceId: 'allModels',
                        multi: false,
                        links: true,
                        linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                        endpoints: {
                            getAll: 'models/all-models',
                        },
                    },
                    /*edit: (field, value, onChange, entity, routeParams) => {
                        if ((routeParams.modelId || value) && !entity.model) {
                            entity.model = routeParams.modelId ? '/api/models/' + routeParams.modelId : value;
                        }
                        return (
                            <EntitySelect
                                label="Model"
                                resourceId="models"
                                resourceLabel="toString"
                                value={entity.model}
                                onChange={onChange}
                                clearable={true}
                                returnApiPath={true}
                                multi={false}
                                issueButton={true}
                                filters={field.params.filters}
                            />
                        );
                    },*/
                },
                date: {
                    title: 'Certification date',
                    type: 'date',
                    helperTextDisplay: (entity) => {
                        if (entity.dateVerified === false) {
                            return {
                                type: 'warning',
                                icon: 'fa-exclamation-triangle',
                                text: 'Unverified',
                            };
                        }
                    },
                    display: (field, value, entity, props) => {
                        return (
                            <DisplayTextField
                                {...props}
                                fieldName={field.title}
                                value={
                                    value
                                        ? entity.dateVerified === false
                                            ? DateFormatter.getYear(value)
                                            : DateFormatter.dayIsoToString(value)
                                        : null
                                }
                            />
                        );
                    },
                },
                dateVerified: {
                    title: 'Date - Verified',
                    type: 'bool',
                    displayCondition: (entity, item) => entity.date,
                },
                note: { title: 'Note', type: 'text' },
            })
            .setLayout({
                tabs: {
                    General: {
                        rows: [
                            {
                                panels: {
                                    Certification: {
                                        cols: 6,
                                        fields: [
                                            'user',
                                            'certifierTeam',
                                            'certifierPosition',
                                            'model',
                                            'date',
                                            'dateVerified',
                                            'note',
                                        ],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genListView({
                fields: ['user', 'model', 'date', 'note'],
            })
            .genInsertView({
                menuItem: { title: 'Add' },
                additionalRoutes: ['/resource/certifications/add/:modelId'],
                onInit: ({ entity, _resource, _context }) => {
                    entity['@type'] = 'Certification';
                },
            })
            .genEditView({
                fields: ['model', 'note'],
                additionalLinkButton: additionalLinkButton,
            })
            .genDetailView({
                fields: ['id', 'user', 'certifierTeam', 'certifierPosition', 'model', 'date', 'note'],
                additionalLinkButton: additionalLinkButton,
            });
    }
}
