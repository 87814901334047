import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress } from '@material-ui/core';

export const LoadingOverComponent = (props) => {
    const { open, onClick = () => null, children } = props;
    return (
        <div style={{ position: 'relative' }}>
            <Backdrop open={open} onClick={onClick} style={{ zIndex: 1, position: 'absolute' }}>
                <CircularProgress />
            </Backdrop>
            {children}
        </div>
    );
};
LoadingOverComponent.propTypes = {
    open: PropTypes.bool,
    /** Déclenchée lors du clic sur le backdrop "grisé" */
    onClick: PropTypes.func,
    /** Elements JSX enfants */
    children: PropTypes.any,
};
