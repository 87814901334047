import React from 'react';
import { APIResource } from '../Services/APIResource/APIResource';
import ParameterStore, {
    userHasDeveloperRights,
    userHasMRMRights,
    userHasNoRole,
    userHasOwnershipRights,
    userHasRoleMRM, userIsVal,
} from '../Store/ParameterStore';
import { DisplayTextField } from '../Components/Display/DisplayTextField/DisplayTextField';
import DateFormatter from '../Services/DateFormatter';
import { ChangeLog } from '../Components/Display/ChangeLog/ChangeLog';
import User from "../Services/User/User";
import {BulkEdit} from "../Services/BulkActions/BulkEdit/BulkEdit";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import ModelProvider from '../Services/APIResource/FieldProviders/ModelProvider';
import { ITSystemQuickAdd } from '../Components/ITSystem/ITSystemQuickAdd';
import {needHighlightField} from "./ModelAdmin";
import {postSaveRedirectToCertification} from "./CertificationAdmin";
import { isGranted } from './common';
import APIResourceStore from "../Store/APIResourceStore";
import { BulkDelete } from '../Services/BulkActions/BulkDelete/BulkDelete';

export const ApiResourceDefaultParams = {
    id: 'implementations',
    name: 'Implementations',
    canonicalName: 'Implementation',
    fieldForTitle: "toString",
    breadcrumbName: "Impl.",
    componentForTitle: (entity, resource, resourceEditComponent) => {
        return <Breadcrumb entity={entity} resource={resource} resourceEditComponent={resourceEditComponent} relationalProperty={'models'} resourcePath={'models'} />
    },
    keepQueryParams: () => {
        return ['certification-id'];
    }
};

export default class ImplementationAdmin {
    constructor() {
        this.configure();
    }

    configure() {

        let resourceImplementations = new APIResource(ApiResourceDefaultParams);

        let additionalLinkButton = (entity) => {
            let models = [];
            if(entity.models){
                entity.models.forEach((modelPath) => {
                    if(modelPath){
                        let model = APIResourceStore.resources.allModels.getObservableItemByPath(modelPath);
                        models.push({
                            title: model.functionalID,
                            link: '/resource/models/' + model.id + '/detail',
                        });
                    }
                })
            }
            if(models.length > 0){
                return [{
                    links: models,
                    tooltip: 'Associated models',
                    icon: 'link',
                }];
            }
        };


        resourceImplementations
            .setFields({
                id: {
                            title: 'ID',
                            params: {
                                filterMulti: true,
                            },
                            displayListIfNotInLayout: true,
                        },
                date: {
                    title: 'Date',
                    type: 'date',
                    bulk: true,
                    required: (entity) => {
                        let allModelAreProductionRun = true;
                        if(entity.models && Array.isArray(entity.models)){
                            entity.models.forEach((modelPath) => {
                                let model = APIResourceStore.resources.allModels.getObservableItemByPath(modelPath);
                                if(!model.productionRun){
                                    allModelAreProductionRun = false;
                                }
                            })
                            return allModelAreProductionRun;
                        }
                        return false;
                    },
                    helperText: (entity) => {
                        let helper = [];
                        helper.push({
                            icon: 'fa-info',
                            text: 'Implementation date of the model in the IT system',
                        });
                        if (entity.dateVerified === false) {
                            helper.push({
                                type: 'warning',
                                icon: 'fa-exclamation-triangle',
                                text: 'Unverified',
                            });
                        }
                        return helper;
                    },
                    display: (field, value, entity, props) => {
                        return (
                            <DisplayTextField
                                {...props}
                                fieldName={field.title}
                                value={
                                    value
                                        ? entity.dateVerified === false
                                            ? DateFormatter.getYear(value)
                                            : DateFormatter.dayIsoToString(value)
                                        : null
                                }
                            />
                        );
                    },
                    highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams)
                },
                dateVerified: {
                    title: 'Confirmed date',
                    type: 'bool',
                    displayCondition: (entity, _item) => entity.date && userHasRoleMRM(),
                },
                systemID: {
                    title: 'System ID',
                    type: 'text',
                    maxLength:255, 
                    helperText: 'Model ID given on IT systems',
                },
                modelImplementerTeam: {
                    title: 'Model implementer team',
                    type: 'entityTree',
                    bulk: true,
                    params: {
                        resource: 'scopes',
                        instanceId: 'scopes_all',
                        displayField: 'title',
                        childrenPropertyName: 'childScopes',
                        multi: false,
                        links: false,
                        endpoints: {
                            getAll: 'scopes/all-scopes/all',
                        },
                    },
                    helperText: 'The Model Implementer Team is in charge of the correct implementation of the model in an information system',
                    required: true,
                    highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams)
                },
                ITSystem: {
                    title: 'IT System',
                    type: 'entity',
                    bulk: true,
                    params: {
                        resource: 'i_t_systems',
                        displayField: 'name',
                        multi: false,
                        links: true,
                        filters: {verified: true},
                    },
                    helperText: 'Name of the IT System where the model is implemented',
                    required: true,
                    issueButtonComponent: (props) =>  <ITSystemQuickAdd {...props} />,
                    highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams)
                },
                systemVersion: {
                    title: 'System version',
                    type: 'entity',
                    bulk: true,
                    params: {
                        resource: 'system_versions',
                        displayField: 'versionNumber',
                        multi: false,
                        links: true,
                        filters: (systemVersion, entity) => {
                            if(
                                entity
                                && systemVersion.ITSystem === entity.ITSystem
                            ){
                                return systemVersion;
                            }
                            return null;
                        }
                    },
                    helperText: 'Version of the IT System',
                },
                models: {
                    title: 'Models',
                    type: 'model',
                    params: {
                        resource: 'models',
                        instanceId: 'allModels',
                        multi: true,
                        links: true,
                        linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                        endpoints: {
                            getAll: 'models/all-models',
                        },
                    },
                    edit: (field, value, onChange, entity, routeParams) => {
                        if ((routeParams.modelId || value) && !entity.models) {
                            entity.models = routeParams.modelId
                                ? ['/api/models/' + routeParams.modelId]
                                : value;
                        }
                        return ModelProvider.getEdit(field, value, onChange, entity, routeParams);
                    },
                    required: true,
                },
                changeLogsEntities: {
                    title: 'Audit trail',
                    inParentList: false,
                    display: (field, value, entity, props) => (
                        <ChangeLog
                            field={field}
                            values={value}
                            entity={entity}
                            entityResource={'implementations'}
                            props={props}
                        />
                    ),
                    edit: () => null,
                    displayList: () => null,
                },
                //Additional field without relation with ModelUse
                changeLogComment: {
                    title: 'Justification of the data update',
                    inParentList: false,
                    type: 'textarea',
                    display: () => null,
                    token: false,
                },
            })
            .setLayout({
                tabs: {
                    General: {
                        rows: [
                            {
                                panels: {
                                    Implementation: {
                                        cols: 6,
                                        fields: [
                                            'date',
                                            'dateVerified',
                                            'ITSystem',
                                            'systemVersion',
                                            'modelImplementerTeam',
                                            'models',
                                        ],
                                    },
                                },
                            },
                        ],
                    },
                    'Audit trail': {
                        rows: [
                            {
                                panels: {
                                    'Audit trail': {
                                        cols: 12,
                                        fields: ['changeLogsEntities', 'changeLogComment'],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genListView({
                fields: ['id', 'date', 'modelImplementerTeam', 'ITSystem', 'systemVersion'],
                itemAccessCondition: (entity) => !userHasNoRole(User, entity),
                permanentFilters: {
                    "models.modelStatus[]": ParameterStore("MODEL_STATUS_ACTIVE"),
                },
            })
            .genInsertView({
                menuItem: { title: 'Add' },
                additionalRoutes: ['/resource/implementations/add/:modelId'],
                onInit: ({ entity, _resource, _context }) => {
                    entity['@type'] = 'Implementation';
                },
                onUpdate: (fieldId, oldValue, newValue, entity, _resource, _resourceEditComponent) => {
                    if(fieldId === 'ITSystem'){
                        entity.systemVersion = null;
                    }
                },
                postSaveRedirect: postSaveRedirectToCertification,
            })
            .genEditView({
                fields: [
                    'date',
                    'dateVerified',
                    'systemID',
                    'modelImplementerTeam',
                    'ITSystem',
                    'systemVersion',
                    'models',
                    'changeLogComment',
                ],
                additionalLinkButton: additionalLinkButton,
                itemAccessCondition: (entity) => entity && entity.id ? isGranted(entity, resourceImplementations.resourceId) : false,
                onUpdate: (fieldId, oldValue, newValue, entity, _resource, _resourceEditComponent) => {
                    if(fieldId === 'ITSystem'){
                        entity.systemVersion = null;
                    }
                },
                postSaveRedirect: postSaveRedirectToCertification,
                additionalActionButtons: (entity, resource, resourceEditComponent, queryParams) => {
                    let additionalActionButtons = [];

                    if (
                        !(
                            typeof queryParams !== 'object'
                            || queryParams.get('certification-id') === undefined
                            || queryParams.get('certification-id') === ''
                            || queryParams.get('certification-id') === null
                        )
                    ) {
                        additionalActionButtons.push({
                            link: '/resource/my_model_certification_campaigns/' + queryParams.get('certification-id') + '/detail',
                            tooltip: 'Go back to certification',
                            icon: 'chevron-left',
                        });
                    }
                    return additionalActionButtons;
                }
            })
            .genDetailView({
                fields: [
                    'id',
                    'date',
                    'systemID',
                    'modelImplementerTeam',
                    'ITSystem',
                    'systemVersion',
                    'models',
                    'changeLogsEntities',
                ],
                additionalLinkButton: additionalLinkButton,
                additionalActionButtons: (entity, resource, resourceEditComponent, queryParams) => {
                    let additionalActionButtons = [];

                    if (
                        !(
                            typeof queryParams !== 'object'
                            || queryParams.get('certification-id') === undefined
                            || queryParams.get('certification-id') === ''
                            || queryParams.get('certification-id') === null
                        )
                    ) {
                        additionalActionButtons.push({
                            link: '/resource/my_model_certification_campaigns/' + queryParams.get('certification-id') + '/detail',
                            tooltip: 'Go back to certification',
                            icon: 'chevron-left',
                        });
                    }
                    return additionalActionButtons;
                }
            })
            .allowDelete({
                itemAccessCondition: (entity) => {
                    let ownershipRights = false;
                    let developerRights = false;
                            let valRights = false;
                            let mrmRights = false;
                    if(entity.models && entity.models.length > 0){
                        ownershipRights = entity.models.some(m => {
                            let model = APIResourceStore.resources.allModels.getObservableItemByPath(m);
                            return model && userHasOwnershipRights(User.getId(), model);
                                })
                                developerRights = entity.models.some(m => {
                                    let model = APIResourceStore.resources.allModels.getObservableItemByPath(m);
                                    return model && userHasDeveloperRights(User, model);
                                })
                                valRights = entity.models.some(m => {
                                    let model = APIResourceStore.resources.allModels.getObservableItemByPath(m);
                                    return model && userIsVal(User, model);
                        })
                        mrmRights = entity.models.some(m => {
                            let model = APIResourceStore.resources.allModels.getObservableItemByPath(m);
                            return model && userHasMRMRights(User, model);
                        })
                    }
                    return ownershipRights || developerRights || valRights || mrmRights;
                },
                returnPath: (entity) => {
                    let models = entity.models;
                    if(Array.isArray(models) && models.length > 0){
                        let model = models[0];
                        model = model.split('/');
                        let modelId = model[model.length - 1];
                        return (
                            '/resource/models/' +
                            modelId +
                            '/detail?tab=Implementations'
                        );
                    }
                    return '/resource/models/list';
                },
            })
        ;
        resourceImplementations.addBulkAction(BulkDelete, {
            resource: resourceImplementations,
            icon: 'trash-alt',
            entityType: 'implementation',
            entityTypeLabel: 'Implementation',
            softDelete: false,
        });    
        if (userHasRoleMRM()) {
            resourceImplementations.addBulkAction(BulkEdit, {
                resource: resourceImplementations,
                icon: 'edit',itemAccessCondition: (entity) =>
                    !entity.models ||
                    entity.models
                        .map((iri) => APIResourceStore.resources.allModels.getObservableItemByPath(iri))
                        .every((model) => model && userHasMRMRights(User, model)),
                forbiddenAccessMessage: "You can't edit models belonging to another MRM team.",
                fields: Object.fromEntries(
                    Object.entries(resourceImplementations.fields)
                        .filter(([k, v]) => {
                            return v.bulk && resourceImplementations.operations.edit.fields.includes(k);
                        })
                        .map(([k, v]) => ((v.resourceId = resourceImplementations.id), [k, v]))
                ),
            });
        }
    }
}
