import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { TableDisplay } from '../../Display/TableDisplay/TableDisplay';
import { Link } from 'react-router-dom';

import { extractErrorFieldnames } from '.';
import { EditButton } from '../../Buttons/EditButton';
import Modal from '../../../Services/Modal';
import APIResourceStore from '../../../Store/APIResourceStore';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import { Button, Typography } from '@material-ui/core';
import { isGranted } from '../../../Admin/common';

class CheckRequirementsDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            canEdit: {},
        };

        this.excludeToDisplay = {
            models: [
                'implementationsEntities',
                'coveringMitigationActionsEntities',
            ],
        };

        this.tableCols = [
            {
                label: 'Title',
                field: 'entityString',
                noTooltip: true,
            },
            {
                label: 'Number of errors',
                field: 'totalErrors',
                noTooltip: true,
            },
            {
                label: 'Missing data',
                field: 'fields',
                noTooltip: true,
                display: (field, value, _entity, _props) =>
                    extractErrorFieldnames(value, this.props.resourceName).map((fieldName) => (
                        <div key={`error_field_${fieldName}`}>{fieldName}</div>
                    )),
            },
        ];
    }

    componentDidMount() {
        Object.values(this.props.entities).map((e) =>
            isGranted(e, this.props.resourceName, this.props.resourceName === 'models' ? 'MODEL_EDIT' : 'EDIT').then(
                (res) =>
                    this.setState((prevState) => ({
                        canEdit: {
                            ...prevState.canEdit,
                            [e.id]: res,
                        },
                    }))
            )
        );
    }

    editLink(entity) {
        const fields = Object.entries(entity.fields);
        let fieldsNameArray = [];
        fields.forEach((f) => {
            if(
                f[1]
                && APIResourceStore.resources[this.props.resourceName].fields[f[0]]
                && APIResourceStore.resources[this.props.resourceName].fields[f[0]].title
            ){
                fieldsNameArray.push(f[0])
            }
        });
        return `/resource/${this.props.resourceName}/${entity.id}/edit?certification-id=${this.props.certificationId}&certification=${fieldsNameArray.join('-')}`;
    }

    renderErrors() {
        return (
            <TableDisplay
                filter={false}
                cols={this.tableCols}
                rows={Object.values(this.props.entities)}
                dense={true}
                actions={(value) => {
                    return (
                        <div>
                            <Link
                                to={this.editLink(value)}
                                style={styles.actionLink}
                                onClick={() => Modal.close()}
                            >
                                {this.state.canEdit[value.id] === undefined ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={true}
                                        style={styles.loadingButton}
                                    >
                                        <LoadingIndicator styles={styles.loadingIndicator} />
                                    </Button>
                                ) : (
                                    <EditButton disabled={this.state.canEdit[value.id] !== true}></EditButton>
                                )}
                            </Link>
                        </div>
                    );
                }}
            />
        );
    }

    render() {
        return <div>
            <Typography component="h2" variant="h6">
                Missing data :
            </Typography>
            {this.renderErrors()}
        </div>
        ;
    }
}
CheckRequirementsDetail.propTypes = {
    /** Objet qui contient les entités, accessibles par leur id: { xx : entity_xx, yy : entity_yy } */
    entities: PropTypes.object,
    resourceName: PropTypes.string,
};

const styles = {
    mrDetailBtn: {
        margin: 1,
    },
    actionLink: {
        marginLeft: 5,
        textDecoration: 'none',
    },
    loadingButton: {
        padding: "6px 10px",
    },
    loadingIndicator: {
        display: 'flex',
        transition: 'color 150ms',
        alignSelf: 'center',
        fontSize: '0.5rem',
        margin: '0.5rem 0px',
        textAlign: 'center',
        width: '44px',
    },
};

export default observer(CheckRequirementsDetail);
