import React from "react";
import {APIResource} from "../Services/APIResource/APIResource";
import {
    PARAMETER_TYPE_APPROVAL_TYPE,
    PARAMETER_TYPE_DETAILED_USE,
    PARAMETER_TYPE_GLOBAL_USE,
    PARAMETER_TYPE_MICRO_RISK_CATEGORY,
    PARAMETER_TYPE_MICRO_RISK_SUB_CATEGORY,
    PARAMETER_TYPE_MODEL_DEVELOPMENT_USE,
    PARAMETER_TYPE_MODEL_USE_VALIDATION_STATUS,
    PARAMETER_TYPE_MRM_PERIMETER,
    PARAMETER_TYPE_RISK_CATEGORY,
    PARAMETER_TYPE_USE_FREQUENCY,
} from '../Admin/ParameterAdmin';
import ParameterStore, {
    hasOneGroupEntityNTX,
    userHasDeveloperRights,
    userHasMRMRights,
    userHasOwnershipRights,
    userHasRoleMRM,
    userHasSpecificRole,
    userIsVal,
} from "../Store/ParameterStore";
import {BulkEdit} from "../Services/BulkActions/BulkEdit/BulkEdit";
import APIResourceStore from "../Store/APIResourceStore";
import {EntityDisplay} from "../Components/Display/EntityDisplay/EntityDisplay";
import {MODEL_FIELDS, needHighlightField} from "./ModelAdmin";
import {ChangeLog} from "../Components/Display/ChangeLog/ChangeLog";
import User, {ROLE as Role} from "../Services/User/User";
import {getIdFromIri} from "../Services/utils";
import {EntitySelectAsync} from "../Components/Forms/EntitySelectAsync/EntitySelectAsync";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import {postSaveRedirectToCertification} from "./CertificationAdmin";
import {isGranted} from "./common";
import {BulkDelete} from "../Services/BulkActions/BulkDelete/BulkDelete";

const SUPERVISOR_AUTHORIZATION_YES = 0;
// eslint-disable-next-line no-unused-vars
const SUPERVISOR_AUTHORIZATION_NO = 1;
const SUPERVISOR_AUTHORIZATION_PLANNED = 2;

let supervisorAuthorizationsMap = {
    SUPERVISOR_AUTHORIZATION_YES: 'Yes',
    SUPERVISOR_AUTHORIZATION_NO: 'No',
    SUPERVISOR_AUTHORIZATION_PLANNED: 'Planned',
};

supervisorAuthorizationsMap = Object.fromEntries(Object.entries(supervisorAuthorizationsMap).map(([k, v]) => {
    return [eval(k), v];
}));

export const ApiResourceDefaultParams = {
    id: "model_uses",
    name: "Model Uses",
    canonicalName: "Model Use",
    fieldForTitle: "toString",
    breadcrumbName: "Use",
    componentForTitle: (entity, resource, resourceEditComponent) => {
        return <Breadcrumb entity={entity} resource={resource} resourceEditComponent={resourceEditComponent}
                           relationalProperty={'model'} resourcePath={'models'}/>
    },
    fieldsAclLocation: "annotations/ModelUse",
    aclFields: (entity, routeParams) => {
        if (routeParams !== undefined && routeParams.modelId !== undefined) {
            return {
                model: `/api/models/${routeParams.modelId}`
            };
        }
        return null;
    },
    keepQueryParams: () => {
        return ['certification-id'];
    }
};

export default class ModelUseAdmin {
    constructor() {
        this.configure();
    }

    configure() {
        let additionalLinkButton = (entity) => {
            if (!entity.model) {
                return [];
            }

            let modelId = getIdFromIri(entity.model);

            return [
                {
                    link: "/resource/models/" + modelId + "/detail?tab=Uses",
                    tooltip: "Associated model",
                    icon: 'link',
                },
            ];
        };

        /** @type {Object.<string, import('../Services/APIResource/APIResource').APIResourceField>} */
        let modelFields = {};
        let originalModelFields = JSON.parse(JSON.stringify(MODEL_FIELDS(APIResourceStore.resources.allModels)));
        Object.keys(originalModelFields).forEach(function (
            property,
            _key
        ) {
            let fieldDefinition = originalModelFields[property];
            fieldDefinition.title = "Model: " + fieldDefinition.title;
            // Comme on a transformé le MODEL_FIELDS en json on a perdu les fonctions, donc on remet display et displayList
            fieldDefinition.display =
                MODEL_FIELDS(APIResourceStore.resources.allModels)[property].display;
            fieldDefinition.displayList = MODEL_FIELDS(APIResourceStore.resources.allModels)[property].displayList;
            fieldDefinition.resourceInstanceId = "models";
            fieldDefinition.resourceInstanceDatas =
                "model_use_admin_models";
            fieldDefinition.canonicalFieldName = "model." + property;
            modelFields["modelEntity." + property] = fieldDefinition;
        });
        // Traitement particulier pour functionalID
        // Inutile depuis la modification du filtre back, mais conservé pour exemple.
        // modelFields["modelEntity.functionalID"].params.listFilterTransform = (/** @type {string} */ v) => v.replace(/^(INV_|DFT_|UDC_|RTD_|DTL_)/, "");

        const views = [
            {
                instanceId: 'model_uses',
                name: "Model Uses",
                permanentFilters: {
                    'model.modelStatus[0]': ParameterStore('MODEL_STATUS_ACTIVE'),
                },
            }, {
                instanceId: 'model_uses_all',
                name: "All Model Uses",
            },
        ];
        views.forEach((view) => {
            let resourceModelUses = new APIResource({
                ...ApiResourceDefaultParams,
                ...{
                    instanceId: view.instanceId,
                    id: "model_uses",
                    name: view.name,
                }
            });

            resourceModelUses
                .setFields(
                    Object.assign(
                        {
                            id: {
                                title: 'ID',
                                params: {
                                    filterMulti: true,
                                },
                            },
                            useFrequency: {
                                title: 'Use frequency',
                                type: 'parameter',
                                params: {
                                    type: PARAMETER_TYPE_USE_FREQUENCY,
                                    multi: false,
                                },
                                helperText: 'Use frequency of  this specific model use',
                                bulk: true,
                            },
                            legalEntities: {
                                title: 'Legal Entity',
                                type: 'entityTree',
                                params: {
                                    resource: 'legal_entities',
                                    instance_id: 'legal_entities_all',
                                    displayField: 'title',// /!\ Nous devons conserver title au lieu de toString car le EntityTreeProvider effectue la recherche en back via cette propriété, toString n'est pas recherchable en base. Ou alors il faut utiliser treeDisplayField.
                                    childrenPropertyName: 'childLegalEntities',
                                    selectable: (item) => item.children.length === 0,
                                    multi: true,
                                    links: false,
                                    endpoints: {
                                        getAll: 'legal_entities/all',
                                    },
                                },
                                filter: (field, onChange, value) => {
                                    return (
                                        <EntitySelectAsync
                                            label={null}
                                            resourceId={field.params.resource}
                                            instanceId={field.params.instanceId}
                                            resourceLabel={field.params.displayField}
                                            value={value}
                                            onChange={onChange}
                                            clearable={true}
                                            multi={field.params.multi}
                                            endpoints={field.params.endpoints}
                                        />
                                    );
                                },
                                helperText: 'Legal entity where the model is used.',
                                required: false,
                                bulk: true,
                            },
                            legalEntityBfcCode: {
                                title: 'Legal entity BFC Code',
                                type: 'text',
                                helperText: 'BFC code of the legal entity',
                            },
                            legalEntityCopernicCode: {
                                title: 'Legal entity Copernic Code',
                                type: 'text',
                                helperText: 'Copernic code of the legal entity',
                            },
                            BL: {
                                title: 'Business Line (BL)',
                                type: 'entityTree',
                                params: {
                                    resource: 'b_ls',
                                    instanceId: 'b_ls_all',
                                    childrenPropertyName: 'childBLs',
                                    treeDisplayField: 'title',
                                    displayField: 'toString',
                                    multi: false,
                                    links: false,
                                    endpoints: {
                                        getAll: 'business_lines/all',
                                    },
                                },
                                filter: (field, onChange, value) => {
                                    return (
                                        <EntitySelectAsync
                                            label={null}
                                            resourceId={field.params.resource}
                                            instanceId={field.params.instanceId}
                                            resourceLabel={field.params.displayField}
                                            value={value}
                                            onChange={onChange}
                                            clearable={true}
                                            multi={field.params.multi}
                                            endpoints={field.params.endpoints}
                                        />
                                    );
                                },
                                helperText:
                                    'Business Line where the model is used, according to the OSIRISK referential',
                                bulk: true,
                            },
                            mrmPerimeters: {
                                title: 'MRM Perimeters',
                                type: 'parameter',
                                helperText: 'Perimeter where the model is used, according to MRM referential',
                                bulk: true,
                                params: {type: PARAMETER_TYPE_MRM_PERIMETER, multi: true},
                                required: true,
                                highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams)
                            },
                            //OUs: {
                            //    title: 'Organizational Unit (OU)',
                            //    type: 'entityTree',
                            //    params: {
                            //        resource: 'o_us',
                            //        instanceId: 'o_us_all',
                            //        childrenPropertyName: 'childOUs',
                            //        displayField: 'title',
                            //        multi: true,
                            //        links: false,
                            //        endpoints: {
                            //            getAll: 'o_us/all',
                            //        },
                            //    },
                            //    filter: (field, onChange, value) => {
                            //        return (
                            //            <EntitySelectAsync
                            //                label={null}
                            //                resourceId={field.params.resource}
                            //                instanceId={field.params.instanceId}
                            //                resourceLabel={field.params.displayField}
                            //                value={value}
                            //                onChange={onChange}
                            //                clearable={true}
                            //                multi={field.params.multi}
                            //                endpoints={field.params.endpoints}
                            //            />
                            //        );
                            //    },
                            //    helperText:
                            //        'Organizational Unit where the model is used, according to the OSIRISK referential',
                            //    required: false,
                            //    bulk: true,
                            //    highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams)
                            //},
                            //OUsUid: {
                            //    title: 'Organizational Unit (OU) References',
                            //    type: 'text',
                            //    helperText: 'OU code according to the OSIRISK referential',
                            //},
                            BLUid: {
                                title: 'Business Line (BL) Reference',
                                type: 'text',
                                helperText: 'BL code according to the OSIRISK referential',
                            },
                            use: {
                                title: 'Use',
                                type: 'parameter',
                                params: {
                                    type: PARAMETER_TYPE_GLOBAL_USE,
                                    multi: false,
                                },
                                required: true,
                                helperText:
                                    'Global description of the use of the model (Piliar 1 of Solvency, Provisioning,…)'
                                    + '\nA use is coupled with a detailed use',
                                bulk: true,
                                highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams)
                            },
                            detailedUse: {
                                title: 'Detailed use',
                                type: 'parameter',
                                params: {
                                    type: PARAMETER_TYPE_DETAILED_USE,
                                    multi: false,
                                },
                                helperText:
                                    'Detailed description of the use of the model (RWA, IFRS9 Provision,…)'
                                    + '\nA detailed use is coupled with a use',
                                required: true,
                                bulk: true,
                                highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams)
                            },
                            model: {
                                title: 'Model',
                                type: 'model',
                                params: {
                                    resource: 'models',
                                    instanceId: 'allModels',
                                    multi: false,
                                    links: true,
                                    linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                                    endpoints: {
                                        getAll: 'models/all-models',
                                    },
                                },
                                edit: (field, value, onChange, entity, routeParams) => {
                                    if (!entity.model && (routeParams.modelId || value)) {
                                        entity.model =
                                            routeParams.modelId && !entity.model
                                                ? '/api/models/' + routeParams.modelId
                                                : value;
                                    }
                                    return (
                                        <EntityDisplay
                                            label="Model"
                                            resourceId="models"
                                            resourceLabel="toString"
                                            value={entity.model}
                                        />
                                    );
                                },
                            },
                            supervisorAuthorization: {
                                title: "Supervisor authorization",
                                type: "mapped",
                                params: {
                                    mapping: supervisorAuthorizationsMap,
                                },
                                helperText: 'Please indicate if the model has been approved by the supervisor (BCE, ACPR).\n' +
                                    'Only applicable for Pillar I uses.',
                                bulk: true,
                                displayCondition: (entity) => entity.use === ParameterStore('GLOBAL_USE_PILLAR_I_PRUDENTIAL'),
                                required: (entity) => entity.use === ParameterStore('GLOBAL_USE_PILLAR_I_PRUDENTIAL') && !userHasRoleMRM(),
                                highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams)
                            },
                            approvalType: {
                                title: 'Supervisor',
                                type: 'parameter',
                                params: {
                                    type: PARAMETER_TYPE_APPROVAL_TYPE,
                                    multi: false,
                                },
                                helperText: 'Name of the supervisor in charge of approval',
                                bulk: true,
                                displayCondition: (entity) => entity.use === ParameterStore('GLOBAL_USE_PILLAR_I_PRUDENTIAL') && (entity.supervisorAuthorization === SUPERVISOR_AUTHORIZATION_YES || entity.supervisorAuthorization === SUPERVISOR_AUTHORIZATION_PLANNED),
                                required: (entity) => entity.supervisorAuthorization === SUPERVISOR_AUTHORIZATION_YES || entity.supervisorAuthorization === SUPERVISOR_AUTHORIZATION_PLANNED,
                                highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams)
                            },
                            dateOfApproval: {
                                title: 'Authorization date',
                                type: 'date',
                                params: {
                                    maxDate: new Date(),
                                },
                                helperText: 'Approval date of the model by the supervisor',
                                bulk: true,
                                displayCondition: (entity) => entity.use === ParameterStore('GLOBAL_USE_PILLAR_I_PRUDENTIAL') && (entity.supervisorAuthorization === SUPERVISOR_AUTHORIZATION_YES),
                                required: (entity) => entity.supervisorAuthorization === SUPERVISOR_AUTHORIZATION_YES,
                                highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams)
                            },
                            applicationDate: {
                                title: 'Application date',
                                type: 'date',
                                helperText: 'Date of the application for approval',
                                bulk: true,
                                displayCondition: (entity) => entity.use === ParameterStore('GLOBAL_USE_PILLAR_I_PRUDENTIAL') && (entity.supervisorAuthorization === SUPERVISOR_AUTHORIZATION_YES || entity.supervisorAuthorization === SUPERVISOR_AUTHORIZATION_PLANNED),
                                required: (entity) => entity.supervisorAuthorization === SUPERVISOR_AUTHORIZATION_PLANNED,
                                highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams)
                            },
                            modelUsers: {
                                title: 'Users',
                                type: 'entityTree',
                                params: {
                                    resource: 'scopes',
                                    instanceId: 'scopes_all',
                                    childrenPropertyName: 'childScopes',
                                    displayField: 'title',
                                    multi: true,
                                    links: false,
                                    endpoints: {
                                        getAll: 'scopes/all-scopes/all',
                                    },
                                },
                                helperText: 'The model user is responsible for the correct use of the model\n' +
                                    'They must understand the weaknesses and limitations of the model they are using, and report any apparent problems with the model',
                                bulk: true,
                            },
                            riskCategory: {
                                title: 'Risk Category',
                                type: 'parameter',
                                params: {
                                    type: PARAMETER_TYPE_RISK_CATEGORY,
                                    multi: false,
                                },
                                helperText: 'Defined following the internal risk taxonomy of the BPCE group',
                                displayCondition: (entity, _item) => {
                                    if (entity.model) {
                                        // On force le reload ici, et cela sert pour les autres champs (micro)risk(sub)category
                                        let model = APIResourceStore.resources.allModels.getObservableItem(getIdFromIri(entity.model), true);
                                        return model.riskCategory === ParameterStore('RISK_CATEGORY_SHARED_MODELS');
                                    }
                                    return false;
                                },
                                bulk: true,
                                highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams)
                            },
                            microRiskCategory: {
                                title: 'Micro-Risk Category',
                                type: 'parameter',
                                params: {
                                    type: PARAMETER_TYPE_MICRO_RISK_CATEGORY,
                                    multi: false,
                                },
                                helperText: 'Defined following the internal risk taxonomy of the BPCE group',

                                displayCondition: (entity, _item) => {
                                    if (entity.model) {
                                        let model = APIResourceStore.resources.allModels.getObservableItem(getIdFromIri(entity.model));
                                        return model.riskCategory === ParameterStore('RISK_CATEGORY_SHARED_MODELS');
                                    }
                                    return false;
                                },
                                bulk: true,
                                highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams)
                            },
                            microRiskSubCategory: {
                                title: 'Micro-Risk Sub-Category',
                                type: 'parameter',
                                params: {
                                    type: PARAMETER_TYPE_MICRO_RISK_SUB_CATEGORY,
                                    multi: false,
                                },
                                helperText: 'Defined following the internal risk taxonomy of the BPCE group',

                                displayCondition: (entity, _item) => {
                                    if (entity.model) {
                                        let model = APIResourceStore.resources.allModels.getObservableItem(getIdFromIri(entity.model));
                                        return model.riskCategory === ParameterStore('RISK_CATEGORY_SHARED_MODELS');
                                    }
                                    return false;
                                },
                                bulk: true,
                                highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams)
                            },
                            validationStatus: {
                                title: 'Validation status',
                                type: 'parameter',
                                params: {
                                    type: PARAMETER_TYPE_MODEL_USE_VALIDATION_STATUS,
                                    multi: false,
                                },
                                helperText: 'Committee decision on the last  review undergone by the model',
                                bulk: true,
                            },
                            validationStatusRationale: {
                                title: 'Validation status rationale',
                                type: 'text',
                                helperText: 'Comments from the committee to justify the validation status',
                            },
                            mainUse: {
                                title: 'Main use',
                                type: 'bool',
                                helperText: 'Indicates if the current use is the main use of the model'
                            },
                            developmentAndUse: {
                                title: 'Development and use',
                                type: 'parameter',
                                helperText: 'Local = Developed at platform level and used in the platform'
                                    + "\nGlobal = Developed at Group and used in any platform with no customization"
                                    + "\nCustomized = Developed at Group, used in the platform with customization",
                                params: {
                                    type: PARAMETER_TYPE_MODEL_DEVELOPMENT_USE,
                                    multi: false,
                                },
                                required: userHasRoleMRM,
                                displayCondition: (entity, _item) => {
                                    if (entity.model) {
                                        return hasOneGroupEntityNTX(
                                            APIResourceStore.resources.allModels.getObservableItem(
                                                getIdFromIri(entity.model)
                                            )
                                        );
                                    }
                                    return false;
                                },
                                bulk: true,
                            },
                            changeLogsEntities: {
                                title: 'Audit trail',
                                inParentList: false,
                                display: (field, value, entity, props) => (
                                    <ChangeLog
                                        field={field}
                                        values={value}
                                        entity={entity}
                                        entityResource={'model_uses'}
                                        props={props}
                                    />
                                ),
                                edit: () => null,
                                displayList: () => null,
                            },
                            //Additional field without relation with ModelUse
                            changeLogComment: {
                                title: 'Justification of the data update',
                                inParentList: false,
                                type: 'textarea',
                                display: () => null,
                                token: false,
                            },
                        },
                        modelFields
                    )
                )
                .setValidation((_entity) => {
                    return true;
                })
                .setLayout({
                    tabs: {
                        Properties: {
                            rows: [
                                {
                                    panels: {
                                        'Use classification': {
                                            cols: 6,
                                            fields: [
                                                'use',
                                                'detailedUse',
                                                'useFrequency',
                                                'model',
                                                'mainUse',
                                                'developmentAndUse',
                                            ],
                                        },
                                    },
                                },
                                {
                                    panels: {
                                        'Use Perimeter': {
                                            cols: 6,
                                            fields: [
                                                'mrmPerimeters',
                                                //'OUs',
                                                'BL',
                                                'legalEntities',
                                                //'OUsUid',
                                                'BLUid',
                                                'legalEntityBfcCode',
                                                'legalEntityCopernicCode',
                                                'modelUsers',
                                            ],
                                        },

                                        'Use authorization by the supervisor': {
                                            cols: 6,
                                            fields: ['supervisorAuthorization', 'approvalType', 'applicationDate', 'dateOfApproval'],
                                        },
                                    },
                                },
                                {
                                    panels: {
                                        Taxonomy: {
                                            cols: 6,
                                            fields: ['riskCategory', 'microRiskCategory', 'microRiskSubCategory'],
                                        },
                                    },
                                },
                                {
                                    panels: {
                                        'Use validation': {
                                            cols: 6,
                                            fields: [
                                                'validationStatus',
                                                'validationStatusRationale',
                                            ],
                                        },
                                    },
                                },
                            ],
                        },
                        'Audit trail': {
                            rows: [
                                {
                                    panels: {
                                        'Audit trail': {
                                            cols: 12,
                                            fields: ['changeLogsEntities', 'changeLogComment'],
                                        },
                                    },
                                },
                            ],
                        },
                    },
                })
                .genListView({
                    fields: ['use', 'detailedUse', 'model', 'legalEntities'],
                    permanentFilters: view.permanentFilters,
                    showDefaultAddButton: false,
                    // neededFields: ['modelEntity'],
                    _debugNoLimitProperties: true, /** @todo tmp on desactive pour modelUse */
                })
                .genInsertView({
                    menuItem: {title: 'Add'},
                    fields: [
                        'useFrequency',
                        'legalEntities',
                        'mrmPerimeters',
                        'BL',
                        //'OUs',
                        'use',
                        'detailedUse',
                        'model',
                        'supervisorAuthorization',
                        'dateOfApproval',
                        'approvalType',
                        'applicationDate',
                        'modelUsers',
                        'riskCategory',
                        'microRiskCategory',
                        'microRiskSubCategory',
                        'developmentAndUse',
                    ],
                    additionalRoutes: ['/resource/model_uses/add/:modelId'],
                    onInit: async ({entity, _resource, _context}) => {
                        entity['@type'] = 'ModelUse';
                        entity.mainUse = false;
                    },
                    postSaveRedirect: postSaveRedirectToCertification,
                })
                .genEditView({
                    fields: [
                        'useFrequency',
                        'legalEntities',
                        'mrmPerimeters',
                        'BL',
                        //'OUs',
                        'use',
                        'detailedUse',
                        'model',
                        'supervisorAuthorization',
                        'dateOfApproval',
                        'approvalType',
                        'applicationDate',
                        'modelUsers',
                        'riskCategory',
                        'microRiskCategory',
                        'microRiskSubCategory',
                        'mainUse',
                        'developmentAndUse',
                        'changeLogComment',
                        'validationStatus',
                        'validationStatusRationale',
                    ],
                    additionalLinkButton: additionalLinkButton,
                    itemAccessCondition: (entity) => {
                        return entity && entity.id ? isGranted({id: entity.id}, 'model_uses') : false;
                    },
                    postSaveRedirect: postSaveRedirectToCertification,
                    additionalActionButtons: (entity, resource, resourceEditComponent, queryParams) => {
                        let additionalActionButtons = [];

                        if (
                            !(
                                typeof queryParams !== 'object'
                                || queryParams.get('certification-id') === undefined
                                || queryParams.get('certification-id') === ''
                                || queryParams.get('certification-id') === null
                            )
                        ) {
                            additionalActionButtons.push({
                                link: '/resource/my_model_certification_campaigns/' + queryParams.get('certification-id') + '/detail',
                                tooltip: 'Go back to certification',
                                icon: 'chevron-left',
                            });
                        }
                        return additionalActionButtons;
                    }
                })
                .genDetailView({
                    fields: [
                        'detailedUse',
                        'use',
                        'useFrequency',
                        'model',
                        'legalEntities',
                        'legalEntityBfcCode',
                        'legalEntityCopernicCode',
                        'BL',
                        //'OUs',
                        'mrmPerimeters',
                        'supervisorAuthorization',
                        'dateOfApproval',
                        'applicationDate',
                        'approvalType',
                        'modelUsers',

                        'BLUid',
                        //'OUsUid',
                        'validationStatus',
                        'validationStatusRationale',
                        'riskCategory',
                        'microRiskCategory',
                        'microRiskSubCategory',
                        'mainUse',
                        'developmentAndUse',
                        'changeLogsEntities',
                    ],
                    additionalLinkButton: additionalLinkButton,
                    additionalActionButtons: (entity, resource, resourceEditComponent, queryParams) => {
                        let additionalActionButtons = [];

                        if (
                            !(
                                typeof queryParams !== 'object'
                                || queryParams.get('certification-id') === undefined
                                || queryParams.get('certification-id') === ''
                                || queryParams.get('certification-id') === null
                            )
                        ) {
                            additionalActionButtons.push({
                                link: '/resource/my_model_certification_campaigns/' + queryParams.get('certification-id') + '/detail',
                                tooltip: 'Go back to certification',
                                icon: 'chevron-left',
                            });
                        }
                        return additionalActionButtons;
                    }
                })
                .allowDelete({
                    itemAccessCondition: (entity) => {
                        let model = entity.model ? APIResourceStore.resources.allModels.getObservableItemByPath(entity.model) : {};
                        return userHasOwnershipRights(User.getId(), model) ||
                            userHasDeveloperRights(User, model) ||
                            userIsVal(User, model) ||
                            userHasMRMRights(User, model)
                            ;
                    },
                    returnPath: (entity) => {
                        let modelId = getIdFromIri(entity.model);
                        return '/resource/models/' + modelId + '/detail?tab=Uses';
                    },
                })
            ;
            resourceModelUses.addBulkAction(BulkDelete, {
                resource: resourceModelUses,
                icon: 'trash-alt',
                entityType: 'modelUse',
                entityTypeLabel: 'Model Use',
                softDelete: false,
            });
            if (!userHasSpecificRole(Role.IG)) {
                resourceModelUses.addBulkAction(BulkEdit, {
                    resource: resourceModelUses,
                    icon: 'edit',
                    itemAccessCondition: async (entity) => {
                        if (!entity.model) return false;
                        let model = await APIResourceStore.resources.models.getItemFromResourcePath(entity.model);
                        return userHasOwnershipRights(User.getId(), model) || userHasMRMRights(User, model);
                    },
                    forbiddenAccessMessage: "Bulk edit unauthorized.",
                    fields: Object.fromEntries(
                        Object.entries(APIResourceStore.resources.model_uses.fields)
                            .filter(
                                ([k, v]) =>
                                    v.bulk &&
                                    APIResourceStore.resources.model_uses.operations.edit.fields.includes(k)
                            )
                            .map(([k, v]) => ((v.resourceId = 'model_uses'), [k, v]))
                    ),
                });
            }
        });
    }
}
