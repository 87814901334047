import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { ActionButton } from '../../Modal/ActionButton';
import { ButtonBar } from '../../Modal/ButtonBar';
import { ModalContent } from '../../Modal/ModalContent';
import { ActionLink } from '../../ActionLink/ActionLink';
import Modal from '../../../Services/Modal';

const tabNames = {
    implementations: 'Implementations',
    coveringMitigationActions: 'Mitigation actions',
    modelUses: 'Uses',
}

export const CheckRequirementsSimple = (props) => {
    const { entity, certificationId, message, relationName } = props;

    const getDefaultLink = () => {
        return `/resource/models/${entity.id}/detail?tab=${tabNames[relationName]}&certification-id=${certificationId}`;
    }

    const getLink = () => {
        if(relationName === 'coveringMitigationActions'){
            return `/resource/mitigation_actions/add/${entity.id}?tab=${tabNames[relationName]}&certification-id=${certificationId}`;
        }else if(relationName === 'implementations'){
            return `/resource/implementations/add/${entity.id}?tab=${tabNames[relationName]}&certification-id=${certificationId}`;
        }else if(relationName === 'modelUses'){
            return `/resource/model_uses/add/${entity.id}?tab=${tabNames[relationName]}&certification-id=${certificationId}`;
        }
        return getDefaultLink();
    }

    return (
        <ModalContent>
            <Typography component="h2" variant="h6">
                Missing relation :
            </Typography>
            <Typography component="p">{message}.</Typography>
            <ButtonBar>
                {
                    relationName === 'coveringMitigationActions'?
                        <>
                            <ActionLink to={getLink()} onClick={() => Modal.close()}>
                                <ActionButton>{`New ${tabNames[relationName].slice(0, -1)}`}</ActionButton>
                            </ActionLink>
                            <ActionLink to={getDefaultLink()} onClick={() => Modal.close()}>
                                <ActionButton>Edit model</ActionButton>
                            </ActionLink>
                        </>
                    :
                        <ActionLink to={getLink()} onClick={() => Modal.close()}>
                            <ActionButton>{`New ${tabNames[relationName].slice(0, -1)}`}</ActionButton>
                        </ActionLink>
                }

            </ButtonBar>
        </ModalContent>
    );
};
CheckRequirementsSimple.propTypes = {
    message: PropTypes.string,
    relationName: PropTypes.string,
    entity: PropTypes.object,
};
