/* eslint-disable no-console */
/* eslint-disable react/display-name */
import React from 'react';
import {LinkPreload as Link} from '../Components/Link/Link';

import {
    APIResource,
    CONTEXT_ADD,
    CONTEXT_CUSTOM,
    CONTEXT_DETAIL,
    CONTEXT_EDIT
} from '../Services/APIResource/APIResource';
import {TableDisplay} from '../Components/Display/TableDisplay/TableDisplay';
import {DisplayTextField} from '../Components/Display/DisplayTextField/DisplayTextField';
import VersionsDetail from '../Components/Model/Detail/VersionsDetail';
import {ModelExport} from '../Services/BulkActions/BulkExport/ModelExport';
import {ChangeLogExport} from '../Services/BulkActions/BulkExport/ChangeLogExport';
import {
    AddCertificationForm,
    BulkAddCertifications
} from '../Services/BulkActions/BulkAddCertifications/BulkAddCertifications';
import {BulkEdit} from '../Services/BulkActions/BulkEdit/BulkEdit';
import {BulkDelete} from '../Services/BulkActions/BulkDelete/BulkDelete';
import Modal from '../Services/Modal';
import APIResourceStore from '../Store/APIResourceStore';
import Navigation from '../Services/Navigation';
import User, {ROLE as Role} from '../Services/User/User';
import {canEditByProcess, getUserRole} from '../Services/MRA';
import ParameterStore, {
    BusinessRole,
    getParamByIri,
    hasGroupEntityBPCE,
    hasOneGroupEntityNTX,
    USER_SCOPE_MANAGER,
    userHasBusinessSponsorRights,
    userHasContributingRights,
    userHasDeclarerRights,
    userHasDeveloperRights,
    userHasImplementerRights,
    userHasIssuerTeamsRights,
    userHasMRMRights,
    userHasNoRole,
    userHasNoticeIssuerTeamsRights,
    userHasNoticeOwnershipRights,
    userHasOwnershipRights,
    userHasRights,
    userHasRole,
    userHasRoleADMIN,
    userHasRoleIG,
    userHasRoleMRM,
    userHasRoleSTD,
    userHasSpecificRole,
    userHasStakeHolderRights,
    userHasValidatorRights,
    userIsVal,
    userValidatorTeamManagedRights,
} from '../Store/ParameterStore';
import {IssueAddForm} from '../Components/Issue/IssueButton';
import {LogicalDeleteButton} from '../Components/DeleteButton/LogicalDeleteButton';
import Http from '../Services/Http';
import {
    PARAMETER_TYPE_ADL1,
    PARAMETER_TYPE_ADL2,
    PARAMETER_TYPE_ADL3,
    PARAMETER_TYPE_CHAMPION_OR_CHALLENGER,
    PARAMETER_TYPE_COMPLEXITY_LEVEL,
    PARAMETER_TYPE_DOCUMENT_CATEGORY_FOR_MODEL,
    PARAMETER_TYPE_EXTERNAL_IMPACT,
    PARAMETER_TYPE_IN_HOUSE_OR_VENDOR,
    PARAMETER_TYPE_MATERIALITY,
    PARAMETER_TYPE_METRICS,
    PARAMETER_TYPE_MICRO_RISK_CATEGORY,
    PARAMETER_TYPE_MICRO_RISK_SUB_CATEGORY,
    PARAMETER_TYPE_MODEL_NATURE_STANDARD,
    PARAMETER_TYPE_MODEL_OBSERVABILITY,
    PARAMETER_TYPE_MODEL_POLICY_EXCEPTION_TYPE,
    PARAMETER_TYPE_MODEL_STATUS,
    PARAMETER_TYPE_MODEL_TIER_RESULT,
    PARAMETER_TYPE_MODEL_TIERING_CATEGORY,
    PARAMETER_TYPE_MODEL_TYPE,
    PARAMETER_TYPE_MODEL_USAGE,
    PARAMETER_TYPE_MODEL_USE_VALIDATION_STATUS,
    PARAMETER_TYPE_MODEL_VALIDATOR_LOCATION,
    PARAMETER_TYPE_MRM_CATEGORY,
    PARAMETER_TYPE_MRM_SUBCATEGORY,
    PARAMETER_TYPE_NON_MODEL_STATUS,
    PARAMETER_TYPE_REVIEW_TYPE,
    PARAMETER_TYPE_RISK_CATEGORY,
    PARAMETER_TYPE_SPECIFIC_FRAMEWORK,
    PARAMETER_TYPE_TIERING_COMPLEXITY_RATIONALE,
    PARAMETER_TYPE_VALIDATION_PLAN_ENTRYEXIT,
    PARAMETER_TYPE_VALIDATION_PLAN_STATUS,
} from '../Admin/ParameterAdmin';
import {ParameterSelect} from '../Components/Forms/ParameterSelect/ParameterSelect';
import {
    DOCUMENT_ACTION_ADD,
    DOCUMENT_ACTION_DELETE,
    DOCUMENT_ACTION_EDIT,
    DOCUMENT_ACTION_LIST,
    DOCUMENT_ACTION_SHOW,
    DOCUMENT_BULK_DOWNLOAD,
    DocumentList,
    DocumentListAccordion,
    DocumentManager,
} from '../Components/Display/DocumentManager/DocumentManager';
import DateFormatter from '../Services/DateFormatter';
import {TieringForm} from '../Components/Tiering/TieringForm';
import {VerifiedButton} from '../Components/VerifiedButton/VerifiedButton';
import {CloneModelModal} from '../Components/Model/Clone/CloneModelModal';
import {
    getNextStatuses,
    isModelUnauthorizedInProduction,
    submitToMRM,
    validateModel
} from '../Services/Actions/ModelActions';
import {EntityDisplay} from '../Components/Display/EntityDisplay/EntityDisplay';
import BackgroundModel from '../Components/Model/Add/BackgroundModel';
import Environment from '../Services/Environment';
import TextProvider from '../Services/APIResource/FieldProviders/TextProvider';
import {Header} from '../Components/Header/Header';
import {ReviewRequestModal} from '../Components/Review/ReviewRequestModal';
import {ModelStepProvider} from '../Components/ModelStep/ModelStep';
import ModalDeclareModel from '../Components/Model/Add/ModalDeclareModel';
import ModelRetirementModal, {retirementFields} from '../Components/Model/Retire/ModelRetirementModal';
import EntityAsyncProvider from '../Services/APIResource/FieldProviders/EntityAsyncProvider';
import ModelRemovalModal from '../Components/Forms/ModelRemoval/ModelRemoval';
import BoolProvider from '../Services/APIResource/FieldProviders/BoolProvider';
import {confirmAsNonModel, convertToModel} from '../Services/Actions/NonModelActions';
import ParameterProvider from '../Services/APIResource/FieldProviders/ParameterProvider';
import {NewReview} from '../Services/BulkActions/BulkReview/NewReview';
import {NewMra} from '../Services/BulkActions/BulkMra/NewMra';
import {getIdFromIri} from '../Services/utils';
import AssociateFindingNotice from '../Components/Review/AssociateFindingNotice';
import ReviewIssueRelation from '../Components/Review/ReviewIssueRelation';
import ValidateFindingNotice from '../Components/Review/ValidateFindingNotice';
import String from '../Services/String';
import DateProvider from '../Services/APIResource/FieldProviders/DateProvider';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import {NewUse} from '../Services/BulkActions/BulkUse/NewUse';
import {needHighlightField as needHighlightFieldCommon} from './common';
import {EditButton} from '../Components/Buttons/EditButton';
import {DetailButton} from '../Components/Buttons/DetailButton';
import {postSaveRedirectToCertification} from './CertificationAdmin';
import ModelProvider from '../Services/APIResource/FieldProviders/ModelProvider';
import {REVIEW_STATUS_CLOSED} from './ReviewAdmin';
import {ConfirmModal} from "../Components/Modal/ConfirmModal";
import {
    specificFrameworkConversionFields
} from "../Components/Forms/ConvertSpecificFrameworkForm/ConvertSpecificFrameworkForm";
import {OpenModal, OpenModalForEntities} from "../Components/Modal/OpenModal";
import {Init as MraInit} from "../Components/Mra/Init"
import {BulkRetire} from '../Services/BulkActions/BulkRetire/BulkRetire';
import TextareaProvider from '../Services/APIResource/FieldProviders/TextareaProvider';
import {
    FINDING_STATUS_CLOSED,
    FINDING_STATUS_DELETED,
    FINDING_STATUS_DISMISSED,
    FINDING_STATUS_DRAFT,
    FINDING_STATUS_OPEN
} from "./FindingAdmin";
import Export from '../Components/Export/Export';
import Import from '../Components/Import/Import';
import {freezeInitialValidationPlanFieldsButton} from '../Components/Model/ModelFreezeInitialValidationFields';
import {UnauthorizedModelInProductionModal} from '../Components/Model/Modal/UnauthorizedModelInProductionModal';
import UserProvider from "../Services/APIResource/FieldProviders/UserProvider";

export const mitigationActionsMap = {
    false: 'At least one mitigation action is applicable to this model',
    true: 'No mitigation action is applicable to this model',
};

const MODEL_EDIT_FIELDS_DEFAULT = [
    'name',
    'description',
    'initialID',
    'ECBID',
    'ECBAnnexID',
    'underDeclarationConversion',
    'inventoriedConversion',
    'clonedFrom',
    'modelType',
    'output',
    'validatorsLocation',
    'coreModel',
    'coreModelParent',
    'declarer',
    'declarationDate',
    'modelOwner',
    'modelOwnerDelegation',
    'modelOwnerDelegationDenyComment',
    'modelDeveloperTeam',
    'modelValidatorTeams',
    'businessSponsor',
    'riskCategory',
    'microRiskCategory',
    'microRiskSubCategory',
    'applicationDomainLevel1',
    'applicationDomainLevel2',
    'applicationDomainLevel3',
    'championOrChallenger',
    'championModels',
    'inHouseOrVendor',
    'vendor',
    'modelNatureStandard',
    'modelNatureDetailedString',
    'modelObservability',
    'nextScheduledValidationDate',
    'nextValidationDateVerificationComment',
    'nextScheduledValidationType',
    'upStreamNotAvailable',
    'downStreamNotAvailable',
    'upstreamModels',
    'downstreamModels',
    'backgroundModelRelations',
    'tieringReferenceModels',
    'tieringReferenceForModels',
    'operationalRiskFlag',
    'productionRun',
    'previsionalDate',
    'changeLogComment',
    'mrmTeamsOverride',
    'dataSource',
    'modelStatus',
    'retirementStatus',
    'retirementCommittee',
    'retirementJustificationMrm',
    'retirementJustificationLod1',
    'retirementRefusedJustification',
    'retirementRequestDate',
    'retirementExpectedDate',
    'policyExceptionStartDate',
    'policyExceptionEndDate',
    'policyExceptionType',
    'policyExceptionJustification',
    'ongoingMonitoringExceptionStartDate',
    'ongoingMonitoringExceptionEndDate',
    'ongoingMonitoringExceptionJustification',
    'unauthorizedModelInProduction',
    'nextScheduledValidationDateInThePast',
    'specificFramework',
    'mrmCategory',
    'mrmSubcategory',
    'isValuationRisk',
    'isIcaapEconomicalCapital',
    'isAi',
    'isEsg',
    'isLegacy',
    //Champs non éditables
    'modelID',
    'functionalID',
    'nextFullValidationDateProcedure',
    'nextPeriodicValidationDateProcedure',
    'modelOwnerTeams',
    'modelOwnerEstablishment',
    'modelOwnerPosition',
    'lastValidationType',
    'lastCertificationDate',
    'lastFullValidationDate',
    'lastPeriodicValidationDate',
    'lastMinorEvolutionReviewDate',
    'certifierName',
    'certifierTeam',
    'certifierPosition',
    'lastCertificationResult',
    'inherentModelRiskScore',
    // "modelRiskScore",
    // "residualModelRiskScore",
    'modelRiskAssessmentResults',
    'validationStatus',
    'validationStatusRationale',
    'criticalScoreDimension',
    'averageModelRiskScore',
    'treeDeterminationAnswers',
    'insertionFromImport',
    'mitigationActions',
];
const MODEL_DETAIL_FIELDS_DEFAULT = [
    'name',
    'description',
    'treeDeterminationAnswers',
    'modelID',
    'initialID',
    'functionalID',
    'ECBID',
    'ECBAnnexID',
    'underDeclarationConversion',
    'inventoriedConversion',
    'modelStatusDeletionDate',
    'modelStatusRetirementDate',
    'clonedFrom',
    'modelType',
    'specificFramework',
    'output',
    'validatorsLocation',
    'coreModel',
    'coreModelParent',
    'declarer',
    'declarationDate',
    'modelOwner',
    'modelOwnerTeams',
    'modelOwnerEstablishment',
    'modelOwnerPosition',
    'modelOwnerDelegation',
    'modelDeveloperTeam',
    'modelValidatorTeams',
    'businessSponsor',
    'riskCategory',
    'microRiskCategory',
    'microRiskSubCategory',
    'applicationDomainLevel1',
    'applicationDomainLevel2',
    'applicationDomainLevel3',
    'championOrChallenger',
    'championModels',
    'inHouseOrVendor',
    'vendor',
    'modelNatureStandard',
    'modelNatureDetailedString',
    'modelObservability',
    'upStreamNotAvailable',
    'downStreamNotAvailable',
    'upstreamModels',
    'downstreamModels',
    'backgroundModelRelations',
    'foregroundModelRelations',
    'lastValidationType',
    'lastFullValidationDate',
    'lastPeriodicValidationDate',
    'lastMinorEvolutionReviewDate',
    'validationStatus',
    'validationStatusRationale',
    'lastCertificationDate',
    'nextScheduledValidationDate',
    'nextValidationDateVerificationComment',
    'nextScheduledValidationType',
    'nextFullValidationDateProcedure',
    'nextPeriodicValidationDateProcedure',
    'validationPlanFullValidationCurrentYearInitial',
    'validationPlanPeriodicReviewCurrentYearInitial',
    'validationPlanOtherReviewCurrentYearInitial',
    'validationPlanFullValidationCurrentYear',
    'validationPlanPeriodicReviewCurrentYear',
    'validationPlanOtherReviewCurrentYear',
    'entryExitValidationPlanFullValidation',
    'entryExitValidationPlanPeriodicReview',
    'entryExitValidationPlanOtherReview',
    'validationPlanFullValidationStatus',
    'validationPlanPeriodicReviewStatus',
    'validationPlanOtherReviewStatus',
    'firstImplementationDate',
    'implementationsEntities',
    'certifierName',
    'certifierTeam',
    'certifierPosition',
    'lastCertificationResult',
    'inherentModelRiskScore',
    // "modelRiskScore",
    // "residualModelRiskScore",
    'modelRiskAssessmentResults',
    'criticalScoreDimension',
    'averageModelRiskScore',
    'tieringReferenceModels',
    'tieringReferenceForModels',
    'tieringsEntities',
    'operationalRiskFlag',
    'productionRun',
    'previsionalDate',
    'certificationsEntities',
    'certificationsIssuesEntities',
    'modelUsesEntities',
    'mrasEntities',
    'reviewsEntities',
    'numberOfOpenNotices',
    'numberOfCriticalNotices',
    'numberOfV2Notices',
    'noticesEntities',
    'findingsEntities',
    // 'iggBceRecommendationEntities',
    'versionsSummary',
    'deletionComment',
    'nonModelComment',
    'nonModelConversionDate',
    'mrmTeams',
    'dataSource',
    'documentsEntities',
    'validationReportsEntities',
    'modelingDocumentsEntities',
    'mitigationActions',
    'coveringMitigationActionsEntities',
    'reservingMitigationActionsEntities',
    'insertionFromImport',
    'modelStatus',
    'nonModelStatus',
    'retirementStatus',
    'retirementCommittee',
    'retirementJustificationMrm',
    'retirementDocumentsEntities',
    'retirementJustificationLod1',
    'retirementRefusedJustification',
    'policyExceptionStartDate',
    'policyExceptionEndDate',
    'policyExceptionType',
    'policyExceptionJustification',
    'ongoingMonitoringExceptionStartDate',
    'ongoingMonitoringExceptionEndDate',
    'ongoingMonitoringExceptionJustification',
    'unauthorizedModelInProduction',
    'nextScheduledValidationDateInThePast',
    'retirementRequestDate',
    'retirementExpectedDate',
    'specificFrameworkJustification',
    'specificFrameworkConversionDate',
    'convertSpecificFrameworkDocumentsEntities',
    'mrmCategory',
    'mrmSubcategory',
    'isValuationRisk',
    'isIcaapEconomicalCapital',
    'isAi',
    'isEsg',
    'isLegacy',
];
const MODEL_LIST_FIELDS_DEFAULT = [
    'modelID',
    'name',
    'modelType',
    'riskCategory',
    'modelOwner',
    'modelDeveloperTeam',
    'modelValidatorTeams',
];

const MODEL_FIELDS_BY_STATUS = {
    functionalID: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    name: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    description: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelType: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    output: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelStatus: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    expectedStatus: {
        draft: ['detail'],
        candidate: ['detail'],
        underDeclaration: ['detail'],
    },
    modelOwner: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelDeveloperTeam: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    businessSponsor: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    riskCategory: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    microRiskCategory: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    microRiskSubCategory: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    championOrChallenger: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    championModels: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    inHouseOrVendor: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    vendor: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    dataSource: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelNatureStandard: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelNatureDetailedString: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    documentsEntities: {
        nonModel: ['detail', 'edit'],
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    versionsSummary: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    changeLogComment: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    underDeclarationConversion: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    inventoriedConversion: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelOwnerTeams: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelOwnerEntity: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelOwnerDelegation: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelValidatorTeams: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    mrmTeams: {
        draft: ['detail'],
        candidate: ['detail'],
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail'],
    },
    mrmTeamsOverride: {
        draft: ['edit'],
        candidate: ['edit'],
        underDeclaration: ['edit'],
        readyToBeInventoried: ['edit'],
    },
    applicationDomainLevel1: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    applicationDomainLevel2: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    applicationDomainLevel3: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelID: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    initialID: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    systemID: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    ECBID: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    ECBAnnexID: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    coreModel: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    coreModelParent: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    upStreamNotAvailable: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    downStreamNotAvailable: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    upstreamModels: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    downstreamModels: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    operationalRiskFlag: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    productionRun: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    previsionalDate: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    clonedFrom: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    firstImplementationDate: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    implementationsEntities: {
        candidate: ['detail'],
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    mitigationActions: {
        candidate: ['detail'],
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail'],
    },
    coveringMitigationActionsEntities: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    reservingMitigationActionsEntities: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelUsesEntities: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    tieringsEntities: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    tieringReferenceModels: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    tieringReferenceForModels: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    treeDeterminationAnswers: {
        draft: ['detail'],
        candidate: ['detail'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelOwnerEstablishment: {
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelRiskAssessmentResults: {
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    validationStatus: {
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    criticalScoreDimension: {
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    averageModelRiskScore: {
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    backgroundModelRelations: {
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    foregroundModelRelations: {
        candidate: ['detail'],
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail'],
    },
    nextScheduledValidationDate: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    nextValidationDateVerificationComment: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    modelObservability: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    reviewsEntities: {
        underDeclaration: ['detail'],
        readyToBeInventoried: ['detail'],
    },
    policyExceptionType: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    policyExceptionStartDate: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit']
    },
    policyExceptionEndDate: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit']
    },
    policyExceptionJustification: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit']
    },
    ongoingMonitoringExceptionStartDate: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit']
    },
    ongoingMonitoringExceptionEndDate: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit']
    },
    ongoingMonitoringExceptionJustification: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit']
    },
    unauthorizedModelInProduction: {
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit']
    },
    mrmCategory: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    mrmSubcategory: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    isValuationRisk: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    isIcaapEconomicalCapital: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    isAi: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    isEsg: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
    isLegacy: {
        draft: ['detail', 'edit'],
        candidate: ['detail', 'edit'],
        underDeclaration: ['detail', 'edit'],
        readyToBeInventoried: ['detail', 'edit'],
    },
};

const FORCE_HIDE_BEFORE_CANDIDATE = ['operationalRiskFlag'];

const FORCE_HIDE_BEFORE_INVENTORIED = [
    'mrasEntities',
    'inherentModelRiskScore',
    // "modelRiskScore",
    // "residualModelRiskScore",
    'modelRiskAssessmentResults',
    'validationStatus',
    'validationStatusRationale',
    'criticalScoreDimension',
    'averageModelRiskScore',
    'lastCertificationResult',
];

const enableByStatus = (entity, propertyName, context) => {
    let enable = true;

    // Si on est dans le contexte de la vue de détail du modèle, on affiche tous les champs renseignés
    if (context === CONTEXT_DETAIL) {

        if (
            FORCE_HIDE_BEFORE_CANDIDATE.includes(propertyName) &&
            entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT')
        ) {
            return false;
        }

        if (
            FORCE_HIDE_BEFORE_INVENTORIED.includes(propertyName) &&
            !['MODEL_STATUS_ACTIVE', 'MODEL_STATUS_RETIRED', 'MODEL_STATUS_DELETED']
                .map(ParameterStore)
                .includes(entity.modelStatus)
        ) {
            return false;
        }

        //Cas particulier de ModelUsesEntities undefined, cette propriété est asynchrone
        if (propertyName === 'modelUsesEntities' && entity.hasModelUses) {
            return true;
        }

        return (
            (entity[propertyName] !== undefined && !Array.isArray(entity[propertyName])) ||
            (entity[propertyName] !== undefined &&
                Array.isArray(entity[propertyName]) &&
                entity[propertyName].length > 0) ||
            (entity.nonModel === true &&
                MODEL_FIELDS_BY_STATUS[propertyName] !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].nonModel !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].nonModel.includes(context)) ||
            (entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT') &&
                !entity.expectedStatus &&
                MODEL_FIELDS_BY_STATUS[propertyName] !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].draft !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].draft.includes(context)) ||
            (entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT') &&
                entity.expectedStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') &&
                MODEL_FIELDS_BY_STATUS[propertyName] !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].candidate !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].candidate.includes(context)) ||
            (entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') &&
                !entity.expectedStatus &&
                MODEL_FIELDS_BY_STATUS[propertyName] !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].underDeclaration !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].underDeclaration.includes(context)) ||
            (entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') &&
                entity.expectedStatus === ParameterStore('MODEL_STATUS_ACTIVE') &&
                MODEL_FIELDS_BY_STATUS[propertyName] !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].readyToBeInventoried !== undefined &&
                MODEL_FIELDS_BY_STATUS[propertyName].readyToBeInventoried.includes(context)) ||
            entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE')
        );
    }

    // Si le modèle n'est pas en Draft, qu'il est clôné ou qu'il est en cours d'insertion, on affiche tous les champs
    if (entity.modelStatus !== ParameterStore('MODEL_STATUS_DRAFT') || entity.clonedFrom || !entity.id) {
        enable = true;
    }

    // Sinon, si on est en Draft, si la règle d'affichage du champ n'est pas définie ou l'est est n'autorise pas l'affichage dans le contexte, le champ ne doit pas apparaître
    if (entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT')) {
        if (MODEL_FIELDS_BY_STATUS[propertyName] === undefined) {
            enable = false;
        } else if (
            MODEL_FIELDS_BY_STATUS[propertyName].draft === undefined ||
            !MODEL_FIELDS_BY_STATUS[propertyName].draft.includes(context)
        ) {
            enable = false;
        }
        if (
            entity.expectedStatus &&
            MODEL_FIELDS_BY_STATUS[propertyName] !== undefined &&
            MODEL_FIELDS_BY_STATUS[propertyName].candidate !== undefined &&
            MODEL_FIELDS_BY_STATUS[propertyName].candidate.includes(context)
        ) {
            enable = true;
        }
    }

    // Si on est dans le contexte de la vue de détail du modèle, et que le champ ne doit pas être affiché (voir conditions précédentes), on force l'affichage de tous champs renseignés
    if (context === CONTEXT_DETAIL && enable === false) {
        enable = entity[propertyName] !== undefined;
    }

    return enable;
};

const requiredByStatus = (entity, propertyName, propertiesOverride = {modelStatus: null, expectedStatus: null}) => {
    let required = [];

    const modelStatus = propertiesOverride?.modelStatus ?? entity.modelStatus;
    const expectedStatus = propertiesOverride?.expectedStatus ?? entity.expectedStatus;

    if (userHasRoleMRM()) {
        if (modelStatus === ParameterStore('MODEL_STATUS_ACTIVE')) {
            return ['modelValidatorTeams'].includes(propertyName);
        }

        // Bypass, sauf pour passer en "inventoried"
        if (
            !(
                modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION')
                && expectedStatus === ParameterStore('MODEL_STATUS_ACTIVE')
            )
        ) {
            return false;
        }
    }

    if (entity.insertionFromImport === true) {
        if (
            modelStatus === ParameterStore('MODEL_STATUS_ACTIVE') &&
            userHasOwnershipRights(User.getId(), entity)
        ) {
            required = required.concat([
                'name',
                'description',
                'modelType',
                'modelOwner',
                'modelDeveloperTeam',
                'riskCategory',
            ]);
        } else if (modelStatus === ParameterStore('MODEL_STATUS_DRAFT')) {
            required = required.concat([
                'name',
                'description',
                'modelOwner',
                'modelDeveloperTeam',
                'riskCategory',
                'modelType',
            ]);
        } else {
            //Champs requis du statut Under Declaration
            required = required.concat([
                'name',
                'description',
                'modelType',
                'output',
                'modelOwner',
                'modelDeveloperTeam',
                'businessSponsor',
                'riskCategory',
                'microRiskCategory',
                'microRiskSubCategory',
                'championOrChallenger',
                'championModels',
                'inHouseOrVendor',
                'vendor',
                'dataSource',
                'modelNatureStandard',
            ]);
        }
    } else {
        if (userHasRoleMRM() && modelStatus !== ParameterStore('MODEL_STATUS_ACTIVE')) {
            required = required.concat(['name', 'description', 'modelType', 'riskCategory']);
        } else {
            if (
                //Conditions principales
                modelStatus === ParameterStore('MODEL_STATUS_DRAFT') ||
                //Conditions secondaires : Cumul des required pour les étapes suivantes
                modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') ||
                expectedStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') ||
                entity.underDeclarationConversion === true ||
                modelStatus === ParameterStore('MODEL_STATUS_ACTIVE')
            ) {
                required = required.concat([
                    'name',
                    'description',
                    'modelOwner',
                    'modelDeveloperTeam',
                    'riskCategory',
                    'modelType',
                    'productionRun',
                ]);
            }
            if (
                //Conditions principales
                modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') ||
                expectedStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') ||
                entity.underDeclarationConversion === true ||
                //Conditions secondaires : Cumul des required pour les étapes suivantes
                modelStatus === ParameterStore('MODEL_STATUS_ACTIVE')
            ) {
                required = required.concat([
                    'output',
                    'businessSponsor',
                    'riskCategory',
                    'microRiskCategory',
                    'microRiskSubCategory',
                    'applicationDomainLevel1',
                    'championOrChallenger',
                    'championModels',
                    'inHouseOrVendor',
                    'vendor',
                    'dataSource',
                    'modelNatureStandard',
                    'modelNatureDetailedString',
                    'modelOwnerTeams',
                    'modelOwnerEstablishment',
                    'modelRiskAssessmentResults',
                    'criticalScoreDimension',
                    'averageModelRiskScore',
                ]);
                if (entity.productionRun) {
                    required.push('firstImplementationDate');
                } else {
                    required.push('previsionalDate');
                }
            }
            if (
                modelStatus === ParameterStore('MODEL_STATUS_ACTIVE') ||
                expectedStatus === ParameterStore('MODEL_STATUS_ACTIVE')
            ) {
                if (
                    !entity.coveringMitigationActionsEntities ||
                    entity.coveringMitigationActionsEntities.length === 0
                ) {
                    required = required.concat(['mitigationActions']);
                }
            }
        }
    }

    if (required.includes(propertyName)) {
        return true;
    }
    return false;
};
/**
 * Ne pas oublier d'ajouter les champs non déclarés mais utilisés dans la fieldsSaveWhitelist.
 */
const validationByStatus = (entity, resource) => {
    if (userHasRoleMRM()) {
        return true;
    }

    if (entity.insertionFromImport === true) {
        return true;
    }

    let errors = [];
    let modelStatus = entity.modelStatus;
    modelStatus = modelStatus.split('/');
    let modelStatusId = modelStatus[modelStatus.length - 1];
    let modelStatusLabel = APIResourceStore.resources.parameters.getObservableItem(modelStatusId).label;

    let detailViewTab = null;

    if (userHasRoleMRM() && entity.modelStatus !== ParameterStore('MODEL_STATUS_ACTIVE')) {
    } else {
        if (
            entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE') ||
            entity.expectedStatus === ParameterStore('MODEL_STATUS_ACTIVE')
        ) {
            //Le model doit avoir minimum 1 modelUse associé, qui lui-même doit avoir un Use et un Detailed Use.
            if (typeof entity.hasModelUses === 'undefined' || entity.hasModelUses === false) {
                errors.push({
                    field: userHasRoleMRM() ? 'Status ' + modelStatusLabel : 'Uses',
                    detail: 'At least one Use is required at this stage.',
                }); //Note: Les champs Use et detailedUse sont obligatoire dans le formulaire.
                detailViewTab = detailViewTab === null ? 'Uses' : detailViewTab;
            }
            //Au moins une implémentation associée si le model est en production
            if (
                (
                    typeof entity.implementations === 'undefined'
                    || entity.implementations.length === 0
                )
                && entity.productionRun
            ) {
                errors.push({
                    field: userHasRoleMRM() ? 'Status ' + modelStatusLabel : 'Implementations',
                    detail: 'At least one Implementation is required at this stage.',
                });
                detailViewTab = detailViewTab === null ? 'Implementations' : detailViewTab;
            }
            //Au moins une RiskAndMitigationAction sauf si le flag noRiskAndMitigationAction == true
            if (
                !entity.mitigationActions &&
                (typeof entity.coveringMitigationActions === 'undefined' ||
                    entity.coveringMitigationActions.length === 0)
            ) {
                errors.push({
                    field: userHasRoleMRM() ? 'Status ' + modelStatusLabel : 'Mitigation Action',
                    detail: 'At least one Mitigation Action is required at this stage.',
                });
                detailViewTab = detailViewTab === null ? 'Mitigation actions' : detailViewTab;
            }
            //Les données de tiering doivent être remplies
            if (typeof entity.tierings === 'undefined' || entity.tierings.length === 0) {
                errors.push({
                    field: userHasRoleMRM() ? 'Status ' + modelStatusLabel : 'Tierings',
                    detail: 'At least one Tiering is required at this stage.',
                });
                detailViewTab = detailViewTab === null ? 'Tiering' : detailViewTab;
            }
        }
    }

    if (errors.length > 0) {
        if (detailViewTab !== null) {
            Navigation.router.history.push(`/resource/${resource.instanceId}/${entity.id}/detail?tab=${detailViewTab}`);
        }
        return errors;
    }
    return true;
};

export const needHighlightField = (entity, propertyName, queryParams) => {
    /** Highlight des champs **supplémentaires** requis pour passer à la prochaine étape du workflow model */
    const nextStatuses = getNextStatuses(entity.modelStatus, entity.expectedStatus);
    const required = requiredByStatus(entity, propertyName, nextStatuses)
        && !requiredByStatus(entity, propertyName)
        && !entity[propertyName];

    // Ajout du highlight depuis les queryParams si besoin
    return required || needHighlightFieldCommon(entity, propertyName, queryParams, 'certification');
};

const header = (entity) => {
    let header = [];

    if (entity.nonModelStatus === ParameterStore('NON_MODEL_STATUS_NON_MODEL_AWAITING')) {
        header.push(<ModelStepProvider key={'model_step'} forceStep={0}/>);
    } else {
        header.push(<ModelStepProvider key={'model_step'} entity={entity}/>);
    }

    if (entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT') && userHasDeclarerRights(User.getId(), entity)) {
        if (!entity.expectedStatus) {
            header.push(
                <Header
                    key={'header_draft'}
                    text={
                        'As a second step, please fill in the required information before submitting the candidate to MRM for review.\n' +
                        'As long as it is not submitted, your draft is not visible by MRM. It can be saved at any time. '
                    }
                />
            );
        } else {
            header.push(
                <Header
                    key={'header_draft'}
                    text={
                        'Your model candidate is currently being reviewed by MRM, you can continue your declaration. Please add at least one use, one implementation, one mitigation action (or indicate that this is not applicable) and the tiering of the model.'
                    }
                />
            );
        }
    } else if (
        entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') &&
        !entity.expectedStatus &&
        userHasDeclarerRights(User.getId(), entity)
    ) {
        header.push(
            <Header
                text={
                    'In order to finalize the declaration, please declare at least one use, one implementation (if the model runs in production), one mitigation action (or indicate that it is not applicable) and the tiering of the model then submit to MRM by clicking on the green arrow button.'
                }
            />
        );
    }

    if (
        entity.clonedFrom &&
        // Etapes 4 et 5 du workflow : under declaration, avec expected status "ACTIVE" ou non
        entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') &&
        userHasRoleMRM()
    ) {
        let clonedFromModel = APIResourceStore.resources.models.getObservableItem(getIdFromIri(entity.clonedFrom));
        let modelDisplay = clonedFromModel.toString || '...loading...';
        header.push(<Header key="duplication" text={`This declaration is a duplication of model ${modelDisplay}.`}/>);
    }

    // En contexte de certification et de surlignage :
    if (new URLSearchParams(window.location.search)?.get('certification')) {
        header.push(
            <Header
                key="certification"
                text={
                    'Highlighted fields in ID card, Properties and Implementations tabs must be completed in order to certify this model.' +
                    'Please also complete the other fields to the best of your knowledge.'
                }
            />
        );
    }

    return header.length > 0 ? header : null;
};

let tierings = new APIResource({
    id: 'tierings',
    instanceId: 'model_admin_tierings',
    name: 'Tierings',
    icon: 'brain',
});

/**
 *
 * @param { import('../Services/APIResource/APIResource').APIResource} models
 * @returns {Object.<string, import('../Services/APIResource/APIResource').APIResourceField>}
 */
export const MODEL_FIELDS = (models) =>
    ({
        modelID: {
            title: 'ID',
            width: 50,
            helperText: 'Short model ID defined by MRM tool',
            params: {
                filterMulti: true,
            },
            edit: (field, value, onChange, entity) => {
                return TextProvider.getDisplay(field, value, entity);
            },
        },
        name: {
            title: 'Name',
            type: 'text',
            maxLength: 255,
            helperText: 'Full model name in accordance with the model documentation or the model directory',
            required: (entity) => requiredByStatus(entity, 'name'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'name', context),
            width: 400,
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        description: {
            title: 'Description',
            type: 'textarea',
            helperText: 'Please describe the model and its purpose in a few words',
            required: (entity) => requiredByStatus(entity, 'description'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'description', context),
            width: 400,
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        treeDeterminationAnswers: {
            title: 'Tree determination answers',
            type: 'text',
            doNotResetValueWhenNotDisplayed: true,
            required: (entity) => requiredByStatus(entity, 'treeDeterminationAnswers'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'treeDeterminationAnswers', context),
            displayList: () => false,
            edit: (field, value, onChange, entity) => {
                return TextareaProvider.getDisplay(field, value, entity);
            },
        },
        treeDeterminationResult: {
            title: 'Tree determination Result',
            type: 'text',
            maxLength: 255,
            required: (entity) => requiredByStatus(entity, 'treeDeterminationResult'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'treeDeterminationResult', context),
        },
        insertionFromImport: {
            title: 'Imported',
            type: 'bool',
            displayList: () => false,
            displayConditions: (entity, entity2, key, context) =>
                userHasRoleMRM() && enableByStatus(entity, 'insertionFromImport', context),
        },
        initialID: {
            title: 'Initial ID',
            type: 'text',
            maxLength: 255,
            helperText: 'Model ID used before the MRM tool implementation',
            params: {
                filterMulti: true,
            },
            disableTypeFormatValidation: true,
            required: (entity) => requiredByStatus(entity, 'initialID'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'initialID', context),
        },
        functionalID: {
            title: 'Functional ID',
            type: 'text',
            maxLength: 255,
            helperText: 'Model ID defined by MRM tool',
            params: {
                filterMulti: true,
            },
            width: 400,
            edit: (field, value, onChange, entity) => {
                return TextProvider.getDisplay(field, value, entity);
            },
            required: (entity) => requiredByStatus(entity, 'functionalID'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'functionalID', context),
        },
        systemID: {
            title: 'System ID',
            type: 'text',
            helperText: 'Model ID given on IT systems',
            required: (entity) => requiredByStatus(entity, 'systemID'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'systemID', context),
            displayList: () => false,
        },
        ECBID: {
            title: 'ECB ID',
            type: 'text',
            maxLength: 255,
            helperText: 'Model ID defined by the ECB',
            bulk: true,
            disableTypeFormatValidation: true,
            required: (entity) => requiredByStatus(entity, 'ECBID'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'ECBID', context),
        },
        ECBAnnexID: {
            title: 'ECB Model Name',
            type: 'text',
            maxLength: 255,
            helperText: 'Additional model ID defined by the ECB',
            bulk: true,
            disableTypeFormatValidation: true,
            required: (entity) => requiredByStatus(entity, 'ECBAnnexID'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'ECBAnnexID', context),
        },
        modelType: {
            title: 'Model type',
            type: 'parameter',
            params: {type: PARAMETER_TYPE_MODEL_TYPE, multi: false},
            helperText: 'Characterization of the model based on its function (ex: PD, Pricer, VaR…)',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'modelType'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'modelType', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        coreModel: {
            title: 'Core model',
            type: 'bool',
            helperText:
                'A core model is either: \n' +
                '- an independent model which can be used on a standalone basis \n' +
                '- an aggregated model which can be split into sub-models for development and validation purposes \n',
            required: (entity) => requiredByStatus(entity, 'coreModel'),
            doNotResetValueWhenNotDisplayed: true,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'coreModel', context),
            bulk: true,
        },
        coreModelParent: {
            title: 'Core model parent',
            type: 'model',
            helperText: 'Functional ID of the Core model parent',
            params: {
                resource: 'models',
                instanceId: 'allModels',
                multi: false,
                links: true,
                linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                endpoints: {
                    getAll: 'models/all-models',
                },
            },
            required: (entity) => requiredByStatus(entity, 'coreModelParent'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'coreModel', context),
            bulk: true,
            //Disabled for Skander
            //displayConditions: (entity, entity2, key, context) =>
            //    entity.coreModel === false &&
            //    enableByStatus(entity, 'coreModel', context),
        },
        clonedFrom: {
            title: 'Duplicated from',
            type: 'model',
            params: {
                resource: 'models',
                instanceId: 'allModels',
                multi: false,
                links: true,
                linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                endpoints: {
                    getAll: 'models/all-models',
                },
            },
            doNotResetValueWhenNotDisplayed: true,
            required: (entity) => requiredByStatus(entity, 'clonedFrom'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'clonedFrom', context),
            displayList: () => false,
        },
        declarer: {
            title: 'Declarer',
            type: 'userAsync',
            params: {
                resource: 'users',
                displayField: 'toString',
                editDisplayField: 'fullNameWithTeam',
                sortField: 'lastName',
                multi: false,
                links: false,
                endpoints: {
                    getAll: 'users/all-users/all',
                },
            },
            required: (entity) => requiredByStatus(entity, 'declarer'),
            //displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'declarer', context)
        },
        declarationDate: {
            title: 'Inventory date',
            type: 'date',
            required: (entity) => requiredByStatus(entity, 'declarationDate'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'declarationDate', context),
            edit: (field, value, onChange, entity, routeParams) => {
                if (entity.insertionFromImport === false) {
                    return DateProvider.getDisplay(field, value, entity, {});
                }
                return DateProvider.getEdit(field, value, onChange, entity, routeParams);
            },
        },
        modelOwner: {
            title: 'Model owner',
            type: 'user',
            params: {
                resource: 'users',
                instanceId: 'users_mo',
                displayField: 'toString',
                editDisplayField: 'fullNameWithTeam',
                sortField: 'lastName',
                multi: false,
                links: false,
                endpoints: {
                    getAll: 'users/all-users/mo',
                },
            },
            helperText:
                'The Model Owner is responsible for ensuring that the development, implementation and continued use of models are carried out in accordance with the principles set out in the MRM policy',
            bulk: true,
            display: (field, value, entity, props, resourceDetailComponent, context) => {
                if (context === 'edit' && userHasOwnershipRights(User.getId(), entity)) {
                    field.issueButton = {
                        tooltip: 'Request a model owner change',
                        title: `Model owner change request for model ${entity.functionalID}`,
                        description: 'New model owner: ',
                        type: ParameterStore('TROUBLE_TYPE_OWNERSHIP'),
                    };
                } else {
                    field.issueButton = false;
                }
                return EntityAsyncProvider.getDisplay(field, value, entity, props);
            },
            displayList: (field, value, entity, props) => {
                return EntityAsyncProvider.getDisplay(field, value, entity, props);
            },
            edit: (field, value, onChange, entity, routeParams) => {
                if (
                    entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE')
                    && userHasDeclarerRights(User.getId(), entity)
                    && !userHasRoleMRM() //Cas de MRM qui serait Declarer
                ) {
                    return UserProvider.getDisplay(field, value, entity, {});
                }
                return UserProvider.getEdit(field, value, onChange, entity, routeParams);
            },
            required: (entity) => requiredByStatus(entity, 'modelOwner'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'modelOwner', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        modelOwnerTeams: {
            title: 'Model owner team',
            type: 'entityTree',
            params: {
                resource: 'scopes',
                instanceId: 'scopes_mot',
                displayField: 'title',
                multi: true,
                links: false,
                childrenPropertyName: 'childScopes',
                endpoints: {
                    getAll: 'scopes/all-scopes/mot',
                },
            },
            helperText: 'Team of the model owner (based on group repository)',
            bulk: false,
            required: (entity) => requiredByStatus(entity, 'modelOwnerTeams'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'modelOwnerTeams', context),
        },
        /*{title: 'Model owner team / position', type: 'entity', params: {resource: 'scopes', displayField: 'title', multi: false, links: false}, bulk: false},*/
        modelOwnerEstablishment: {
            title: 'Model owner establishment',
            type: 'text',
            helperText: 'Entity of the model owner (based on group repository)',
            edit: () => null,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'modelOwnerEstablishment', context),
        },
        modelOwnerPosition: {
            title: 'Model owner position',
            type: 'text',
            helperText: 'Function of the model owner (based on group repository)',
            edit: () => null,
            displayConditions: (entity) => enableByStatus(entity, 'modelOwnerPosition'),
            displayList: () => false,
        },
        modelOwnerDelegation: {
            title: 'Delegated model owner',
            type: 'user',
            params: {
                resource: 'users',
                instanceId: 'users_dmo',
                displayField: 'toString',
                editDisplayField: 'fullNameWithTeam',
                sortField: 'lastName',
                multi: false,
                links: false,
                endpoints: {
                    getAll: 'users/all-users/dmo',
                },
            },
            helperText: 'Backup of the Model Owner in charge of the update of the inventory on a regular basis',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'modelOwnerDelegation'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'modelOwnerDelegation', context),
            edit: (field, value, onChange, entity, routeParams) => {
                if (entity.modelOwnerDelegation === `/api/users/${User.getId()}`) {
                    return EntityAsyncProvider.getDisplay(field, value, entity, {});
                }
                return EntityAsyncProvider.getEdit(field, value, onChange, entity, routeParams);
            },
        },
        modelOwnerDelegationAccepted: {
            title: 'Delegation acceptance',
            type: 'bool',
            helperText: 'Delegation was accepted.',
            required: (entity) => requiredByStatus(entity, 'modelOwnerDelegationAccepted'),
            displayList: () => false,
            displayConditions: (entity, entity2, key, context) =>
                entity.modelOwnerDelegation && enableByStatus(entity, 'modelOwnerDelegationAccepted', context),
        },
        modelOwnerDelegationDenyComment: {
            title: 'Justification of the delegation refusal',
            type: 'text',
            helperText: 'Justification of the delegation refusal',
            required: (entity) => requiredByStatus(entity, 'modelOwnerDelegationDenyComment'),
            displayList: () => false,
            displayCondition: (entity, entity2, key, context) =>
                entity.modelOwnerDelegation &&
                !entity.modelOwnerDelegationAccepted &&
                enableByStatus(entity, 'modelOwnerDelegationDenyComment', context),
        },

        modelDeveloperTeam: {
            title: 'Model developer team',
            type: 'entityTree',
            params: {
                multi: false,
                resource: 'scopes',
                instanceId: 'scopes_dev',
                displayField: 'title',
                childrenPropertyName: 'childScopes',
                endpoints: {
                    getAll: 'scopes/all-scopes/dev',
                },
            },
            helperText:
                'The Model Developer team leads all model development activities, including methodology and design',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'modelDeveloperTeam'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'modelDeveloperTeam', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        modelValidatorTeams: {
            title: 'Model validator teams',
            type: 'entityTree',
            params: {
                multi: true,
                resource: 'scopes',
                instanceId: 'scopes_val',
                displayField: 'title',
                childrenPropertyName: 'childScopes',
                endpoints: {
                    getAll: 'scopes/all-scopes/val',
                },
            },
            helperText:
                'The Model Validator team is responsible for independently verifying that the models proposed by the Model Owner are indeed suitable for their intended use and that the associated model risk is correctly identified, assessed and, if necessary, reduced',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'modelValidatorTeams'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'modelValidatorTeams', context),
            highlighted: needHighlightField,
        },
        mrmTeamsOverride: {
            title: 'MRM Teams',
            helperText:
                "The MRM function ensures that the inventory of the Group's models is exhaustive and up-to-date\n" +
                'Oversees the good application of the procedures and policy',
            type: 'entity',
            params: {
                resource: 'scopes',
                instanceId: 'scopes_mrm',
                displayField: 'title',
                multi: true,
                endpoints: {
                    getAll: 'scopes/mrm',
                },
            },
            bulk: true,
            displayList: () => null,
            display: (field, value, entity, props) => {
                return EntityAsyncProvider.getDisplay(
                    {
                        title: 'MRM Teams',
                        type: 'entityAsync',
                        params: {
                            resource: 'scopes',
                            instanceId: 'scopes_mrm',
                            displayField: 'title',
                            multi: true,
                            endpoints: {
                                getAll: 'scopes/all-scopes/mrm',
                            },
                        },
                    },
                    entity.mrmTeams,
                    entity,
                    props
                );
            },
            required: (entity) => requiredByStatus(entity, 'mrmTeamsOverride'),
            token: false,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'mrmTeamsOverride', context) && userHasRoleMRM(),
        },
        mrmTeams: {
            title: 'MRM Teams',
            type: 'entityAsync',
            params: {
                resource: 'scopes',
                instanceId: 'scopes_mrm',
                displayField: 'title',
                multi: true,
                endpoints: {
                    getAll: 'scopes/all-scopes/mrm',
                },
            },
            edit: () => null,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'mrmTeams', context),
            helperText:
                "The MRM function ensures that the inventory of the Group's models is exhaustive and up-to-date\n" +
                'Oversees the good application of the procedures and policy',
        },
        businessSponsor: {
            title: 'Business Sponsor',
            type: 'user',
            params: {
                resource: 'users',
                instanceId: 'users_bs',
                displayField: 'toString',
                editDisplayField: 'fullNameWithTeam',
                sortField: 'lastName',
                multi: false,
                links: false,
                endpoints: {
                    getAll: 'users/all-users/bs',
                },
            },
            helperText:
                'The Business Sponsor is a senior member of the business who has an overview of the use of the model',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'businessSponsor'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'businessSponsor', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        riskCategory: {
            title: 'Risk Category',
            type: 'parameter',
            params: {type: PARAMETER_TYPE_RISK_CATEGORY, multi: false},
            helperText: '1st risk category level' + '\nDefined following the internal risk taxonomy of the BPCE group',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'riskCategory'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'riskCategory', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        microRiskCategory: {
            title: 'Micro-Risk Category',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MICRO_RISK_CATEGORY,
                multi: false,
            },
            helperText: '2nd risk category level' + '\nDefined following the internal risk taxonomy of the BPCE group',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'microRiskCategory'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'microRiskCategory', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        microRiskSubCategory: {
            title: 'Micro-Risk Sub-Category',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MICRO_RISK_SUB_CATEGORY,
                multi: false,
            },
            helperText: '3rd risk category level' + '\nDefined following the internal risk taxonomy of the BPCE group',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'microRiskSubCategory'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'microRiskSubCategory', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        applicationDomainLevel1: {
            title: 'Scope of application - Level 1',
            type: 'parameter',
            params: {type: PARAMETER_TYPE_ADL1, multi: false},
            helperText: '1st population segmentation level of the initial perimeter',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'applicationDomainLevel1'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'applicationDomainLevel1', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        applicationDomainLevel2: {
            title: 'Scope of application - Level 2',
            type: 'parameter',
            params: {type: PARAMETER_TYPE_ADL2, multi: false},
            helperText: '2nd population segmentation level of the initial perimeter',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'applicationDomainLevel2'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'applicationDomainLevel2', context),
        },
        applicationDomainLevel3: {
            title: 'Scope of application - Level 3',
            type: 'parameter',
            params: {type: PARAMETER_TYPE_ADL3, multi: false},
            helperText: '3rd population segmentation level of the initial perimeter',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'applicationDomainLevel3'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'applicationDomainLevel3', context),
        },
        championOrChallenger: {
            title: 'Champion or challenger',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_CHAMPION_OR_CHALLENGER,
                multi: false,
            },
            helperText:
                'A champion model is a model developed and used for decision making purpose' +
                '\nA challenger model is a model built to provide another set of outcomes as a benchmark for a champion model',
            bulk: true,
            issueButton: false,
            required: (entity) => requiredByStatus(entity, 'championOrChallenger'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'championOrChallenger', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        championModels: {
            title: 'Associated Champion Model',
            type: 'model',
            params: {
                resource: 'models',
                instanceId: 'allModels',
                multi: false,
                links: true,
                linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                endpoints: {
                    getAll: 'models/all-models',
                },
            },
            helperText: 'Champion model challenged by the current model',
            required: (entity) =>
                entity.championOrChallenger &&
                entity.championOrChallenger === ParameterStore('CHAMPION_OR_CHALLENGER_CHALLENGER') &&
                requiredByStatus(entity, 'championModels'),
            displayConditions: (entity, entity2, key, context) =>
                entity.championOrChallenger &&
                entity.championOrChallenger === ParameterStore('CHAMPION_OR_CHALLENGER_CHALLENGER') &&
                enableByStatus(entity, 'championModels', context),
        },
        inHouseOrVendor: {
            title: 'In House or Vendor',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_IN_HOUSE_OR_VENDOR,
                multi: false,
            },
            helperText: 'Was the model designed internally (In House) or by a third party (Vendor) ?',
            bulk: true,
            issueButton: false,
            required: (entity) => requiredByStatus(entity, 'inHouseOrVendor'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'inHouseOrVendor', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        vendor: {
            title: 'Vendor',
            type: 'text',
            maxLength: 255,
            helperText: 'Vendor name',
            displayConditions: (entity, entity2, key, context) =>
                entity.inHouseOrVendor === ParameterStore('VENDOR') && enableByStatus(entity, 'vendor', context),
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'vendor'),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        modelNatureStandard: {
            title: 'Model Nature Standard',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MODEL_NATURE_STANDARD,
                multi: false,
            },
            helperText:
                'Quantitative: based on historical observations and statistical or mathematical assumptions or other data use techniques (purely statistical/mathematical or AI)\n' +
                'Expert: based on the judgments and assumptions made by one or more experts\n' +
                'Hybrid: consisting of a combination of the first two points',
            bulk: true,
            issueButton: false,
            required: (entity) => requiredByStatus(entity, 'modelNatureStandard'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'modelNatureStandard', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        modelNatureDetailedString: {
            title: 'Model Nature Detailed',
            type: 'text',
            maxLength: 255,
            helperText:
                'Main method used' +
                '\nFor pricing functions used in valuation, the main pricing model must be filled in this field.',
            required: (entity) => requiredByStatus(entity, 'modelNatureDetailedString'),
            displayConditions: (entity, entity2, key, context) =>
                entity.modelNatureStandard &&
                (entity.modelNatureStandard === ParameterStore('MODEL_NATURE_STANDARD_QUANTITATIVE') ||
                    entity.modelNatureStandard === ParameterStore('MODEL_NATURE_STANDARD_HYBRID')) &&
                enableByStatus(entity, 'modelNatureDetailedString', context),
            bulk: true,
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        modelObservability: {
            title: 'Model observability',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MODEL_OBSERVABILITY,
                multi: false,
            },
            helperText: 'Describe the observability of the model',
            required: (entity) => requiredByStatus(entity, 'modelObservability'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'modelObservability', context) && hasOneGroupEntityNTX(entity),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
            bulk: true,
        },
        downStreamNotAvailable: {
            title: '',
            type: 'mapped',
            params: {
                mapping: {
                    false: '',
                    true: 'Downstream models are not available',
                },
            },
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'downStreamNotAvailable'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'downStreamNotAvailable', context),
            display: (field, value, entity) => {
                return value ? <label>No downstream model</label> : null;
            },
            displayList: () => null,
            edit: (field, value, onChange, entity, routeParams, operation, bulkEntities = [], loading) => {
                let authorizeBulkEdit = true;
                if (bulkEntities.length) {
                    bulkEntities.forEach((bulkEntity) => {
                        if (
                            Array.isArray(bulkEntity.downstreamModels) &&
                            bulkEntity.downstreamModels.length > 0
                        ) {
                            authorizeBulkEdit = false;
                        }
                    });
                }
                return (
                    <FormControlLabel
                        style={{marginLeft: 10}}
                        checked={value}
                        key={'form-control-label-' + Date.now()}
                        control={
                            <Checkbox
                                checked={value}
                                disabled={loading || entity?.downstreamModels?.length > 0 || authorizeBulkEdit === false}
                                onChange={(event, state) => {
                                    onChange(state);
                                }}
                            />
                        }
                        label={<span>No downstream model</span>}
                    />
                );
            },
        },
        upStreamNotAvailable: {
            title: '',
            type: 'mapped',
            params: {
                mapping: {
                    false: '',
                    true: 'Upstream models are not available',
                },
            },
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'upStreamNotAvailable'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'upStreamNotAvailable', context),
            display: (field, value, entity) => {
                return value ? <label>No upstream model</label> : null;
            },
            displayList: () => null,
            edit: (field, value, onChange, entity, routeParams, operation, bulkEntities = [], loading) => {
                let authorizeBulkEdit = true;
                if (bulkEntities.length) {
                    bulkEntities.forEach((bulkEntity) => {
                        if (
                            Array.isArray(bulkEntity.upstreamModels) &&
                            bulkEntity.upstreamModels.length > 0
                        ) {
                            authorizeBulkEdit = false;
                        }
                    });
                }
                return (
                    <FormControlLabel
                        style={{marginLeft: 10}}
                        checked={value}
                        key={'form-control-label-' + Date.now()}
                        control={
                            <Checkbox
                                checked={value}
                                disabled={loading || entity?.upstreamModels?.length > 0 || authorizeBulkEdit === false}
                                onChange={(event, state) => {
                                    onChange(state);
                                }}
                            />
                        }
                        label={<span>No upstream model</span>}
                    />
                );
            },
        },
        upstreamModels: {
            title: 'Upstream Models',
            type: 'model',
            params: {
                resource: 'models',
                instanceId: 'allModels',
                multi: true,
                links: true,
                modalLinks: true,
                endpoints: {
                    getAll: 'models/all-models',
                }
            },
            helperText: 'Indicate here the models which outputs are used as input data for the current model',
            required: (entity) => requiredByStatus(entity, 'upstreamModels'),
            displayConditions: (entity, entity2, key, context) =>
                !entity.upStreamNotAvailable && enableByStatus(entity, 'upstreamModels', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
            displayList: (field, value, entity, props) => {
                if (entity && !entity.upStreamNotAvailable) {
                    return ModelProvider.getDisplay(field, value, entity, props);
                }
                return TextProvider.getDisplayList(field, 'No upstream model', entity, props);
            },
            bulk: true,
        },
        downstreamModels: {
            title: 'Downstream Models',
            type: 'model',
            params: {
                resource: 'models',
                instanceId: 'allModels',
                multi: true,
                links: true,
                modalLinks: true,
                endpoints: {
                    getAll: 'models/all-models',
                }
            },
            helperText: 'Indicate here the models which use the current model outputs as input data',
            required: (entity) => requiredByStatus(entity, 'downstreamModels'),
            displayConditions: (entity, entity2, key, context) =>
                !entity.downStreamNotAvailable && enableByStatus(entity, 'downstreamModels', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
            displayList: (field, value, entity, props) => {
                if (entity && !entity.downStreamNotAvailable) {
                    return ModelProvider.getDisplay(field, value, entity, props);
                }
                return TextProvider.getDisplayList(field, 'No downstream model', entity, props);
            },
            bulk: true,
        },
        lastValidationType: {
            title: 'Last validation type',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_REVIEW_TYPE,
                multi: false,
            },
            helperText: 'Last type of review undergone by the model',
            required: (entity) => requiredByStatus(entity, 'lastValidationType'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'lastValidationType', context),
        },
        lastFullValidationDate: {
            title: 'Last full validation date',
            type: 'date',
            helperText: 'Last initial validation, full revalidation or change validation (committee date)',
            required: (entity) => requiredByStatus(entity, 'lastFullValidationDate'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'lastFullValidationDate', context),
        },
        lastPeriodicValidationDate: {
            title: 'Last periodic review date',
            type: 'date',
            helperText: 'Last periodic review date (committee date)',
            required: (entity) => requiredByStatus(entity, 'lastPeriodicValidationDate'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'lastPeriodicValidationDate', context),
        },
        lastMinorEvolutionReviewDate: {
            title: 'Last change validation - partial date',
            type: 'date',
            helperText: 'Last change validation - partial date (committee date)',
            required: (entity) => requiredByStatus(entity, 'lastMinorEvolutionReviewDate'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'lastMinorEvolutionReviewDate', context),
        },
        validationStatus: {
            title: 'Validation Status',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MODEL_USE_VALIDATION_STATUS,
            },
            helperText: 'Committee decision on the last  review undergone by the model',
            required: (entity) => requiredByStatus(entity, 'validationStatus'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'validationStatus', context),
        },
        validationStatusRationale: {
            title: 'Validation Status Rationale',
            type: 'text',
            helperText: 'Comments from the committee to justify the validation status',
            required: (entity) => requiredByStatus(entity, 'validationStatusRationale'),
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'validationStatusRationale', context),
        },
        nextScheduledValidationDate: {
            title: 'Next scheduled validation date',
            type: 'date',
            required: (entity) => requiredByStatus(entity, 'nextScheduledValidationDate'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'nextScheduledValidationDate', context),
            helperText: (entity) => {
                let helper = [];
                helper.push({
                    text: 'Next validation date, as scheduled by Validation or MRM team',
                });
                if (new Date(entity?.nextScheduledValidationDate) < new Date()) {
                    helper.push({
                        type: 'danger',
                        icon: 'fa-exclamation-triangle',
                        text: 'Date is in the past',
                    });
                }
                if (entity?.nextScheduledValidationDateVerified === false) {
                    helper.push({
                        type: 'warning',
                        icon: 'fa-exclamation-triangle',
                        text: 'Unverified',
                    });
                }
                return helper;
            },
            edit: (field, value, onChange, entity, _routeParams) => {
                return field.display(field, value, entity, {});
            },
            display: (field, value, entity, props) => {
                return (
                    <DisplayTextField
                        {...props}
                        fieldName={field.title}
                        value={
                            value
                                ? entity.nextScheduledValidationDateVerified === false
                                    ? entity.nextValidationDateVerificationComment ?? DateFormatter.getYear(value)
                                    : DateFormatter.dayIsoToString(value)
                                : null
                        }
                    />
                );
            },
        },
        nextScheduledValidationType: {
            title: 'Next scheduled validation type',
            type: 'parameter',
            helperText: 'Type of the next scheduled validation',
            params: {
                type: PARAMETER_TYPE_REVIEW_TYPE,
                multi: false,
            },
            required: (entity) => requiredByStatus(entity, 'nextScheduledValidationType'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'nextScheduledValidationType', context),
            edit: (field, value, onChange, entity, routeParams) => {
                return ParameterProvider.getDisplay(field, value, entity);
            },
        },
        nextValidationDateVerificationComment: {
            title: 'Next validation date (Scheduled - Year)',
            type: 'text',
            required: (entity) => requiredByStatus(entity, 'nextValidationDateVerificationComment'),
            displayCondition: (entity, entity2, key, context) => false,
            displayList: () => null,
            edit: (field, value) => <DisplayTextField fieldName={field.title} value={value}/>,
            //enableByStatus(entity, 'nextValidationDateVerificationComment', context),
        },
        nextFullValidationDateProcedure: {
            title: 'Next full validation date (Procedure)',
            type: 'date',
            helperText:
                'Next theoretical full validation depending on the tiering of the model\n' +
                'The validation frequency is defined by MRM validation procedure',
            required: (entity) => requiredByStatus(entity, 'nextFullValidationDateProcedure'),
            displayConditions: (entity, entity2, key, context) =>
                entity.hasMrmTeamsForProcedure &&
                enableByStatus(entity, 'nextFullValidationDateProcedure', context),
            edit: (field, value) => DateProvider.getDisplay(field, value),
        },
        nextPeriodicValidationDateProcedure: {
            title: 'Next periodic review date (Procedure)',
            type: 'date',
            helperText:
                'Next theoretical periodic review depending on the tiering of the model\n' +
                'The validation frequency is defined by MRM validation procedure',
            required: (entity) => requiredByStatus(entity, 'nextPeriodicValidationDateProcedure'),
            displayConditions: (entity, entity2, key, context) =>
                entity.hasMrmTeamsForProcedure &&
                enableByStatus(entity, 'nextPeriodicValidationDateProcedure', context),
            edit: (field, value) => DateProvider.getDisplay(field, value),
        },
        validationPlanFullValidationCurrentYearInitial: {
            title: 'Validation Plan Full Validation current year - Initial',
            type: 'bool',
            helperText: 'For MRM follow-up purposes. Status at the beginning of the year.',
            edit: () => null,
        },
        validationPlanPeriodicReviewCurrentYearInitial: {
            title: 'Validation Plan Periodic Review current year - Initial',
            type: 'bool',
            helperText: 'For MRM follow-up purposes. Status at the beginning of the year.',
            edit: () => null,
        },
        validationPlanOtherReviewCurrentYearInitial: {
            title: 'Validation Plan change validation - partial current year - Initial',
            type: 'bool',
            helperText: 'For MRM follow-up purposes. Status at the beginning of the year.',
            edit: () => null,
        },
        validationPlanFullValidationCurrentYear: {
            title: 'Validation Plan Full Validation current year',
            type: 'bool',
            helperText: 'For MRM follow-up purposes. Current status.',
            edit: () => null,
        },
        validationPlanPeriodicReviewCurrentYear: {
            title: 'Validation Plan Periodic Review current year',
            type: 'bool',
            helperText: 'For MRM follow-up purposes. Current status.',
            edit: () => null,
        },
        validationPlanOtherReviewCurrentYear: {
            title: 'Validation Plan change validation - partial current year',
            type: 'bool',
            helperText: 'For MRM follow-up purposes. Current status.',
            edit: () => null,
        },
        entryExitValidationPlanFullValidation: {
            title: 'Entry / Exit Validation Plan Full Validation',
            type: 'parameter',
            helperText: 'For MRM follow-up purposes.',
            params: {
                type: PARAMETER_TYPE_VALIDATION_PLAN_ENTRYEXIT,
                multi: false,
            },
            edit: () => null,
        },
        entryExitValidationPlanPeriodicReview: {
            title: 'Entry / Exit Validation Plan Periodic Review',
            type: 'parameter',
            helperText: 'For MRM follow-up purposes.',
            params: {
                type: PARAMETER_TYPE_VALIDATION_PLAN_ENTRYEXIT,
                multi: false,
            },
            edit: () => null,
        },
        entryExitValidationPlanOtherReview: {
            title: 'Entry / Exit Validation Plan change validation - partial',
            type: 'parameter',
            helperText: 'For MRM follow-up purposes.',
            params: {
                type: PARAMETER_TYPE_VALIDATION_PLAN_ENTRYEXIT,
                multi: false,
            },
            edit: () => null,
        },
        validationPlanFullValidationStatus: {
            title: 'Validation plan full validation status',
            type: 'parameter',
            helperText: 'Automatically computed. Possible values are: Validation not started and not assigned, Validation assigned but not started, Validation in progress, Validation finalized (awaiting committee), Validated.',
            params: {
                type: PARAMETER_TYPE_VALIDATION_PLAN_STATUS,
                multi: false,
            },
            edit: () => null,
        },
        validationPlanPeriodicReviewStatus: {
            title: 'Validation plan periodic review status',
            type: 'parameter',
            helperText: 'Automatically computed. Possible values are: Validation not started and not assigned, Validation assigned but not started, Validation in progress, Validation finalized (awaiting committee), Validated.',
            params: {
                type: PARAMETER_TYPE_VALIDATION_PLAN_STATUS,
                multi: false,
            },
            edit: () => null,
        },
        validationPlanOtherReviewStatus: {
            title: 'Validation plan change validation - partial status',
            type: 'parameter',
            helperText: 'Automatically computed. Possible values are: Validation not started and not assigned, Validation assigned but not started, Validation in progress, Validation finalized (awaiting committee), Validated.',
            params: {
                type: PARAMETER_TYPE_VALIDATION_PLAN_STATUS,
                multi: false,
            },
            edit: () => null,
        },
        firstImplementationDate: {
            title: 'First Implementation date',
            type: 'date',
            helperText: 'Model implementation date in BPCE IT Systems',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'firstImplementationDate'),
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'firstImplementationDate', context),
        },
        dataSource: {
            title: 'Data sources',
            type: 'text',
            helperText: 'Description of the data sources used by the model',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'dataSource'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'dataSource', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        backgroundModelRelations: {
            title: 'Background Models',
            type: 'entity',
            params: {
                resource: 'background_model_relations',
                displayField: 'toString',
                multi: true,
                links: true,
                linkPath: (entity) => {
                    let entityPath = entity.backgroundModel;
                    if (!entityPath) return null;
                    entityPath = entityPath.split('/');
                    let modelId = entityPath[entityPath.length - 1];
                    return '/resource/models/' + modelId + '/detail';
                },
                tooltip: (entity) => entity.backgroundModelName,
            },
            helperText:
                'Models which have been replaced on a perimeter by the current model, or which have been used as the basis of development for the current model',
            edit: (field, value, onChange, entity, routeParams) => (
                <BackgroundModel field={field} entity={entity} value={value} onChange={onChange}/>
            ),
            required: (entity) => requiredByStatus(entity, 'backgroundModelRelations'),
            displayList: () => false,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'backgroundModelRelations', context),
        },
        foregroundModelRelations: {
            title: 'Offspring Models',
            type: 'entity',
            params: {
                resource: 'background_model_relations',
                displayField: 'toStringForeground',
                multi: true,
                links: true,
                linkPath: (entity) => {
                    let entityPath = entity.foregroundModel;
                    if (!entityPath) return null;
                    entityPath = entityPath.split('/');
                    let modelId = entityPath[entityPath.length - 1];
                    return '/resource/models/' + modelId + '/detail';
                },
                tooltip: (entity) => entity.foregroundModelName,
            },
            helperText: 'List of models created from the current model, or created to replace the current model',
            required: (entity) => requiredByStatus(entity, 'foregroundModelRelations'),
            displayList: () => false,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'foregroundModelRelations', context),
        },
        certifierName: {
            title: 'Last certifier name',
            type: 'text',
            helperText: 'Name of the last certifier',
            required: (entity) => requiredByStatus(entity, 'certifierName'),
            edit: (field, value) => <DisplayTextField fieldName={field.title} value={value}/>,
            /** On force le droit d'un des champs en front (même si les droits sont interdits en back)
             * pour imposer l'affichage de l'onglet "Certifications", lors du mode edition.
             */
            rights: {edit: true},
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'certifierName', context),
        },
        certifierTeam: {
            title: 'Last certifier team',
            type: 'text',
            helperText: 'Team of the last certifier (based on group repository)',
            required: (entity) => requiredByStatus(entity, 'certifierTeam'),
            edit: (field, value) => <DisplayTextField fieldName={field.title} value={value}/>,
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'certifierTeam', context),
        },
        certifierPosition: {
            title: 'Last certifier position',
            type: 'text',
            helperText: 'Position of the last certifier (based on group repository)',
            required: (entity) => requiredByStatus(entity, 'certifierPosition'),
            edit: (field, value) => <DisplayTextField fieldName={field.title} value={value}/>,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'certifierPosition', context),
        },
        lastCertificationResult: {
            title: 'Certification result',
            helperText: 'Result of the last certification',
            type: 'mapped',
            params: {
                mapping: {
                    Certified: 'Certified',
                    'Not certified': 'Not certified',
                    'New model': 'New model',
                },
            },
            required: (entity) => requiredByStatus(entity, 'lastCertificationResult'),
            edit: (field, value) => <DisplayTextField fieldName={field.title} value={value}/>,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'lastCertificationResult', context),
        },
        lastCertificationDate: {
            title: 'Last certification date',
            type: 'date',
            helperText: 'Date of the last certifier attestation of the model data',
            edit: (field, value) => DateProvider.getDisplay(field, value),
            required: (entity) => requiredByStatus(entity, 'lastCertificationDate'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'lastCertificationDate', context),
        },
        inherentModelRiskScore: {
            title: 'Inherent Model Risk Score',
            type: 'text',
            helperText: 'Model Risk Score without taking in account mitigations actions',
            required: (entity) => requiredByStatus(entity, 'inherentModelRiskScore'),
            displayConditions: (entity, entity2, key, context) => {
                return enableByStatus(entity, 'inherentModelRiskScore', context) && hasGroupEntityBPCE(entity);
            },
        },
        modelRiskAssessmentResults: {
            title: 'Model Risk Assessment Result',
            type: 'text',
            helperText: 'LoD 2 conclusion at the end of the Model Risk Assessment process',
            required: (entity) => requiredByStatus(entity, 'modelRiskAssessmentResults'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'modelRiskAssessmentResults', context),
        },
        criticalScoreDimension: {
            title: 'Critical Dimensions',
            type: 'text',
            helperText: 'Dimension(s) with the highest score obtained at the end of the Model Risk Assessment process',
            required: (entity) => requiredByStatus(entity, 'criticalScoreDimension'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'criticalScoreDimension', context),
        },
        averageModelRiskScore: {
            title: 'Model Risk Score',
            type: 'text',
            helperText: 'Last MRA score (maximum between the average score and the key dimensions maximum)',
            required: (entity) => requiredByStatus(entity, 'averageModelRiskScore'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'averageModelRiskScore', context),
        },
        operationalRiskFlag: {
            title: 'Operational risk flag',
            type: 'bool',
            helperText: 'Is the model covered by risk scenarios from the operational risk team ?',
            required: (entity) => requiredByStatus(entity, 'operationalRiskFlag'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'operationalRiskFlag', context),
            bulk: true,
        },
        productionRun: {
            title: 'Production run',
            type: 'bool',
            helperText: 'Has the model been launched in production ?',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'productionRun'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'productionRun', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        previsionalDate: {
            title: 'Planned implementation date',
            type: 'date',
            bulk: true,
            required: (entity, fieldId) => requiredByStatus(entity, fieldId),
            displayConditions: (entity, entity2, key, context, fieldId) => enableByStatus(entity, fieldId, context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        modelStatus: {
            title: 'Inventory status',
            type: 'parameter',
            params: {type: PARAMETER_TYPE_MODEL_STATUS, multi: false},
            helperText: 'Status of the model on the MRM lifecycle (under declaration, inventoried, retired…)',
            bulk: userHasRoleMRM,
            display: (field, value, entity, props) => {
                if (!entity.id) {
                    return ParameterProvider.getDisplay(field, entity.modelStatus, entity, props);
                }
                return TextProvider.getDisplay(field, entity.modelStatusDisplay, entity, props);
            },
            displayList: (field, value, entity, props, resource) => null,
            edit: (field, value, onChange, entity, routeParams, operation, bulkEntities = [], loading = false) => {
                return userHasRoleMRM() && !entity.expectedStatus ? (
                    <ParameterSelect
                        field={field}
                        value={value}
                        onChange={onChange}
                        issueButton={'issueButton' in field ? field.issueButton : true}
                        entity={entity}
                        clearable={true}
                        multi={false}
                        disabled={loading}
                    />
                ) : (
                    TextProvider.getDisplay(field, entity.modelStatusDisplay, entity, {})
                );
                //entity.modelStatus = (routeParams.modelId) ? modelStatusRetired : value;
            },
            required: (entity) => requiredByStatus(entity, 'modelStatus'),
            displayCondition: (entity, entity2, key, context) =>
                !(entity.nonModelStatus && !entity.deleted) && enableByStatus(entity, 'modelStatus', context),
        },
        modelStatusDisplay: {
            title: 'Inventory status',
            type: 'mapped',
            params: {
                mapping: {
                    'Draft': 'Draft',
                    'Candidate (awaiting validation)': 'Candidate (awaiting validation)',
                    'Model under declaration': 'Model under declaration',
                    'Ready to be inventoried (awaiting validation)': 'Ready to be inventoried (awaiting validation)',
                    'Inventoried model': 'Inventoried model',
                    'Retired': 'Retired',
                    'Deleted': 'Deleted',
                    'Specific framework': 'Specific framework',
                },
                mappingByInstance: (instanceId) => {
                    /**
                     * Dans les cas suivants, les resources ont un permanentFilter sur modelStatus,
                     * donc si on ne limite pas les status disponibles, ils peuvent venir s'ajouter au permanentFilter
                     * ce qui ajoute à tort des models dans la liste avec les status demandés.
                     */
                    switch (instanceId) {
                        case 'models':
                            return {'Inventoried model': 'Inventoried model'};
                        case 'models_under_declaration':
                            return {
                                'Model under declaration': 'Model under declaration',
                                'Ready to be inventoried (awaiting validation)': 'Ready to be inventoried (awaiting validation)',
                            };
                        case 'retired_models':
                            return {'Retired': 'Retired'};
                        case 'deleted_models':
                            return {'Deleted': 'Deleted'};
                        case 'specific_frameworks':
                            return {'Specific framework': 'Specific framework'};
                    }
                }
            },
            display: null,
            edit: null,
            bulk: userHasRoleMRM,
            displayList: (field, value, entity, props, resource) => {
                if (resource.instanceId === 'non_models') {
                    return null;
                }
                return TextProvider.getDisplayList(field, entity ? entity.modelStatusDisplay : null, entity, props);
            },
            displayListIfNotInLayout: true,
        },
        readableStatus: {
            // Pour les colonnes de la recherche
            title: "Status",
            display: null,
        },
        nonModelStatus: {
            title: 'Non-model status',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_NON_MODEL_STATUS,
                multi: false,
            },
            displayCondition: (entity) => !entity.deleted && entity.nonModelStatus,
            displayList: (field, value, entity, props, resource) => {
                if (resource.instanceId !== 'non_models') {
                    return null;
                }
                return ParameterProvider.getDisplayList(field, value, entity, props);
            },
        },
        underDeclarationConversion: {
            title: 'Under declaration conversion',
            type: 'bool',
            edit: () => null,
            display: () => null,
        },
        inventoriedConversion: {
            title: 'Inventoried conversion',
            type: 'bool',
            edit: () => null,
            display: () => null,
        },
        modelStatusDeletionDate: {
            title: 'Deletion date',
            type: 'date',
            edit: () => null,
            displayList: (field, value, entity, props, resource) => {
                if (resource.instanceId !== 'deleted_models') {
                    return null;
                }
                return DateProvider.getDisplayList(field, value, entity, props, resource)
            },
            displayConditions: (entity, entity2, key, context) =>
                entity.modelStatus === ParameterStore('MODEL_STATUS_DELETED') &&
                enableByStatus(entity, 'modelStatusDeletionDate', context),
        },
        modelStatusRetirementDate: {
            title: 'Retirement date',
            type: 'date',
            edit: () => null,
            displayList: (field, value, entity, props, resource) => {
                if (resource.instanceId !== 'retired_models') {
                    return null;
                }
                return DateProvider.getDisplayList(field, value, entity, props, resource)
            },
            displayConditions: (entity, entity2, key, context) =>
                entity.modelStatus === ParameterStore('MODEL_STATUS_RETIRED') &&
                enableByStatus(entity, 'modelStatusRetirementDate', context),
        },
        expectedStatus: {
            title: 'Expected status',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MODEL_STATUS,
                multi: false,
                filters: (parameter) => {
                    if (
                        parameter['@id'] === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') ||
                        parameter['@id'] === ParameterStore('MODEL_STATUS_ACTIVE')
                    ) {
                        return parameter;
                    }
                    return null;
                },
            },
            edit: (field, value) => {
                return (
                    <EntityDisplay
                        resourceId="parameters"
                        resourceLabel="label"
                        label={field.title}
                        value={value}
                        links={false}
                    />
                );
            },
            displayList: () => null,
            required: (entity) => requiredByStatus(entity, 'expectedStatus'),
            displayConditions: (entity, entity2, key, context) =>
                entity.expectedStatus && enableByStatus(entity, 'expectedStatus', context),
        },
        output: {
            title: 'Output',
            type: 'text',
            helperText:
                'Describe output metrics from the items (e.g. fair value of an option, default probability of a loan…)',
            required: (entity) => hasOneGroupEntityNTX(entity) && requiredByStatus(entity, 'output'),
            displayConditions: (entity, entity2, key, context) => {
                return enableByStatus(entity, 'output', context);
            },
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
            bulk: true,
        },
        referenceDocumentation: {
            title: 'Reference documentation',
            type: 'text',
            required: (entity) => requiredByStatus(entity, 'referenceDocumentation'),
            displayConditions: (entity, entity2, key, context) =>
                hasOneGroupEntityNTX(entity) && enableByStatus(entity, 'referenceDocumentation', context),
        },
        validatorsLocation: {
            title: 'Validators location',
            type: 'parameter',
            helperText: 'Location of the model validation team',
            params: {
                type: PARAMETER_TYPE_MODEL_VALIDATOR_LOCATION,
                multi: false,
            },
            required: (entity) => requiredByStatus(entity, 'validatorsLocation'),
            displayConditions: (entity, entity2, key, context) =>
                hasOneGroupEntityNTX(entity) && enableByStatus(entity, 'validatorsLocation', context),
            bulk: true,
        },
        mitigationActions: {
            title: 'Mitigation actions',
            type: 'bool',
            params: {
                mapping: mitigationActionsMap,
            },
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'mitigationActions'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'mitigationActions', context),
            display: (field, value, entity) => {
                if (
                    !(
                        Array.isArray(entity.coveringMitigationActionsEntities) &&
                        entity.coveringMitigationActionsEntities.length > 0
                    )
                ) {
                    return (
                        <FormControlLabel
                            style={{marginLeft: 10}}
                            control={
                                <Checkbox
                                    checked={value === undefined ? false : value /** composant contrôlé => checked doit pas être undefined */}
                                    disabled={userHasNoRole(User, entity)}
                                    onChange={(event, state) => {
                                        entity.mitigationActions = state;
                                        models.apiPut({
                                            '@id': entity['@id'],
                                            id: entity.id,
                                            mitigationActions: state
                                        }).then();
                                    }}
                                />
                            }
                            label={<span>No mitigation action is applicable to this model.</span>}
                        />
                    );
                }
                return BoolProvider.getDisplay(field, value, entity);
            },
            displayList: (field, value, _entity, _props) => {
                if (value === 'true' || value === true) {
                    return field.params.mapping['true'];
                }
                return field.params.mapping['false'];
            },
            edit: (field, value, onChange, entity, routeParams, operation, bulkEntities = [], loading) => {
                if (bulkEntities.length) {
                    let authorizeBulkEdit = true;
                    bulkEntities.forEach((bulkEntity) => {
                        if (
                            Array.isArray(bulkEntity.coveringMitigationActions) &&
                            bulkEntity.coveringMitigationActions.length > 0
                        ) {
                            authorizeBulkEdit = false;
                        }
                    });
                    return authorizeBulkEdit ? <FormControlLabel
                            style={{marginLeft: 10}}
                            checked={value}
                            key={'form-control-label-' + Date.now()}
                            control={
                                <Checkbox
                                    checked={value === undefined ? false : value}
                                    disabled={loading}
                                    onChange={(event, state) => {
                                        onChange(state);
                                    }}
                                />
                            }
                            label={<span>No mitigation action is applicable to this model.</span>}
                        />
                        : undefined;
                }

                if (
                    Array.isArray(entity.coveringMitigationActionsEntities) &&
                    entity.coveringMitigationActionsEntities.length > 0
                ) {
                    return BoolProvider.getDisplay(field, value, entity);
                }
                return (
                    <FormControlLabel
                        style={{marginLeft: 10}}
                        checked={value}
                        key={'form-control-label-' + Date.now()}
                        control={
                            <Checkbox
                                checked={value === undefined ? false : value}
                                onChange={(event, state) => {
                                    onChange(state);
                                }}
                            />
                        }
                        label={<span>No mitigation action is applicable to this model.</span>}
                    />
                );
            },
        },
        modelComplexity: {
            title: 'Model complexity',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_COMPLEXITY_LEVEL,
                multi: false,
            },
            helperText:
                'The value of the field depends on the model complexity:' +
                '\nThree levels are identified: « High », « Medium », « Low »',
            required: (entity) => requiredByStatus(entity, 'modelComplexity'),
            edit: () => null,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'modelComplexity', context),
        },
        certificationsEntities: {
            title: 'Certifications',
            display: (field, value, entity, props, resourceDetailComponent) => {
                const currentActiveCertificationCampaignId =
                    entity.currentActiveCertificationCampaignId;

                const hasActiveCertificationCampaign = !!(currentActiveCertificationCampaignId);

                const alreadyHasTrouble =
                    entity &&
                    entity.issuesEntities &&
                    entity.issuesEntities.filter(
                        (v) => v.modelCertificationCampaignId === currentActiveCertificationCampaignId
                    ).length > 0;

                return (
                    <div>
                        <TableDisplay
                            dense={true}
                            rows={value}
                            cols={[
                                {label: 'Date', field: 'dateString'},
                                {label: 'Certifier', field: 'user'},
                                {label: 'Certifier team', field: 'userTeam'},
                                {label: 'Certifier position', field: 'userPosition'},
                                {label: 'Note', field: 'note'},
                            ]}
                            buttons={
                                (userHasOwnershipRights(User.getId(), entity) || userHasRoleMRM()) &&
                                hasActiveCertificationCampaign &&
                                !entity.certifyInActiveCampaigns &&
                                entity.allRequirementsCheckedToCertify
                                    ? // &&
                                      //  !alreadyCertified &&
                                      //  !alreadyHasTrouble
                                    [
                                        {
                                            label: 'New certification',
                                            icon: 'fa-plus',
                                            disabled: alreadyHasTrouble,
                                            onClick: () =>
                                                Modal.open({
                                                    title: 'New certification',
                                                    content: (
                                                        <AddCertificationForm
                                                            ids={[entity.id]}
                                                            modelName={entity.functionalID}
                                                            modelCertificationCampaign={
                                                                currentActiveCertificationCampaignId
                                                            }
                                                            resourceDetailComponent={resourceDetailComponent}
                                                        />
                                                    ),
                                                }),
                                        },
                                    ]
                                    : []
                            }
                        />
                    </div>
                );
            },
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'certificationsEntities', context),
        },
        certificationsIssuesEntities: {
            title: 'Certifications Troubles',
            edit: () => null,
            display: (field, value, entity, props) => {

                const currentActiveCertificationCampaignId =
                    entity.currentActiveCertificationCampaignId;

                const hasActiveCertificationCampaign = !!(currentActiveCertificationCampaignId);

                const alreadyCertified =
                    value &&
                    value.filter((v) => v.modelCertificationCampaignId === currentActiveCertificationCampaignId)
                        .length > 0;

                const alreadyHasTrouble =
                    entity &&
                    entity.issuesEntities &&
                    entity.issuesEntities.filter(
                        (v) =>
                            v.modelCertificationCampaignId === currentActiveCertificationCampaignId &&
                            v.statusString !== 'Closed'
                    ).length > 0;

                const certificationIssuesEntities =
                    entity && entity.issuesEntities
                        ? entity.issuesEntities.filter(
                            (issue) => issue.type === ParameterStore('TROUBLE_TYPE_CERTIFICATION')
                        )
                        : [];
                return (
                    <div>
                        <TableDisplay
                            dense={true}
                            rows={certificationIssuesEntities}
                            cols={[
                                {label: 'Date', field: 'dateString'},
                                {label: 'Certifier', field: 'user'},
                                {label: 'Title', field: 'title'},
                                {
                                    label: 'Description',
                                    field: 'description',
                                },
                                {
                                    label: "Trouble status",
                                    field: 'statusString',
                                },
                                {
                                    label: "Certification campaign",
                                    field: 'modelCertificationCampaign',
                                    type: 'entity',
                                    params: {
                                        resource: 'model_certification_campaigns',
                                        displayField: 'id',
                                        links: true,
                                    },
                                    noTooltip: true,
                                },
                            ]}
                            buttons={
                                userHasOwnershipRights(User.getId(), entity) && hasActiveCertificationCampaign
                                    ? // &&
                                      //  !alreadyCertified &&
                                      //  !alreadyHasTrouble
                                    [
                                        {
                                            label: 'Certification trouble',
                                            icon: 'fa-plus',
                                            disabled: alreadyCertified || alreadyHasTrouble,
                                            onClick: () =>
                                                Modal.open({
                                                    title: 'New certification trouble',
                                                    content: (
                                                        <IssueAddForm
                                                            title={'New certification trouble'}
                                                            type={ParameterStore('TROUBLE_TYPE_CERTIFICATION')}
                                                            models={[`/api/models/${entity.id}`]}
                                                            entity={entity}
                                                            modelCertificationCampaign={
                                                                `/api/model_certification_campaigns/${currentActiveCertificationCampaignId}`
                                                            }
                                                            description=""
                                                        />
                                                    ),
                                                }),
                                        },
                                    ]
                                    : []
                            }
                        />
                    </div>
                );
            },
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'certificationsIssuesEntities', context),
        },
        modelUsesEntities: {
            title: 'Model uses',
            display: (field, value, entity, props, resourceDetailComponent) => {
                // On utilise les mêmes conditions que dans ModelUseAdmin
                let conditionalFields = hasOneGroupEntityNTX(entity)
                    ? [
                        {
                            label: 'Taxonomy',
                            field: 'taxonomy',
                        },
                    ]
                    : [];

                return (
                    <TableDisplay
                        rows={value}
                        cols={[
                            {label: 'ID', field: 'id'},
                            {label: 'Use', field: 'useLabel'},
                            {
                                label: 'Detailed use',
                                field: 'detailedUseLabel',
                            },
                            {
                                label: 'MRM Perimeters',
                                field: 'mrmPerimetersString',
                            },
                            //{
                            //    label: 'Organizational Units',
                            //    field: 'OUsString',
                            //},
                            {
                                label: 'Business Line',
                                field: 'BLString',
                            },
                            {
                                label: 'Legal Entity',
                                field: 'legalEntityString',
                                width: '110',
                            },
                            ...conditionalFields,
                            {
                                label: 'Validation status',
                                field: 'validationStatus',
                            },
                            {
                                label: 'Validation status rationale',
                                field: 'validationStatusRationale',
                            },
                            {
                                label: 'Supervisor authorization',
                                field: 'supervisorAuthorizationString',
                                width: '90',
                            },

                            {
                                label: 'Main use',
                                field: 'mainUse',
                                type: 'bool',
                                width: '90',
                            },
                        ]}
                        actions={(value) => {
                            return (
                                <div>
                                    <OpenModalForEntities
                                        context={CONTEXT_DETAIL}
                                        instanceId={'model_uses'}
                                        entity={entity}
                                        value={value}
                                        resourceDetailComponent={resourceDetailComponent}
                                        resource={models}
                                    />
                                    {userHasStakeHolderRights(User, entity) ||
                                    userIsVal(User, entity) ||
                                    userHasRoleMRM() ? (
                                        <OpenModalForEntities
                                            context={CONTEXT_EDIT}
                                            instanceId={'model_uses'}
                                            entity={entity}
                                            value={value}
                                            resourceDetailComponent={resourceDetailComponent}
                                            resource={models}
                                        />
                                    ) : null}
                                </div>
                            );
                        }}
                        buttons={
                            !userHasSpecificRole(Role.IG)
                                ? [
                                    {
                                        label: 'New model use',
                                        icon: 'fa-plus',
                                        inModal: true,
                                        modalProps: {
                                            context: CONTEXT_ADD,
                                            instanceId: 'model_uses',
                                            parentInstanceId: models.instanceId,
                                            routeParams: {modelId: entity.id},
                                            parentId: entity.id,
                                            postSaveRedirectUrl: `/resource/${models.instanceId}/${entity.id}/detail?tab=Uses`
                                        }
                                    },
                                ]
                                : null
                        }
                    />
                );
            },
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'modelUsesEntities', context),
        },
        mrasEntities: {
            edit: () => null,
            display: (field, value, entity, props) => {
                let addMraButton = {
                    label: 'MRA',
                    inModal: true,
                    modalProps: {
                        context: CONTEXT_CUSTOM,
                        customInnerView: <MraInit initialModelId={entity.id}/>,
                    },
                    icon: 'fa-plus',
                };
                let addQuickMraButton = {
                    label: 'Quick MRA',
                    icon: 'fa-plus',
                    inModal: true,
                    modalProps: {
                        context: CONTEXT_ADD,
                        instanceId: 'quick_mras',
                        parentInstanceId: models.instanceId,
                        routeParams: {modelId: entity.id},
                        parentId: entity.id,
                        postSaveRedirectUrl: `/resource/${models.instanceId}/${entity.id}/detail?tab=MRA`
                    }
                };

                const role = getUserRole(entity);

                if (
                    !(
                        userHasMRMRights(User, entity) ||
                        userIsVal(User, entity)
                    )
                ) {
                    addMraButton = null;
                }
                if (!userHasRoleADMIN()) {
                    addQuickMraButton = null;
                }

                const mras = (value ?? []).map((mra) => {
                    const {status, process} = mra;
                    let showEdition = false;
                    let showDetail = false;
                    let editLink = mra.quickMra
                        ? `/resource/quick_mras/${mra.id}/edit`
                        : `/resource/mras/${mra.id}/update`;
                    const detailLink = `/resource/${mra.quickMra ? 'quick_' : ''}mras/${mra.id}/detail`;
                    const instanceId = `${mra.quickMra ? 'quick_' : ''}mras`;
                    const canEdit = canEditByProcess(role, status, process);

                    if (canEdit) {
                        showEdition = true;
                        editLink = `/resource/${mra.quickMra ? 'quick_' : ''}mras/${mra.id}/update`;
                    } else {
                        showDetail = true;
                    }

                    return {
                        ...mra,
                        editLink,
                        detailLink,
                        showDetail,
                        showEdition,
                        instanceId
                    };
                });

                return (
                    <TableDisplay
                        selectedRow={entity.latestClosedMra ? parseInt(getIdFromIri(entity.latestClosedMra)) : null}
                        rows={mras}
                        cols={[
                            {
                                label: 'ID',
                                field: 'id'
                            },
                            {
                                label: 'Date',
                                field: 'dateString',
                                styles: {width: '15%'},
                            },
                            {
                                label: 'Author',
                                field: 'versionAuthor',
                                styles: {width: '20%'},
                            },
                            {label: 'Status', field: 'statusString'},
                            {label: 'Model Risk Score', field: 'score'},
                            {label: 'Model Risk assessment result', field: 'modelRiskAssessmentResult'},
                            {label: 'Critical dimensions', field: 'criticalDimensionsResult'},
                        ]}
                        actions={(value) => {
                            return (
                                <div>
                                    {value.showDetail && value.quickMra && (
                                        <OpenModal
                                            instanceId={value.instanceId}
                                            id={value.id}
                                            context={CONTEXT_DETAIL}
                                            modalTitle={value.id}
                                        />
                                    )}
                                    {value.showDetail && !value.quickMra && (
                                        <Link to={value.detailLink} style={styles.actionLink}>
                                            <DetailButton/>
                                        </Link>
                                    )}
                                    {value.showEdition && !value.insertionFromImport && (
                                        <Link to={value.editLink} style={styles.actionLink}>
                                            <EditButton/>
                                        </Link>
                                    )}
                                </div>
                            );
                        }}
                        buttons={[addMraButton, addQuickMraButton]}
                    />
                );
            },
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'mrasEntities', context),
        },
        reviewsEntities: {
            title: 'Reviews',
            display: (field, value, entity, props) => (
                <TableDisplay
                    rows={value}
                    cols={[
                        {label: 'Status', field: 'reviewStatusString'},
                        {label: 'Review ID', field: 'reviewId'},
                        {label: 'Review type', field: 'reviewTypeString'},
                        {label: 'Title', field: 'title'},
                        {
                            label: 'Planned starting date',
                            field: 'plannedStartingDateString',
                        },
                        {
                            label: 'Planned closing date',
                            field: 'plannedClosingDateString',
                        },
                        {
                            label: 'Committee date',
                            field: 'committeesLastDateString',
                        },
                        {
                            label: 'Documents',
                            field: 'documents',
                            type: 'entity',
                            params: {
                                resource: 'documents',
                            },
                            noTooltip: true,
                            style: {minWidth: '540px'},
                            display: (field, value, entity, props) => (
                                <DocumentListAccordion entity={entity} values={value}/>
                            ),
                        },
                    ]}
                    actions={(value) => {
                        APIResourceStore.resources.reviews.apiGetOne(value.id, true);
                        return (
                            <div>
                                {
                                    // Droits sur le Model :
                                    (userHasOwnershipRights(User.getId(), entity) ||
                                        userHasDeveloperRights(User, entity) ||
                                        userIsVal(User, entity) ||
                                        userHasMRMRights(User, entity) ||
                                        // Droits sur la Review :
                                        userHasContributingRights(User.getId(), value) ||
                                        userIsVal(User, value) ||
                                        userHasRoleIG()
                                    ) && (
                                        <Link
                                            to={'/resource/reviews/' + value.id + '/detail'}
                                            style={styles.actionLink}
                                        >
                                            <DetailButton/>
                                        </Link>
                                    )
                                }
                                {
                                    // Droits Review :
                                    (((userHasValidatorRights(User.getId(), value) ||
                                                userHasRights(User, value, 'validatorTeams', USER_SCOPE_MANAGER)) &&
                                            value.reviewStatus !== ParameterStore(REVIEW_STATUS_CLOSED)) ||
                                        // Droits Model :
                                        userHasMRMRights(User, entity)) && (
                                        <Link to={'/resource/reviews/' + value.id + '/edit'} style={styles.actionLink}>
                                            <EditButton/>
                                        </Link>
                                    )
                                }
                            </div>
                        );
                    }}
                    buttons={
                        (entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') ||
                            entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE')) &&
                        (userHasOwnershipRights(User.getId(), entity) || userHasDeveloperRights(User, entity))
                            ? [
                                {
                                    label: 'Request a review',
                                    icon: 'fa-plus',
                                    onClick: () =>
                                        Modal.open({
                                            title: 'Request a review',
                                            content: <ReviewRequestModal entity={entity} modelResource={models}/>,
                                        }),
                                },
                            ]
                            : ((entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') ||
                                    entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE')) &&
                                entity.modelValidatorTeams &&
                                userIsVal(User, entity)) ||
                            userHasMRMRights(User, entity)
                                ? [
                                    {
                                        label: 'New review',
                                        to: '/resource/reviews/add/' + entity.id,
                                        icon: 'fa-plus',
                                    },
                                ]
                                : []
                    }
                />
            ),
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'reviewsEntities', context),
        },
        numberOfOpenNotices: {
            title: 'Number of open and under review notices',
            type: 'int',
        },
        numberOfCriticalNotices: {
            title: 'Number of open and under review high severity notices',
            type: 'int',
        },
        numberOfV2Notices: {
            title: 'Number of open and under review medium severity notices',
            type: 'int',
        },
        noticesEntities: {
            title: 'Notices',
            display: (field, value, entity, props, resourceDetailComponent) => {
                let nonFilteredValues = value;
                const filteredValues = [];
                if (nonFilteredValues) {
                    nonFilteredValues.forEach((notice) => {
                        if (
                            notice.status !== ParameterStore('NOTICE_STATUS_DELETED') &&
                            ((notice.status === ParameterStore('NOTICE_STATUS_DRAFT') &&
                                    (User.profile.isMemberOfValidatorTeam || userHasRoleMRM())) ||
                                notice.status !== ParameterStore('NOTICE_STATUS_DRAFT'))
                        ) {
                            filteredValues.push(notice);
                        }
                    });
                }

                value = [
                    ...filteredValues.filter((notice) => notice.status === ParameterStore('NOTICE_STATUS_IN_PROGRESS')),
                    ...filteredValues.filter(
                        (notice) => notice.status === ParameterStore('NOTICE_STATUS_WAITING_FOR_VALIDATION')
                    ),
                    ...filteredValues.filter(
                        (notice) => notice.status === ParameterStore('NOTICE_STATUS_UPCOMING_VALIDATION_FOR_CLOSURE')
                    ),
                    ...filteredValues.filter((notice) => notice.status === ParameterStore('NOTICE_STATUS_CLOSED')),
                    ...filteredValues.filter((notice) => notice.status === ParameterStore('NOTICE_STATUS_DRAFT')),
                    ...filteredValues.filter((notice) => notice.status === ParameterStore('NOTICE_STATUS_ON_HOLD')),
                ];

                const hasAccessToItemDetail = (item) =>
                    userHasOwnershipRights(User.getId(), entity) ||
                    userHasNoticeOwnershipRights(User.getId(), item) ||
                    userHasDeveloperRights(User, entity) ||
                    userIsVal(User, entity) ||
                    userValidatorTeamManagedRights(User.profile.managedScopes, entity) ||
                    entity.amIBusinessSponsor ||
                    userHasRoleMRM() ||
                    userHasRoleIG();
                return (
                    <TableDisplay
                        rows={value}
                        cols={[
                            {
                                label: 'Status',
                                field: 'statusString',
                            },
                            {
                                label: 'ID',
                                field: 'id',
                                display: (field, value, notice, _props) => {
                                    return hasAccessToItemDetail(notice) ? (
                                        <OpenModal
                                            instanceId={'notices'}
                                            id={notice.id}
                                            context={CONTEXT_DETAIL}
                                            modalTitle={value}
                                            flat={true}
                                        />
                                    ) : (
                                        <>{value}</>
                                    );
                                },
                                tooltip: (field, value, entity) => entity.noticeDescription,
                            },
                            {
                                label: 'Title',
                                field: 'title',
                            },
                            {
                                label: 'Severity',
                                field: 'severityString',
                            },
                            {
                                label: 'Findings',
                                field: 'findings',
                                type: 'entity',
                                params: {
                                    resource: 'findings',
                                    displayField: 'title',
                                    multi: true,
                                    links: true,
                                    modalLinks: true,
                                    endpoints: {
                                        getAll: 'findings/all-findings',
                                    },
                                },
                                noTooltip: true,
                                display: (field, value, notice, props) => {
                                    return (
                                        <ReviewIssueRelation
                                            value={value}
                                            model={entity}
                                            entity={notice}
                                            {...props}
                                            resource={models}
                                            resourcePath={'models'}
                                            type={'finding'}
                                        />
                                    );
                                },
                            },
                            {
                                label: 'Deadline',
                                field: 'deadline',
                            },
                        ]}
                        actions={(value) => {
                            return (
                                <div>
                                    {hasAccessToItemDetail(value) &&
                                        <OpenModal
                                            instanceId={'notices'}
                                            id={value.id}
                                            context={CONTEXT_DETAIL}
                                            modalTitle={value.id}
                                            //Pour le context d'édition ou en cas de d'édition depuis la modal detail
                                            parentInstanceId={models.instanceId}
                                            parentId={entity.id}
                                            postSaveRedirectUrl={`/resource/${models.instanceId}/${entity.id}/detail?tab=Notices`}
                                            parentResourceComponent={resourceDetailComponent}
                                        />}
                                    {(value.status !== ParameterStore('NOTICE_STATUS_DELETED') &&
                                        value.status !== ParameterStore('NOTICE_STATUS_CLOSED') &&
                                        value.status !== ParameterStore('NOTICE_STATUS_DISMISSED') &&
                                        (userIsVal(User, entity) || userHasNoticeIssuerTeamsRights(User, value))) ||
                                    userHasRoleMRM() ? (
                                        <OpenModal
                                            parentInstanceId={models.instanceId}
                                            parentId={entity.id}
                                            instanceId="notices"
                                            id={value.id}
                                            context={CONTEXT_EDIT}
                                            modalTitle={value.title}
                                            postSaveRedirectUrl={`/resource/${models.instanceId}/${entity.id}/detail?tab=Notices`}
                                            parentResourceComponent={resourceDetailComponent}
                                            allowStayInModal={true}
                                        />
                                    ) : null}
                                </div>
                            );
                        }}
                        buttons={
                            userHasMRMRights(User, entity)
                                ? [
                                    {
                                        label: 'New notice',
                                        to: '/resource/notices/add/model/' + entity.id,
                                        icon: 'fa-plus',
                                    },
                                    {
                                        label: 'Confirm these notices',
                                        icon: 'fa-paper-plane',
                                        onClick: () => {
                                            Modal.open({
                                                title: 'Confirm these notices',
                                                content: (
                                                    <ValidateFindingNotice
                                                        findings={entity.findingsEntities}
                                                        notices={entity.noticesEntities}
                                                        resource={models}
                                                        entity={entity}
                                                        fromModel={true}
                                                    />
                                                ),
                                            });
                                        },
                                    },
                                ]
                                : null
                        }
                    />
                );
            },
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'noticesEntities', context),
        },
        findingsEntities: {
            title: 'Findings',
            display: (field, value, entity, props, resourceDetailComponent) => {
                let nonFilteredValues = value;
                const filteredValues = [];
                if (nonFilteredValues) {
                    nonFilteredValues.forEach((finding) => {
                        if (
                            finding.status !== ParameterStore(FINDING_STATUS_DELETED) &&
                            ((finding.status === ParameterStore(FINDING_STATUS_DRAFT) &&
                                    (User.profile.isMemberOfValidatorTeam || userHasRoleMRM())) ||
                                finding.status !== ParameterStore(FINDING_STATUS_DRAFT))
                        ) {
                            filteredValues.push(finding);
                        }
                    });
                }
                // on regroupe grossièrement les valeurs par statut, dans cet ordre
                value = [
                    ...filteredValues.filter((f) => f.status === ParameterStore(FINDING_STATUS_OPEN)),
                    ...filteredValues.filter((f) => f.status === ParameterStore(FINDING_STATUS_CLOSED)),
                    ...filteredValues.filter((f) => f.status === ParameterStore(FINDING_STATUS_DRAFT)),
                ];

                const hasAccessToItemDetail = (item) =>
                    userHasOwnershipRights(User.getId(), entity) ||
                    userHasNoticeOwnershipRights(User.getId(), item) ||
                    userHasDeveloperRights(User, entity) ||
                    userIsVal(User, entity) ||
                    userValidatorTeamManagedRights(User.profile.managedScopes, entity) ||
                    entity.amIBusinessSponsor ||
                    userHasRoleMRM() ||
                    userHasRoleIG();
                return (
                    <TableDisplay
                        rows={value}
                        cols={[
                            {
                                label: 'Status',
                                field: 'statusString',
                            },
                            {
                                label: 'Title',
                                field: 'title',
                                display: (field, value, finding, _props) => {
                                    return hasAccessToItemDetail(finding) ? (
                                        <OpenModal
                                            instanceId={'findings'}
                                            id={finding.id}
                                            context={CONTEXT_DETAIL}
                                            modalTitle={value}
                                            flat={true}
                                        />
                                    ) : (
                                        <>{value}</>
                                    );
                                },
                                tooltip: (field, value, entity) => entity.weaknessesDescription,
                            },
                            {
                                label: 'Severity',
                                field: 'severityString',
                            },
                            {
                                label: 'Notices',
                                field: 'notices',
                                type: 'entity',
                                params: {
                                    resource: 'notices',
                                    displayField: 'id',
                                    multi: true,
                                    links: true,
                                    modalLinks: true,
                                    endpoints: {
                                        getAll: 'i_t_systems/all-i_t_systems',
                                    },
                                },
                                noTooltip: true,
                                display: (field, value, finding, props) => {
                                    return (
                                        <ReviewIssueRelation
                                            value={value}
                                            model={entity}
                                            entity={finding}
                                            {...props}
                                            resource={models}
                                            resourcePath={'models'}
                                            type={'notice'}
                                        />
                                    );
                                },
                            },
                        ]}
                        actions={(value) => {
                            return (
                                <div>
                                    {hasAccessToItemDetail(value) &&
                                        <OpenModal
                                            instanceId={'findings'}
                                            id={value.id}
                                            context={CONTEXT_DETAIL}
                                            modalTitle={value.title}
                                            //Pour le context d'édition ou en cas de d'édition depuis la modal detail
                                            parentInstanceId={models.instanceId}
                                            parentId={entity.id}
                                            postSaveRedirectUrl={`/resource/${models.instanceId}/${entity.id}/detail?tab=Notices`}
                                            parentResourceComponent={resourceDetailComponent}
                                        />}
                                    {(value.status !== ParameterStore(FINDING_STATUS_DELETED) &&
                                        value.status !== ParameterStore(FINDING_STATUS_CLOSED) &&
                                        value.status !== ParameterStore(FINDING_STATUS_DISMISSED) &&
                                        (userIsVal(User, entity) || userHasIssuerTeamsRights(User, value))) ||
                                    userHasRoleMRM() ? (
                                        <OpenModal
                                            parentInstanceId={models.instanceId}
                                            parentId={entity.id}
                                            instanceId="findings"
                                            id={value.id}
                                            context={CONTEXT_EDIT}
                                            modalTitle={value.title}
                                            postSaveRedirectUrl={`/resource/${models.instanceId}/${entity.id}/detail?tab=Notices`}
                                            parentResourceComponent={resourceDetailComponent}
                                            allowStayInModal={true}
                                        />
                                    ) : null}
                                </div>
                            );
                        }}
                        buttons={
                            userHasMRMRights(User, entity)
                                ? [
                                    {
                                        label: 'New finding',
                                        to: '/resource/findings/add/model/' + entity.id,
                                        icon: 'fa-plus',
                                    },
                                    {
                                        label: 'Associate findings and notices',
                                        icon: 'fa-link',
                                        onClick: () => {
                                            Modal.open({
                                                title: 'Associate findings and notices',
                                                content: (
                                                    <AssociateFindingNotice
                                                        findings={entity.findingsEntities}
                                                        notices={entity.noticesEntities}
                                                        resource={models}
                                                        resourcePath={'models'}
                                                        entity={entity}
                                                    />
                                                ),
                                            });
                                        },
                                    },
                                ]
                                : null
                        }
                    />
                );
            },
            displayList: () => null,
        },
        iggBceRecommendationEntities: {
            title: 'IGG/BCE',
            display: (field, value, entity, props) => (
                <TableDisplay
                    rows={value}
                    cols={[
                        {label: 'Title', field: 'title'},
                        {label: 'Mission title', field: 'missionTitle'},
                        {label: 'Reference', field: 'reference'},
                        {
                            label: 'Status',
                            field: 'statusString',
                            width: '100',
                        },
                        {
                            label: 'Priority',
                            field: 'priorityString',
                            width: '100',
                        },
                        {
                            label: 'Origin',
                            field: 'originString',
                            width: '100',
                        },
                        {
                            label: 'Due date',
                            field: 'dueDateString',
                            width: '100',
                        },
                    ]}
                    actions={(value) => {
                        return (
                            <div>
                                <Link
                                    to={'/resource/recommendation_igg_bces/' + value.id + '/detail'}
                                    style={styles.actionLink}
                                >
                                    <DetailButton/>
                                </Link>
                                <Link
                                    to={'/resource/recommendation_igg_bces/' + value.id + '/edit'}
                                    style={styles.actionLink}
                                >
                                    <EditButton/>
                                </Link>
                            </div>
                        );
                    }}
                    buttons={
                        userIsVal(User, entity) || userHasRoleMRM()
                            ? [
                                {
                                    label: 'New recommendation IGG BCE',
                                    to: '/resource/recommendation_igg_bces/add/model/' + entity.id,
                                    icon: 'fa-plus',
                                },
                            ]
                            : null
                    }
                />
            ),
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'iggBceRecommendationEntities', context),
        },
        coveringMitigationActionsEntities: {
            title: 'Mitigation actions',
            display: (field, value, entity, _props, resourceDetailComponent) => {
                // On affiche les mitigations closed à la fin du tableau.
                const mitigationsNotClosed = value?.filter(v => v.status !== ParameterStore('MITIGATION_ACTION_STATUS_CLOSED')) || [];
                const mitigationsClosed = value?.filter(v => v.status === ParameterStore('MITIGATION_ACTION_STATUS_CLOSED')) || [];

                return <TableDisplay
                    rows={[...mitigationsNotClosed, ...mitigationsClosed]}
                    cols={[
                        {
                            label: 'ID',
                            field: 'id',
                        },
                        {
                            label: 'Status',
                            field: 'statusString',
                        },
                        {
                            label: 'Mitigation measure type',
                            field: 'mitigationMeasureTypeString',
                        },
                        {label: 'Title', field: 'title'},
                        {label: 'Description', field: 'description'},
                        {
                            label: 'Start date',
                            field: 'startDate',
                        },
                        {
                            label: 'Mitigation models',
                            field: 'reserveModels',
                            type: 'entity',
                            params: {
                                resource: 'models',
                                displayField: 'name',
                                links: true,
                                modalLinks: true,
                                multi: true,
                            },
                        },
                        {
                            label: 'Documents',
                            field: 'documents',
                            type: 'entity',
                            params: {
                                resource: 'documents',
                            },
                            noTooltip: true,
                            style: {minWidth: '540px'},
                            display: (field, value, entity, _props) => (
                                <DocumentListAccordion entity={entity} values={value}/>
                            ),
                        },
                    ]}
                    actions={(value) => {
                        return (
                            <div>
                                {userHasOwnershipRights(User.getId(), entity) ||
                                userHasDeveloperRights(User, entity) ||
                                userIsVal(User, entity) ||
                                userHasRoleMRM() ? (
                                    <OpenModalForEntities
                                        context={CONTEXT_DETAIL}
                                        instanceId={'mitigation_actions'}
                                        entity={entity}
                                        value={value}
                                        resourceDetailComponent={resourceDetailComponent}
                                        resource={models}
                                    />
                                ) : null}
                                {userHasOwnershipRights(User.getId(), entity) ||
                                userHasDeveloperRights(User, entity) ||
                                userIsVal(User, entity) ||
                                userHasRoleMRM() ? (
                                    <OpenModalForEntities
                                        context={CONTEXT_EDIT}
                                        instanceId={'mitigation_actions'}
                                        entity={entity}
                                        value={value}
                                        resourceDetailComponent={resourceDetailComponent}
                                        resource={models}
                                    />
                                ) : null}
                            </div>
                        );
                    }}
                    buttons={
                        !entity.mitigationActions &&
                        (userHasRoleMRM() ||
                            userHasOwnershipRights(User.getId(), entity) ||
                            userHasDeveloperRights(User, entity) ||
                            userIsVal(User, entity))
                            ? [
                                {
                                    label: 'New mitigation action',
                                    icon: 'fa-plus',
                                    inModal: true,
                                    modalProps: {
                                        context: CONTEXT_ADD,
                                        instanceId: 'mitigation_actions',
                                        parentInstanceId: models.instanceId,
                                        routeParams: {modelId: entity.id},
                                        parentId: entity.id,
                                        postSaveRedirectUrl: `/resource/${models.instanceId}/${entity.id}/detail?tab=Mitigation actions`
                                    }
                                },
                            ]
                            : []
                    }
                />
            },
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'coveringMitigationActionsEntities', context),
        },
        reservingMitigationActionsEntities: {
            title: 'Mitigation Models',
            display: (field, value, entity, props) => (
                <TableDisplay
                    rows={value}
                    cols={[
                        {
                            label: 'ID',
                            field: 'id',
                        },
                        {
                            label: 'Mitigation measure type',
                            field: 'mitigationMeasureTypeString',
                        },
                        {label: 'Label', field: 'title'},
                        {label: 'Description', field: 'description'},
                        {
                            label: 'Covered models',
                            field: 'coveredModels',
                            type: 'entity',
                            params: {
                                resource: 'models',
                                displayField: 'functionalID',
                                links: true,
                                multi: true,
                            },
                        },
                        {
                            label: 'Documents',
                            field: 'documents',
                            type: 'entity',
                            params: {
                                resource: 'documents',
                            },
                            noTooltip: true,
                            style: {minWidth: '540px'},
                            display: (field, value, entity, props) => (
                                <DocumentListAccordion entity={entity} values={value}/>
                            ),
                        },
                    ]}
                    actions={(value) => {
                        return (
                            <div>
                                {userHasOwnershipRights(User.getId(), entity) ||
                                userHasDeveloperRights(User, entity) ||
                                userIsVal(User, entity) ||
                                userHasRoleMRM() ? (
                                    <Link
                                        to={'/resource/mitigation_actions/' + value.id + '/detail'}
                                        style={styles.actionLink}
                                    >
                                        <DetailButton/>
                                    </Link>
                                ) : null}
                                {userHasOwnershipRights(User.getId(), entity) ||
                                userHasDeveloperRights(User, entity) ||
                                userIsVal(User, entity) ||
                                userHasRoleMRM() ? (
                                    <Link
                                        to={'/resource/mitigation_actions/' + value.id + '/edit'}
                                        style={styles.actionLink}
                                    >
                                        <EditButton/>
                                    </Link>
                                ) : null}
                            </div>
                        );
                    }}
                />
            ),
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                entity.reservingMitigationActionsEntities &&
                entity.reservingMitigationActionsEntities.length > 0 &&
                enableByStatus(entity, 'reservingMitigationActionsEntities', context),
        },
        lastTieringMaterialityLevelFinal: {
            title: 'Tiering: Materiality level',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MATERIALITY,
                multi: false,
            },
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
            //displayConditions: (entity, entity2, key, context) => false,
        },
        lastTieringModelUsageLevelFinal: {
            title: 'Tiering: Model usage level',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MODEL_USAGE,
                multi: false,
            },
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
            //displayConditions: (entity, entity2, key, context) => false,
        },
        lastTieringTierResultFinal: {
            title: 'Tiering: Tier result',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MODEL_TIER_RESULT,
                multi: false,
            },
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
            //displayConditions: (entity, entity2, key, context) => false,
        },
        lastTieringExternalImpactLevelFinal: {
            title: 'Tiering: External impact level',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_EXTERNAL_IMPACT,
                multi: false,
            },
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
            //displayConditions: (entity, entity2, key, context) => false,
        },
        lastTieringTierRationaleFinal: {
            title: 'Tiering: Tier rationale',
            type: 'text',
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
            //displayConditions: (entity, entity2, key, context) => false,
        },
        lastTieringComplexityLevelFinal: {
            title: 'Tiering: Complexity level',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_COMPLEXITY_LEVEL,
                multi: false,
            },
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
            //displayConditions: (entity, entity2, key, context) => false,
        },
        lastTieringComplexityRationaleFinal: {
            title: 'Tiering: Complexity rationale',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_TIERING_COMPLEXITY_RATIONALE,
                multi: false,
            },
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
            //displayConditions: (entity, entity2, key, context) => false,
        },
        lastTieringCategory: {
            title: 'Tiering: Category',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MODEL_TIERING_CATEGORY,
                multi: false,
            },
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
        },
        lastTieringMetricsFinal: {
            title: 'Tiering: Metrics',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_METRICS,
                multi: false,
            },
            bulk: false,
            required: false,
            displayListIfNotInLayout: true,
        },
        tieringsEntities: {
            title: 'Tierings',
            display: (field, value, entity, props, resourceDetailComponent) => {
                let complexityCol = hasOneGroupEntityNTX(entity)
                    ? [
                        {
                            label: 'Complexity level',
                            field: 'complexityLevelFinal',
                        },
                    ]
                    : [];
                return (
                    <TableDisplay
                        className={'model-tiering-entities'}
                        selectedRow={entity.tieringEntity ? parseInt(getIdFromIri(entity.tieringEntity)) : null}
                        rows={value}
                        cols={[
                            {label: 'ID', field: 'id'},
                            {label: 'Date', field: 'dateString'},
                            {
                                label: 'Materiality level',
                                field: 'materialityLevelFinal',
                            },
                            {
                                label: 'Model usage level',
                                field: 'modelUsageLevelFinal',
                            },
                            {
                                label: 'External impact level',
                                field: 'externalImpactLevelFinal',
                            },
                            ...complexityCol,
                            {label: 'Tier result', field: 'tierResultFinal'},
                            {
                                label: 'Tier rationale',
                                field: 'tierRationaleFinal',
                            },
                            {
                                label: 'Verified by MRM',
                                field: 'verifiedByMrm',
                                display: (field, value, tiering, props) => {
                                    return (
                                        <VerifiedButton
                                            readonly={
                                                /** MRM ne peut vérifier que le dernier Tiering */
                                                !userHasRoleMRM() || !tiering.isLastTiering
                                            }
                                            entity={tiering}
                                            entityResource={'tierings'}
                                            property={'verifiedByMrm'}
                                            callback={() => {
                                                models.apiGetOne(entity.id, true).then((model) => {
                                                    resourceDetailComponent.entity = model;
                                                    resourceDetailComponent.forceUpdate();
                                                })
                                            }}
                                        />
                                    );
                                },
                            },
                        ]}
                        actions={(value) => {
                            return (
                                <div>
                                    {userHasRoleMRM() ||
                                    userHasOwnershipRights(User.getId(), entity) ||
                                    userHasDeveloperRights(User, entity) ||
                                    userHasImplementerRights(User, entity) ||
                                    userIsVal(User, entity) ||
                                    userHasBusinessSponsorRights(User.getId(), entity) ? (
                                        <OpenModalForEntities
                                            context={CONTEXT_DETAIL}
                                            instanceId={'tierings'}
                                            entity={entity}
                                            value={value}
                                            resourceDetailComponent={resourceDetailComponent}
                                            resource={models}
                                        />
                                    ) : null}
                                    {((!value.verifiedByMrm &&
                                            (userHasOwnershipRights(User.getId(), entity) ||
                                                userHasDeveloperRights(User, entity))) ||
                                        userHasRoleMRM()) &&
                                    value.isLastTiering ? (
                                        <OpenModalForEntities
                                            context={CONTEXT_EDIT}
                                            instanceId={'tierings'}
                                            entity={entity}
                                            value={value}
                                            resourceDetailComponent={resourceDetailComponent}
                                            resource={models}
                                        />
                                    ) : null}
                                </div>
                            );
                        }}
                        buttons={
                            (userHasRoleMRM() ||
                                userHasDeveloperRights(User, entity) ||
                                userHasDeclarerRights(User.getId(), entity) ||
                                userHasOwnershipRights(User.getId(), entity)) &&
                            (!entity.tieringsEntities ||
                                entity.tieringsEntities.length === 0 ||
                                entity.tieringsEntities[0]['verifiedByMrm'] === true)
                                ? [
                                    {
                                        label: 'New tiering',
                                        onClick: (button) => {
                                            Modal.open({
                                                title: button.label,
                                                className: 'modal-create-tiering',
                                                content: <TieringForm
                                                    model={entity}
                                                    parentInstanceId={models.instanceId}
                                                    parentResourceComponent={resourceDetailComponent}
                                                />,
                                            });
                                        },
                                        icon: 'fa-plus',
                                    },
                                ]
                                : []
                        }
                    />
                );
            },
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'tieringsEntities', context),
        },
        tieringReferenceModels: {
            title: 'Tiering model reference',
            type: 'model',
            params: {
                resource: 'models',
                instanceId: 'allModels',
                multi: true,
                links: true,
                linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                endpoints: {
                    getAll: 'models/all-models',
                }
            },
            helperText: 'Model from which the tiering of this model is derived',
            required: (entity, fieldId) => requiredByStatus(entity, fieldId),
            displayConditions: (entity, entity2, key, context, fieldId) => enableByStatus(entity, fieldId, context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        tieringReferenceForModels: {
            title: 'Tiering model reference for',
            type: 'model',
            params: {
                resource: 'models',
                instanceId: 'allModels',
                multi: true,
                links: true,
                linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                endpoints: {
                    getAll: 'models/all-models',
                }
            },
            helperText: 'Model(s) whose tiering is derived from this model',
            required: (entity, fieldId) => requiredByStatus(entity, fieldId),
            displayConditions: (entity, entity2, key, context, fieldId) => enableByStatus(entity, fieldId, context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        implementationsEntities: {
            title: 'Implementations',
            display: (field, value, entity, props, resourceDetailComponent) => (
                <TableDisplay
                    rows={value}
                    cols={[
                        {label: 'ID', field: 'id'},
                        {label: 'Date', field: 'dateString'},
                        {
                            label: 'Model implementer team',
                            field: 'modelImplementerTeam',
                        },
                        {label: 'IT System', field: 'ITSystem'},
                        {label: 'System Version', field: 'systemVersion'},
                    ]}
                    actions={(value) => {
                        return (
                            <div>
                                <OpenModalForEntities
                                    context={CONTEXT_DETAIL}
                                    instanceId={'implementations'}
                                    entity={entity}
                                    value={value}
                                    resourceDetailComponent={resourceDetailComponent}
                                    resource={models}
                                />
                                {userHasStakeHolderRights(User, entity) || userHasMRMRights(User, entity) ? (
                                    <OpenModalForEntities
                                        context={CONTEXT_EDIT}
                                        instanceId={'implementations'}
                                        entity={entity}
                                        value={value}
                                        resourceDetailComponent={resourceDetailComponent}
                                        resource={models}
                                    />
                                ) : null}
                            </div>
                        );
                    }}
                    buttons={
                        !userHasSpecificRole(Role.IG)
                            ? [
                                {
                                    label: 'New implementation',
                                    icon: 'fa-plus',
                                    inModal: true,
                                    modalProps: {
                                        context: CONTEXT_ADD,
                                        instanceId: 'implementations',
                                        parentInstanceId: models.instanceId,
                                        routeParams: {modelId: entity.id},
                                        parentId: entity.id,
                                        postSaveRedirectUrl: `/resource/${models.instanceId}/${entity.id}/detail?tab=Implementations`
                                    }
                                },
                            ]
                            : null
                    }
                />
            ),
            displayList: () => null,
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'implementationsEntities', context),
        },
        itSystems: {
            title: 'IT Systems',
            type: 'entity',
            displayList: (field, value, entity, props) => (value ? String.nlToBr(value.join('\n')) : ' '),
            params: {
                resource: 'i_t_systems',
                displayField: 'name',
                multi: true,
                endpoints: {
                    getAll: 'i_t_systems/all-i_t_systems',
                },
            },
            bulk: false,
        },
        deletionComment: {
            title: 'Deletion comment',
            type: 'textarea',
            edit: () => null,
            displayConditions: (entity, entity2, key, context) =>
                entity.modelStatus === ParameterStore('MODEL_STATUS_DELETED') &&
                enableByStatus(entity, 'deletionComment', context),
        },
        nonModelComment: {
            title: 'Justification of the conversion to non-model',
            type: 'textarea',
            edit: () => null,
            displayList: (field, value, entity, props, resource) => resource.instanceId === 'non_models' ? TextareaProvider.getDisplayList(field, value, entity, props, resource) : null,
            displayConditions: (entity, entity2, key, context) =>
                entity.nonModel === true && enableByStatus(entity, 'nonModelComment', context),
        },
        nonModelConversionDate: {
            title: 'Conversion to non-model date',
            type: 'date',
            edit: () => null,
            displayList: () => null,
            displayConditions: (entity, entity2, key, context, fieldId) =>
                entity.nonModel === true && enableByStatus(entity, fieldId, context),
        },
        versionsSummary: {
            title: 'Versions',
            display: (field, value, entity, props, resourceDetailComponent) => {
                return <VersionsDetail
                    model={entity}
                    resource={models}
                    entityResource={'models'}
                    resourceDetailComponent={resourceDetailComponent}
                />;
            },
            displayList: (field, value, entity, props) => null,
            token: false,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'versionsSummary', context),
        },
        //Additional fields without relation with Model
        changeLogComment: {
            title: 'Justification of the data update',
            type: 'textarea',
            display: (field, value, entity, props) => null,
            displayList: (field, value, entity, props) => null,
            bulk: false,
            displayConditions: (entity, entity2, key, context) => {
                return !userHasImplementerRights(User, entity) && enableByStatus(entity, 'changeLogComment', context);
            },
        },
        documentsEntities: {
            title: 'Documents',
            display: (field, value, entity, _props, _component) => (
                <DocumentManager
                    values={value}
                    entity={entity}
                    entityResource={'models'}
                    resourceComponent={_component}
                    allowedCategory={true}
                    categoryParameterSystemId={PARAMETER_TYPE_DOCUMENT_CATEGORY_FOR_MODEL}
                    bulkActions={[DOCUMENT_BULK_DOWNLOAD]}
                    onUpdate={(model) => {
                        _component.entity = model;
                        _component.forceUpdate();
                    }}
                    allowedAction={(entity, document, action) => {
                        if (action === DOCUMENT_ACTION_ADD) {
                            if (
                                userHasOwnershipRights(User.getId(), entity) ||
                                userHasDeveloperRights(User, entity) ||
                                userHasImplementerRights(User, entity) ||
                                userIsVal(User, entity) ||
                                userHasBusinessSponsorRights(User.getId(), entity) ||
                                userHasRoleMRM() ||
                                userHasRoleIG()
                            ) {
                                return true;
                            }
                        } else if (action === DOCUMENT_ACTION_SHOW || action === DOCUMENT_ACTION_LIST) {
                            if (
                                userHasOwnershipRights(User.getId(), entity) ||
                                userHasDeveloperRights(User, entity) ||
                                userIsVal(User, entity) ||
                                userHasBusinessSponsorRights(User.getId(), entity) ||
                                userHasRoleMRM() ||
                                userHasRoleIG()
                            ) {
                                return true && document.category !== ParameterStore("DOCUMENT_CATEGORY_FOR_MODEL_POST_COMMITTEE_REPORT");
                            }
                        } else if (action === DOCUMENT_ACTION_EDIT) {
                            if (userHasMRMRights(User, entity) || userHasRoleADMIN()) {
                                return true;
                            }
                        } else if (action === DOCUMENT_ACTION_DELETE) {
                            if (document?.author === `/api/users/${User.getId()}` || userIsVal(User, entity) || userHasRoleMRM()) {
                                return true;
                            }
                        }
                        return false;
                    }}
                    allowedSelectModels={true}
                />
            ),
            displayList: (field, value, _entity, _props) => <DocumentList values={value}/>,
            displayConditions: (entity, entity2, key, context) =>
                (userHasOwnershipRights(User.getId(), entity) ||
                    userHasDeveloperRights(User, entity) ||
                    userHasImplementerRights(User, entity) ||
                    userIsVal(User, entity) ||
                    userHasBusinessSponsorRights(User.getId(), entity) ||
                    userHasRoleMRM() ||
                    userHasRoleIG()) &&
                enableByStatus(entity, 'documentsEntities', context),
        },
        validationReportsEntities: {
            title: 'Validation reports',
            display: (field, value, entity, _props) => (
                <DocumentManager
                    values={value}
                    entity={entity}
                    entityResource={'models'}
                    allowedAction={(entity, document, action) => {
                        if (action === DOCUMENT_ACTION_SHOW || action === DOCUMENT_ACTION_LIST) {
                            if (
                                userHasOwnershipRights(User.getId(), entity) ||
                                userHasDeveloperRights(User, entity) ||
                                userHasImplementerRights(User, entity) ||
                                userIsVal(User, entity) ||
                                userHasBusinessSponsorRights(User.getId(), entity) ||
                                userHasRoleMRM() ||
                                userHasRoleIG()
                            ) {
                                return true;
                            }
                        } else if (action === DOCUMENT_ACTION_DELETE) {
                            if (document?.author === `/api/users/${User.getId()}` || userIsVal(User, entity) || userHasRoleMRM()) {
                                return true;
                            }
                        }
                        return false;
                    }}
                />
            ),
            displayList: (field, value, _entity, _props) => <DocumentList values={value}/>,
        },
        modelingDocumentsEntities: {
            title: 'Modeling documents',
            display: (field, value, entity, _props) => (
                <DocumentManager
                    values={value}
                    entity={entity}
                    entityResource={'models'}
                    allowedAction={(entity, document, action) => {
                        if (action === DOCUMENT_ACTION_SHOW || action === DOCUMENT_ACTION_LIST) {
                            if (
                                userHasOwnershipRights(User.getId(), entity) ||
                                userHasDeveloperRights(User, entity) ||
                                userHasImplementerRights(User, entity) ||
                                userIsVal(User, entity) ||
                                userHasBusinessSponsorRights(User.getId(), entity) ||
                                userHasRoleMRM() ||
                                userHasRoleIG()
                            ) {
                                return true;
                            }
                        }
                        return false;
                    }}
                />
            ),
            displayList: (field, value, _entity, _props) => <DocumentList values={value}/>,
        },
        verifiedByMRM: {
            title: 'Verified By MRM',
            type: 'bool',
            bulk: true,
            required: (entity) => requiredByStatus(entity, 'verifiedByMRM'),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'verifiedByMRM', context),
        },
        policyExceptionStartDate: {
            title: 'Policy Exception start date',
            type: 'date',
            params: {}, //Ne pas supprimer, sert au minDate dans le onUpdate
            helperText: 'Date of the exception granted by MRM to a model that did not go through the proper validation and approval process, in accordance with the Group procedure',
            required: (entity, fieldId) => requiredByStatus(entity, fieldId),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'policyExceptionStartDate', context),
            bulk: true,
        },
        policyExceptionEndDate: {
            title: 'Policy Exception end date',
            type: 'date',
            params: {}, //Ne pas supprimer, sert au minDate dans le onUpdate
            helperText: 'Expiry date of the exception',
            required: (entity, fieldId) => requiredByStatus(entity, fieldId),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'policyExceptionEndDate', context),
            bulk: true,
        },
        policyExceptionType: {
            title: 'Policy Exception type',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MODEL_POLICY_EXCEPTION_TYPE,
                multi: false,
            },
            helperText:
                'Type of the exception granted by MRM: model use, retirement etc.',
            bulk: true,
            issueButton: false,
            required: (entity) => requiredByStatus(entity, 'policyExceptionType'),
            displayConditions: (entity, entity2, key, context) =>
                enableByStatus(entity, 'policyExceptionType', context),
            highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
        },
        policyExceptionJustification: {
            title: 'Policy Exception justification',
            type: 'textarea',
            helperText: 'MRM Justification for the exception granted',
            required: (entity, fieldId) => requiredByStatus(entity, fieldId),
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'policyExceptionJustification', context),
            bulk: true,
        },
        ongoingMonitoringExceptionStartDate: {
            title: 'Ongoing Monitoring Exception start date',
            type: 'date',
            params: {}, //Ne pas supprimer, sert au minDate dans le onUpdate
            helperText: 'Date of the exception granted by MRM to a model that does not go through the proper Ongoing Monitoring Process',
            required: (entity, fieldId) => requiredByStatus(entity, fieldId),
            displayConditions: (entity, entity2, key, context, fieldId) => enableByStatus(entity, fieldId, context),
            bulk: true,
        },
        ongoingMonitoringExceptionEndDate: {
            title: 'Ongoing Monitoring Exception end date',
            type: 'date',
            params: {}, //Ne pas supprimer, sert au minDate dans le onUpdate
            helperText: 'Expiry date of the exception',
            required: (entity, fieldId) => requiredByStatus(entity, fieldId),
            displayConditions: (entity, entity2, key, context, fieldId) => enableByStatus(entity, fieldId, context),
            bulk: true,
        },
        ongoingMonitoringExceptionJustification: {
            title: 'Ongoing Monitoring Exception justification',
            type: 'textarea',
            helperText: 'MRM justification for the exception granted',
            required: (entity, fieldId) => requiredByStatus(entity, fieldId),
            displayConditions: (entity, entity2, key, context, fieldId) => enableByStatus(entity, fieldId, context),
            bulk: true,
        },
        unauthorizedModelInProduction: {
            title: 'Unauthorized model in production',
            type: 'bool',
            helperText: "Model running in production without going through the proper MRM process (validation or policy exception)",
            displayConditions: (entity, entity2, key, context) => (
                    userHasStakeHolderRights(User, entity) ||
                    userIsVal(User, entity) ||
                    userHasRoleMRM() ||
                    userHasRoleIG()) &&
                enableByStatus(entity, 'unauthorizedModelInProduction', context),
            edit: () => null,
        },
        nextScheduledValidationDateInThePast: {
            title: 'Next scheduled validation date in the past',
            type: 'bool',
            params: {},
            displayConditions: (entity, entity2, key, context) => (
                    userHasStakeHolderRights(User, entity) ||
                    userIsVal(User, entity) ||
                    userHasRoleMRM() ||
                    userHasRoleIG())
                && enableByStatus(entity, 'nextScheduledValidationDateInThePast', context),
            edit: () => null,
            displayListIfNotInLayout: true,
        },
        process: {
            title: 'Process',
            type: 'mapped',
            params: {
                mapping: {
                    1: 'Declaration',
                    2: 'Non-Model',
                    3: 'Retirement',
                    4: 'Tiering update',
                },
            },
        },
        ...retirementFields,
        ...specificFrameworkConversionFields,
        specificFramework: {
            title: 'Specific framework type',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_SPECIFIC_FRAMEWORK,
                multi: false,
            },
            helperText: "Type of specific framework: non standard transaction, used only once, dormant payoff, no sensitivity for 24 months",
            bulkable: () => userHasRoleMRM(),
            required: false,
            displayList: (field, value, entity, props, resource) => {
                if (resource.instanceId !== 'specific_frameworks') {
                    return null;
                }
                return ParameterProvider.getDisplayList(field, value, entity, props, resource)
            },
            displayConditions: (entity, _e, _k, context) => entity.specificFramework || (context === 'edit' && userHasRoleMRM()),
            editConditions: () => userHasRoleMRM(),
        },
        specificFrameworkConversionDate: {
            title: 'Conversion to specific framework date',
            type: 'date',
            edit: () => null,
            displayList: (field, value, entity, props, resource) => {
                if (resource.instanceId !== 'specific_frameworks') {
                    return null;
                }
                return DateProvider.getDisplayList(field, value, entity, props, resource)
            },
            displayConditions: (entity) => entity.specificFramework,
        },
        mrmCategory: {
            title: 'MRM Category',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MRM_CATEGORY,
                multi: false,
            },
            helperText: 'MRM categorization - 1st level',
            bulk: true,
            required: requiredByStatus,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'mrmCategory', context),
            editConditions: (_f, _v, entity) => userHasRoleMRM() || userHasOwnershipRights(User.getId(), entity),
        },
        mrmSubcategory: {
            title: 'MRM Subcategory',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_MRM_SUBCATEGORY,
                multi: false,
            },
            helperText: 'MRM categorization - 2d level',
            bulk: true,
            required: requiredByStatus,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'mrmSubcategory', context),
            editConditions: (_f, _v, entity) => userHasRoleMRM() || userHasOwnershipRights(User.getId(), entity),
        },
        isValuationRisk: {
            title: 'Valuation Risk',
            type: 'bool',
            helperText: 'Valuation Risk flag',
            canonicalFieldName: 'valuationRisk',
            bulk: true,
            displayConditions: (entity, entity2, key, context) => {
                const hasRightSubcategory =
                    getParamByIri(entity.riskCategory).systemId === 'RISK_CATEGORY_MARKET_RISKS'
                    && [
                        'MRM_SUBCATEGORY_FRONTOFFICE_XVA',
                        'MRM_SUBCATEGORY_FRONTOFFICE_MARKETFAIRVA',
                        'MRM_SUBCATEGORY_FRONTOFFICE_MODELFAIRVA',
                        'MRM_SUBCATEGORY_FRONTOFFICE_IPV',
                        'MRM_SUBCATEGORY_MARKET_RISK_PVA',
                    ].includes(
                        getParamByIri(entity.mrmSubcategory).systemId
                    );
                const enabled = enableByStatus(entity, 'isValuationRisk', context);
                return enabled && hasRightSubcategory;
            },
            editConditions: () => userHasRoleMRM(),
        },
        isIcaapEconomicalCapital: {
            title: 'ICAAP/ Economical Capital and Normatif',
            type: 'bool',
            helperText: "Models that contribute to both economic ICAAP and normative ICAAP",
            canonicalFieldName: 'icaapEconomicalCapital',
            bulk: true,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'isIcaapEconomicalCapital', context),
            editConditions: () => userHasRoleMRM(),
        },
        isAi: {
            title: 'AI',
            type: 'bool',
            helperText: 'Artificial intelligence flag',
            canonicalFieldName: 'ai',
            bulk: true,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'isAi', context),
            editConditions: () => userHasRoleMRM(),
        },
        isEsg: {
            title: 'ESG',
            type: 'bool',
            helperText: 'Environmental, Social, and Governance flag',
            canonicalFieldName: 'esg',
            bulk: true,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'isEsg', context),
            editConditions: () => userHasRoleMRM(),
        },
        isLegacy: {
            title: 'Legacy',
            type: 'bool',
            helperText: 'Legacy model',
            canonicalFieldName: 'legacy',
            bulk: true,
            displayConditions: (entity, entity2, key, context) => enableByStatus(entity, 'isLegacy', context),
            editConditions: (_f, _v, entity) => userHasRoleMRM() || userHasOwnershipRights(User.getId(), entity),
        },
    });
const MODEL_LAYOUT = {
    tabs: {
        'ID Card': {
            rows: [
                {
                    panels: {
                        Identity: {
                            cols: 6,
                            fields: [
                                'name',
                                'description',
                                'modelType',
                                'specificFramework',
                                'output',
                                'modelStatus',
                                'nonModelStatus',
                                'expectedStatus',
                            ],
                        },
                        IDs: {
                            cols: 6,
                            fields: ['modelID', 'functionalID', 'initialID', 'systemID', 'ECBID', 'ECBAnnexID'],
                        },
                    },
                },
                {
                    panels: {
                        Taxonomy: {
                            cols: 6,
                            fields: ['riskCategory', 'microRiskCategory', 'microRiskSubCategory'],
                        },
                        'Scope of application': {
                            cols: 6,
                            fields: ['applicationDomainLevel1', 'applicationDomainLevel2', 'applicationDomainLevel3'],
                        },
                    },
                },
                {
                    panels: {
                        Ownership: {
                            cols: 6,
                            fields: [
                                'modelOwner',
                                'modelOwnerTeams',
                                'modelOwnerPosition',
                                'modelOwnerEstablishment',
                                'modelOwnerDelegation',
                            ],
                        },
                        Stakeholders: {
                            cols: 6,
                            fields: [
                                'modelDeveloperTeam',
                                'modelValidatorTeams',
                                'mrmTeams',
                                'mrmTeamsOverride',
                                'businessSponsor',
                            ],
                        },
                    },
                },
                {
                    panels: {
                        'MRM categories': {
                            cols: 6,
                            fields: [
                                'mrmCategory',
                                'mrmSubcategory',
                                'isValuationRisk',
                                'isIcaapEconomicalCapital',
                                'isAi',
                                'isEsg',
                                'operationalRiskFlag'
                            ],
                        },
                        'Core model': {
                            cols: 6,
                            fields: ['coreModel', 'coreModelParent'],
                        },
                    },
                },
            ],
        },
        Properties: {
            rows: [
                {
                    panels: {
                        'Champion / Challenger': {
                            cols: 6,
                            fields: ['championOrChallenger', 'championModels'],
                        },
                        'Development and sources': {
                            cols: 6,
                            fields: ['inHouseOrVendor', 'vendor', 'dataSource'],
                        },
                    },
                },
                {
                    panels: {
                        'Upstream / Downstream': {
                            cols: 6,
                            fields: [
                                'upStreamNotAvailable',
                                'upstreamModels',
                                'downStreamNotAvailable',
                                'downstreamModels',
                            ],
                        },
                    },
                },
                {
                    panels: {
                        'Model Nature': {
                            cols: 6,
                            fields: ['modelNatureStandard', 'modelNatureDetailedString', 'modelObservability'],
                        },
                        'Family tree': {
                            cols: 6,
                            fields: [
                                'backgroundModelRelations',
                                'foregroundModelRelations',
                                'isLegacy',
                            ],
                        },
                    },
                },
            ],
        },
        Uses: {
            rows: [
                {
                    panels: {
                        'Model uses': {
                            cols: 12,
                            fields: ['modelUsesEntities'],
                        },
                    },
                },
            ],
        },
        Tiering: {
            rows: [
                {
                    panels: {
                        Tiering: {
                            cols: 12,
                            fields: ['tieringReferenceModels', 'tieringReferenceForModels', 'tieringsEntities'],
                        },
                    },
                },
            ],
        },
        Implementations: {
            rows: [
                {
                    panels: {
                        'Model Implementation': {
                            cols: 12,
                            fields: ['firstImplementationDate', 'productionRun', 'previsionalDate', 'implementationsEntities'],
                        },
                    },
                },
            ],
        },
        'Mitigation actions': {
            rows: [
                {
                    panels: {
                        'Mitigation actions': {
                            cols: 12,
                            fields: ['mitigationActions', 'coveringMitigationActionsEntities'],
                        },
                        'Mitigation models': {
                            cols: 12,
                            fields: ['reservingMitigationActionsEntities'],
                        },
                    },
                },
            ],
        },
        Certifications: {
            displayConditions: (entity) =>
                ['MODEL_STATUS_ACTIVE', 'MODEL_STATUS_DELETED', 'MODEL_STATUS_RETIRED']
                    .map(ParameterStore)
                    .includes(entity.modelStatus) &&
                (userHasStakeHolderRights(User, entity) || userIsVal(User, entity) || userHasRoleMRM()),
            rows: [
                {
                    panels: {
                        'Certification information': {
                            cols: 12,
                            fields: [
                                'lastCertificationDate',
                                'certifierName',
                                'certifierTeam',
                                'certifierPosition',
                                'lastCertificationResult',
                            ],
                        },
                        Certifications: {
                            cols: 12,
                            fields: ['certificationsEntities'],
                        },
                    },
                },
                {
                    panels: {
                        'Certifications Troubles': {
                            cols: 12,
                            fields: ['certificationsIssuesEntities'],
                        },
                    },
                },
            ],
        },
        'Model life': {
            displayConditions: (entity) => !userHasNoRole(User, entity),
            //env: 'dev',
            rows: [
                {
                    panels: {
                        'Past reviews': {
                            cols: 6,
                            fields: [
                                'lastValidationType',
                                'lastFullValidationDate',
                                'lastPeriodicValidationDate',
                                'lastMinorEvolutionReviewDate',
                                'validationStatus',
                                'validationStatusRationale',
                            ],
                        },
                    },
                },
                {
                    panels: {
                        'Forthcoming reviews': {
                            cols: 6,
                            fields: [
                                'nextScheduledValidationDate',
                                'nextValidationDateVerificationComment',
                                'nextScheduledValidationType',
                                'nextFullValidationDateProcedure',
                                'nextPeriodicValidationDateProcedure',
                            ],
                        },
                    },
                },
                {
                    panels: {
                        Validation: {
                            cols: 6,
                            fields: [
                                'validatorsLocation',
                                'policyExceptionStartDate',
                                'policyExceptionEndDate',
                                'policyExceptionType',
                                'policyExceptionJustification',
                                'unauthorizedModelInProduction',
                                'ongoingMonitoringExceptionStartDate',
                                'ongoingMonitoringExceptionEndDate',
                                'ongoingMonitoringExceptionJustification',
                            ],
                        },
                    },
                },
                {
                    panels: {
                        "Validation plan": {
                            cols: 6,
                            fields: [
                                'validationPlanFullValidationCurrentYearInitial',
                                'validationPlanPeriodicReviewCurrentYearInitial',
                                'validationPlanOtherReviewCurrentYearInitial',
                                'validationPlanFullValidationCurrentYear',
                                'validationPlanPeriodicReviewCurrentYear',
                                'validationPlanOtherReviewCurrentYear',
                                'entryExitValidationPlanFullValidation',
                                'entryExitValidationPlanPeriodicReview',
                                'entryExitValidationPlanOtherReview',
                                'validationPlanFullValidationStatus',
                                'validationPlanPeriodicReviewStatus',
                                'validationPlanOtherReviewStatus',

                            ],
                        },
                    },
                },
                {
                    panels: {
                        MRA: {
                            cols: 6,
                            fields: [
                                // 'inherentModelRiskScore',
                                // 'modelRiskScore',
                                // 'residualModelRiskScore',
                                'averageModelRiskScore',
                                'modelRiskAssessmentResults',
                                'criticalScoreDimension',
                            ],
                        },
                    },
                },
            ],
        },
        MRA: {
            displayConditions: (entity) => !userHasNoRole(User, entity),
            //env: 'dev',
            rows: [
                {
                    panels: {
                        MRAs: {
                            cols: 12,
                            fields: ['mrasEntities'],
                        },
                    },
                },
            ],
        },
        Reviews: {
            displayConditions: (entity) => !userHasNoRole(User, entity),
            //env: 'dev',
            rows: [
                {
                    panels: {
                        Reviews: {
                            cols: 12,
                            fields: ['reviewsEntities'],
                        },
                    },
                },
            ],
        },
        Notices: {
            displayConditions: (entity) =>
                ['MODEL_STATUS_ACTIVE', 'MODEL_STATUS_DELETED', 'MODEL_STATUS_RETIRED']
                    .map(ParameterStore)
                    .includes(entity.modelStatus) && !userHasNoRole(User, entity),
            //env: 'dev',
            rows: [
                {
                    panels: {
                        Findings: {
                            cols: 6,
                            fields: ['findingsEntities'],
                        },
                        Notices: {
                            cols: 6,
                            fields: ['numberOfOpenNotices', 'numberOfCriticalNotices', 'numberOfV2Notices', 'noticesEntities'],
                        },
                        'IGG/BCE Recommendations': {
                            cols: 12,
                            fields: ['iggBceRecommendationEntities'],
                        },
                    },
                },
            ],
        },
        Documents: {
            rows: [
                {
                    panels: {
                        Documents: {
                            cols: 12,
                            fields: ['documentsEntities'],
                        },
                        'Validation reports': {
                            cols: 12,
                            fields: ['validationReportsEntities'],
                        },
                        'Modeling documents': {
                            cols: 12,
                            fields: ['modelingDocumentsEntities'],
                        },
                    },
                },
            ],
        },
        Retirement: {
            displayConditions: (entity) => !!entity.retirementStatus,
            rows: [
                {
                    panels: {
                        Retirement: {
                            cols: 12,
                            fields: [
                                'retirementRequestDate',
                                'retirementExpectedDate',
                                'retirementStatus',
                                'modelStatusRetirementDate',
                                'retirementCommittee',
                                'retirementJustificationMrm',
                                'retirementDocumentsEntities',
                                'retirementJustificationLod1',
                            ],
                        },
                    },
                },
            ],
        },
        'Audit trail': {
            displayConditions: (entity) => {
                return (
                    userHasStakeHolderRights(User, entity) ||
                    userIsVal(User, entity) ||
                    userHasRoleMRM() ||
                    userHasSpecificRole(Role.IG)
                );
            },
            rows: [
                {
                    panels: {
                        'Inventory removal': {
                            cols: 12,
                            fields: ['deletionComment', 'modelStatusDeletionDate', 'nonModelComment', 'nonModelConversionDate', 'specificFrameworkJustification', 'specificFrameworkConversionDate', 'convertSpecificFrameworkDocumentsEntities'],
                        },
                    },
                },
                {
                    panels: {
                        Versions: {
                            cols: 12,
                            fields: ['versionsSummary', 'changeLogComment'],
                        },
                    },
                },
                {
                    panels: {
                        Declaration: {
                            cols: 6,
                            fields: ['treeDeterminationAnswers'],
                        },
                        Creation: {
                            cols: 6,
                            fields: ['declarationDate', 'declarer', 'insertionFromImport', 'clonedFrom'],
                        },
                    },
                },
            ],
        },
    },
};

const FIELDS_SAVE_WHITE_LIST = [
    'mitigationActions',
    'expectedStatus',
    'groupEntities',
    'coveringMitigationActions',
    'implementations',
    'tierings',
    'modelUses',
    'hasModelUses',
    'hasTieringWithoutMRMVerification',
];

const MODEL_EDIT = (resource) => ({
    fields: MODEL_EDIT_FIELDS_DEFAULT,
    /** Ne pas oublier les champs nécessaires à la validation {@see validationByStatus} */
    fieldsSaveWhitelist: FIELDS_SAVE_WHITE_LIST,
    onInit: ({entity, _resource, _context}) => {
        if (
            Array.isArray(entity.coveringMitigationActionsEntities) &&
            entity.coveringMitigationActionsEntities.length > 0
        ) {
            entity.mitigationActions = false;
        }
    },
    onUpdate: (fieldId, oldValue, newValue, entity, resource, resourceEditComponent) => {
        if (fieldId === 'modelOwner' || fieldId === 'mrmTeamsOverride') {
            if (entity && entity.id) {
                let obj = {
                    modelOwner: entity.modelOwner,
                    mrmTeamsOverride: entity.mrmTeamsOverride,
                };
                Http.post(`models/${entity.id}/mrm-team-group-entity`, obj).then((response) => {
                    entity.mrmTeams = response.mrmTeams;
                    entity.modelOwnerTeams = response.modelOwnerTeams;
                    entity.groupEntities = response.groupEntities;
                    resourceEditComponent.setState({entity});
                });
            }
        }
        if (fieldId === 'policyExceptionStartDate') {
            resource.fields.policyExceptionEndDate.params.minDate = newValue;
            delete resource.fields.policyExceptionEndDate.params.maxDate;
            if (!newValue) {
                delete resource.fields.policyExceptionEndDate.params.minDate;
            }
        } else if (fieldId === 'policyExceptionEndDate') {
            resource.fields.policyExceptionStartDate.params.maxDate = newValue;
            delete resource.fields.policyExceptionStartDate.params.minDate;
            if (!newValue) {
                delete resource.fields.policyExceptionStartDate.params.maxDate;
            }
        }
        if (fieldId === 'ongoingMonitoringExceptionStartDate') {
            resource.fields.ongoingMonitoringExceptionEndDate.params.minDate = newValue;
            delete resource.fields.ongoingMonitoringExceptionEndDate.params.maxDate;
            if (!newValue) {
                delete resource.fields.ongoingMonitoringExceptionEndDate.params.minDate;
            }
        } else if (fieldId === 'ongoingMonitoringExceptionEndDate') {
            resource.fields.ongoingMonitoringExceptionStartDate.params.maxDate = newValue;
            delete resource.fields.ongoingMonitoringExceptionStartDate.params.minDate;
            if (!newValue) {
                delete resource.fields.ongoingMonitoringExceptionStartDate.params.maxDate;
            }
        }

        if (fieldId === 'productionRun' && oldValue === false && newValue === true && entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE')) {
            if (isModelUnauthorizedInProduction(entity)) {
                Modal.open({
                    title: "Warning: unauthorized model in production",
                    content: <UnauthorizedModelInProductionModal models={[entity]}/>,
                    size: 'medium',
                })
            }
        }

        if (fieldId === 'riskCategory') {
            // On vide les dependentFields
            /** @todo on pourrait imaginer une prop dans ParameterProvider pour forcer le vidage de tous les dependentFields */
            entity.microRiskCategory = null;
            entity.microRiskSubCategory = null;
            entity.mrmCategory = null;
            entity.mrmSubcategory = null;
            resourceEditComponent.setState({entity});
        }
    },
    itemAccessCondition: (entity) => {
        // Conditions indispensables :
        const isEditable = !!entity.activeVersion || entity.modelStatus === ParameterStore('MODEL_STATUS_RETIRED');

        const isGranted = async () => {
            let editGranted = await Http.get('models/' + entity.id + '/is_granted/MODEL_EDIT', {cache: true});
            return (
                isEditable &&
                editGranted['hydra:member']['is_granted']
            );
        };
        let res = entity && entity.id && isEditable ? (userHasMRMRights(User, entity) || userHasOwnershipRights(User.getId(), entity) ? true : isGranted()) : false;
        return res;
    },
    routeAccessControl: (_entity) => true,
    postSaveRedirect: postSaveRedirectToCertification,
    additionalActionButtons: (entity, resource, resourceEditComponent, queryParams) => {
        let additionalActionButtons = [];

        if (
            !(
                typeof queryParams !== 'object' ||
                queryParams.get('certification-id') === undefined ||
                queryParams.get('certification-id') === '' ||
                queryParams.get('certification-id') === null
            )
        ) {
            additionalActionButtons.push({
                link: '/resource/my_model_certification_campaigns/' + queryParams.get('certification-id') + '/detail',
                tooltip: 'Go back to certification',
                icon: 'chevron-left',
            });
        }


        if (userHasRoleMRM() && entity.nonModelStatus === ParameterStore('NON_MODEL_STATUS_NON_MODEL_AWAITING')) {
            additionalActionButtons.push({
                onClick: (button) =>
                    Modal.open({
                        title: 'Convert to model',
                        content: (
                            <ConfirmModal
                                message={`Do you confirm that ${button.entity.functionalID} is indeed a model ? It will be converted into a draft model.`}
                                button={{
                                    cancel: 'Cancel',
                                    confirm: 'Convert to model',
                                }}
                                callback={() => {
                                    convertToModel(button.entity, button.resource)
                                }}
                            />
                        ),
                        modalStyle: {width: 450},
                    }),
                tooltip: 'id',
                icon: 'database',
                className: 'agree',
            });
            additionalActionButtons.push({
                onClick: (button) =>
                    Modal.open({
                        title: 'Confirm as non-model',
                        content: (
                            <ConfirmModal
                                message={`Do you confirm that ${button.entity.functionalID} is a non-model ? It will be saved in the Non-models list.`}
                                button={{
                                    cancel: 'Cancel',
                                    confirm: 'Confirm as non-model',
                                }}
                                callback={() => {
                                    confirmAsNonModel(button.entity, button.resource)
                                }}
                            />
                        ),
                        modalStyle: {width: 450},
                    }),
                tooltip: 'Confirm as non-model',
                icon: 'times',
                className: 'disagree',
            });
        }

        if (
            !userHasRoleMRM() &&
            (userHasOwnershipRights(User.getId(), entity) ||
                userHasDeclarerRights(User.getId(), entity) ||
                userHasDeveloperRights(User, entity)) &&
            !entity.expectedStatus &&
            entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT')
        ) {
            if (Environment.getUriParameter('cud') === 'true') {
                Navigation.router.history.push({
                    pathname: Navigation.router.pathname,
                    search: '', //Reset get param
                });
                submitToMRM(
                    resource,
                    entity,
                    entity.modelStatus,
                    ParameterStore('MODEL_STATUS_UNDER_DECLARATION'),
                    resourceEditComponent
                );
            }
            additionalActionButtons.push({
                onClick: (button) =>
                    submitToMRM(
                        resource,
                        button.entity,
                        entity.modelStatus,
                        ParameterStore('MODEL_STATUS_UNDER_DECLARATION'),
                        resourceEditComponent
                    ),
                tooltip: 'Submit to MRM',
                icon: 'arrow-up',
                className: 'agree',
            });
        }
        if (
            userHasRoleSTD() &&
            !userHasRoleMRM() &&
            (userHasOwnershipRights(User.getId(), entity) ||
                userHasDeclarerRights(User.getId(), entity) ||
                userHasDeveloperRights(User, entity)) &&
            entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') &&
            !entity.expectedStatus
        ) {
            let tooltip = 'Submit to MRM';
            let nextStatus = ParameterStore('MODEL_STATUS_ACTIVE');
            if (Environment.getUriParameter('cim') === 'true') {
                Navigation.router.history.push({
                    pathname: Navigation.router.pathname,
                    search: '', //Reset get param
                });
                submitToMRM(resource, entity, entity.modelStatus, nextStatus, resourceEditComponent);
            }
            additionalActionButtons.push({
                onClick: (button) =>
                    submitToMRM(resource, button.entity, entity.modelStatus, nextStatus, resourceEditComponent),
                tooltip: tooltip,
                icon: 'arrow-up',
                className: 'agree',
            });
        }

        return additionalActionButtons;
    },
    header: header,
});
const MODEL_DETAIL = (resource) => ({
    fields: MODEL_DETAIL_FIELDS_DEFAULT,
    onInit: ({entity, _resource, _context}) => {
        if (entity.coveringMitigationActionsEntities && entity.coveringMitigationActionsEntities.length > 0) {
            entity.mitigationActions = false;
        }
    },
    additionalActionButtons: (entity, resource, resourceDetailComponent, queryParams) => {
        if (!entity.activeVersion) return;

        let additionalActionButtons = [];

        if (
            !(
                typeof queryParams !== 'object' ||
                queryParams.get('certification-id') === undefined ||
                queryParams.get('certification-id') === '' ||
                queryParams.get('certification-id') === null
            )
        ) {
            additionalActionButtons.push({
                link: '/resource/my_model_certification_campaigns/' + queryParams.get('certification-id') + '/detail',
                tooltip: 'Go back to certification',
                icon: 'chevron-left',
            });
        }

        if (userHasRoleMRM() && entity.nonModelStatus === ParameterStore('NON_MODEL_STATUS_NON_MODEL_AWAITING')) {
            additionalActionButtons.push({
                onClick: (button) =>
                    Modal.open({
                        title: 'Convert to model',
                        content: (
                            <ConfirmModal
                                message={`Do you confirm that ${button.entity.functionalID} is indeed a model ? It will be converted into a draft model.`}
                                button={{
                                    cancel: 'Cancel',
                                    confirm: 'Convert to model',
                                }}
                                callback={() => {
                                    convertToModel(button.entity, button.resource)
                                }}
                            />
                        ),
                        modalStyle: {width: 450},
                    }),
                tooltip: 'Convert to model',
                icon: 'database',
                className: 'agree',
            });
            additionalActionButtons.push({
                onClick: (button) =>
                    Modal.open({
                        title: 'Confirm as non-model',
                        content: (
                            <ConfirmModal
                                message={`Do you confirm that ${button.entity.functionalID} is a non-model ? It will be saved in the Non-models list.`}
                                button={{
                                    cancel: 'Cancel',
                                    confirm: 'Confirm as non-model',
                                }}
                                callback={() => {
                                    confirmAsNonModel(button.entity, button.resource)
                                }}
                            />
                        ),
                        modalStyle: {width: 450},
                    }),
                tooltip: 'Confirm as non-model',
                icon: 'times',
                className: 'disagree',
            });
        }

        /** Boutons pour le processus de retrait du Model */
        let displayButtons = {};
        let tooltips = {
            default: 'Removal',
            [ParameterStore('MODEL_RETIREMENT_STATUS_PROPOSED_LOD1')]: 'Confirm/discard this retirement request',
            [ParameterStore('MODEL_RETIREMENT_STATUS_PROPOSED_MRM')]: 'Confirm/discard this retirement request',
            [ParameterStore('MODEL_RETIREMENT_STATUS_AWAITING_COMMITTEE')]: 'Indicate if the committee confirmed or declined this model retirement',
        };
        if (
            (
                entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE') ||
                !entity.modelStatus
            ) &&
            ((!entity.retirementStatus && entity.modelStatus !== ParameterStore('MODEL_STATUS_RETIRED')) ||
                entity.retirementStatus === ParameterStore('MODEL_RETIREMENT_STATUS_PROPOSED_LOD1') ||
                entity.retirementStatus === ParameterStore('MODEL_RETIREMENT_STATUS_AWAITING_COMMITTEE') ||
                entity.specificFramework)
        ) {
            if ((userHasMRMRights(User, entity) || userHasRoleADMIN()) && (!entity.retirementStatus || entity.specificFramework)) {
                if (entity.nonModelStatus !== ParameterStore('NON_MODEL_STATUS_NON_MODEL_AWAITING')) {
                    displayButtons.retire = {
                        title: 'Propose the retirement to LoD 1',
                    };
                }
                displayButtons.delete = {};
                if (!entity.nonModelStatus) {
                    displayButtons.reject = {
                        title: 'Conversion to non-model',
                    };
                }
            } else if ((userHasMRMRights(User, entity) || userHasRoleADMIN())) {
                displayButtons.retire = {
                    title: tooltips[entity.retirementStatus || 'default'],
                    label: tooltips[entity.retirementStatus || 'default'],
                };
                displayButtons.reject = {
                    title: 'Conversion to non-model',
                };
                displayButtons.delete = {};
            }
        }

        if (
            (userHasMRMRights(User, entity) || userHasRoleADMIN()) &&
            entity.nonModel === false &&
            !entity.retirementStatus &&
            entity.modelStatus !== ParameterStore('MODEL_STATUS_ACTIVE')
        ) {
            displayButtons.delete = {};
            displayButtons.reject = {
                title: 'Conversion to non-model',
            };
        }
        if (
            entity.declarer === '/api/users/' + User.getId() &&
            !entity.retirementStatus &&
            entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT')
        ) {
            displayButtons.delete = {};
        }

        if (
            (userHasMRMRights(User, entity) || userHasRoleADMIN())
            && !entity.retirementStatus
            && !entity.specificFramework
        ) {
            displayButtons.convert = {
                title: 'Conversion to specific framework',
            };
        }

        if (
            (userHasMRMRights(User, entity) || userHasRoleADMIN()) &&
            (
                entity.nonModel === true
                || entity.specificFramework
            )
        ) {
            displayButtons.convertToModel = {
                title: 'Conversion to model',
            };
        }

        if (Object.keys(displayButtons).length > 0) {
            additionalActionButtons.push({
                onClick: (button) =>
                    Modal.open({
                        title: 'Removal',
                        content: (
                            <ModelRemovalModal
                                entityType="model"
                                entity={button.entity}
                                resource={resource}
                                resourceDetailComponent={resourceDetailComponent}
                                displayButtons={{
                                    ...displayButtons,
                                }}
                            />
                        ),
                        modalStyle: {width: '500px'},
                    }),
                tooltip: tooltips[entity.retirementStatus || 'default'],
                icon: 'trash-restore-alt',
                className: 'retire trash',
            });
        }

        if (
            entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE') &&
            entity.nonModelStatus !== ParameterStore('NON_MODEL_STATUS_NON_MODEL_AWAITING') &&
            //!userHasRoleMRM() &&
            userHasOwnershipRights(User.getId(), entity) &&
            (!entity.retirementStatus ||
                entity.retirementStatus === ParameterStore('MODEL_RETIREMENT_STATUS_PROPOSED_MRM'))
        ) {
            let tooltips = {
                default: 'Propose this model retirement',
                [ParameterStore(
                    'MODEL_RETIREMENT_STATUS_PROPOSED_MRM'
                )]: 'Confirm/discard this model retirement request',
            };
            additionalActionButtons.push({
                onClick: (button) =>
                    Modal.open({
                        title: 'Retiring model',
                        content: (
                            <ModelRetirementModal
                                entity={button.entity}
                                resource={resource}
                                resourceDetailComponent={resourceDetailComponent}
                            />
                        ),
                    }),
                tooltip: tooltips[entity.retirementStatus || 'default'],
                icon: 'trash-restore-alt',
                className: 'retire trash',
            });
        }

        /** Bouton pour le clonage */

        if (
            entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE') &&
            //(userHasRoleMRM() || userHasOwnershipRights(User.getId(), entity) || userHasDeveloperRights(User, entity) || userIsVal(User, entity))
            (userHasRoleMRM() || userHasDeveloperRights(User, entity) || userIsVal(User, entity))//Temporary deactive owner right
        ) {
            additionalActionButtons.push({
                onClick: (button) =>
                    Modal.open({
                        title: 'Duplication',
                        content: <CloneModelModal entity={button.entity} resource={resource}/>,
                    }),
                tooltip: 'Duplicate',
                icon: 'clone',
                className: 'action',
            });
        }

        if (
            !userHasRoleMRM() &&
            (userHasOwnershipRights(User.getId(), entity) ||
                userHasDeclarerRights(User.getId(), entity) ||
                userHasDeveloperRights(User, entity)) &&
            !entity.expectedStatus &&
            entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT')
        ) {
            //
            additionalActionButtons.push({
                link: '/resource/' + resource.instanceId + '/' + entity.id + '/edit?cud=true', //Redirect to Edit view and trigger the same action button
                tooltip: 'Submit to MRM',
                icon: 'arrow-up',
                className: 'agree',
            });
        }
        if (
            userHasRoleSTD() &&
            !userHasRoleMRM() &&
            (userHasOwnershipRights(User.getId(), entity) ||
                userHasDeclarerRights(User.getId(), entity) ||
                userHasDeveloperRights(User, entity)) &&
            entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') &&
            !entity.expectedStatus
        ) {
            additionalActionButtons.push({
                link: '/resource/' + resource.instanceId + '/' + entity.id + '/edit?cim=true', //Redirect to Edit view and trigger the same action button
                tooltip: 'Submit to MRM',
                icon: 'arrow-up',
                className: 'agree',
            });
        }

        if (userHasRoleMRM()) {
            if (entity.expectedStatus && entity.nonModel === false) {
                let expectedStatus = entity.expectedStatus;
                expectedStatus = expectedStatus.split('/');
                let expectedStatusId = expectedStatus[expectedStatus.length - 1];
                let validateAsStatus = APIResourceStore.resources.parameters.getObservableItem(expectedStatusId);
                additionalActionButtons.push({
                    onClick: (_button) => validateModel(resource, entity, resourceDetailComponent),
                    tooltip: `Validate as ${validateAsStatus.label}`,
                    icon: 'check',
                    className: 'agree',
                });
            }
        }

        return additionalActionButtons;
    },
    header: header,
});

export const ApiResourceDefaultParams = {
    id: 'models',
    name: 'Models',
    icon: 'brain',
    fieldForTitle: 'toString',
    breadcrumbName: 'Model',
    fieldsAclLocation: 'annotations/model',
    keepQueryParams: () => {
        return ['certification-id'];
    }
};

export default class ModelAdmin {
    constructor() {
        this.configure();
    }

    async configure() {

        // Models entities

        const userHasRoleMRM = userHasRole('ROLE_MRM');
        const views = [
            {
                instanceId: 'models',
                name: 'Models',
                listFields: MODEL_LIST_FIELDS_DEFAULT,
                insertable: true,
                bulkable: true,
                bulkEditable: userHasRoleMRM,
                bulkDeletable: userHasRoleMRM,
                bulkableNewUse: userHasRoleMRM,
                bulkRetirable: userHasRoleMRM,
                bulkCertifiable: userHasRoleMRM || userHasRoleADMIN(),
                insertOnInit: ({entity, _resource, _context}) => {
                    entity['@type'] = 'Model';
                    if (User.profile.isMemberOfValidatorTeam) {
                        entity.modelStatus = ParameterStore('MODEL_STATUS_UNDER_DECLARATION');
                        entity.modelValidatorTeams = User.profile.teams;
                    } else if (userHasRoleMRM) {
                        entity.modelStatus = ParameterStore('MODEL_STATUS_DRAFT');
                    }
                },
                aclFields: (entity, routeParams) => {
                    if (!entity.id) {
                        if (User.profile.isMemberOfValidatorTeam) {
                            return {
                                modelStatus: ParameterStore('MODEL_STATUS_UNDER_DECLARATION'),
                                modelValidatorTeams: User.profile.teams,
                                declarer: `/api/users/${User.getId()}`,
                            };
                        }
                    }
                    return null;
                }
            },
            {
                instanceId: 'retired_models',
                name: 'Retired models',
                permanentFilters: {
                    'modelStatus[0]': ParameterStore('MODEL_STATUS_RETIRED'),
                },
                listFields: MODEL_LIST_FIELDS_DEFAULT,
                deletable: true,
                bulkable: true,
                bulkEditable: userHasRoleMRM,
                bulkDeletable: userHasRoleMRM,
                bulkableNewUse: userHasRoleMRM,
            },
            {
                instanceId: 'deleted_models',
                name: 'Deleted models',
                permanentFilters: {
                    'modelStatus[0]': ParameterStore('MODEL_STATUS_DELETED'),
                    deleted: true,
                },
                listFields: MODEL_LIST_FIELDS_DEFAULT,
                bulkable: true,
                bulkEditable: userHasRoleMRM,
                bulkableNewUse: userHasRoleMRM,
            },
            {
                instanceId: 'my_models',
                name: 'My models',
                bulkable: true,
                bulkEditable: userHasRoleSTD,
                bulkEditFields: [
                    'modelType',
                    'coreModel',
                    'modelOwnerDelegation',
                    'modelDeveloperTeam',
                    'businessSponsor',
                    'riskCategory',
                    'microRiskCategory',
                    'microRiskSubCategory',
                    'applicationDomainLevel1',
                    'applicationDomainLevel2',
                    'applicationDomainLevel3',
                    'championOrChallenger',
                    'championModels',
                    'inHouseOrVendor',
                    'vendor',
                    'modelNatureStandard',
                    'modelNatureDetailedString',
                    'dataSource',
                    'operationalRiskFlag',
                    'output',
                    'mitigationActions',
                    'upStreamNotAvailable',
                    'downStreamNotAvailable',
                ],
                permanentFilters: {
                    modelOwnerOrModelOwnerDelegationOrDeveloperTeam: true,
                },

                listFields: [
                    'functionalID',
                    'initialID',
                    'modelOwner',
                    'modelOwnerDelegation',
                    'lastCertificationDate',
                    'certifierName',
                    'modelOwnerDelegationDenyComment',
                ],

                // Si présent, ce champ ajoute des droits pour les deletable, bulkDeletable, bulkEditable ...
                itemAccessCondition: (entity) =>
                    userHasOwnershipRights(User.getId(), entity) ||
                    userHasDeclarerRights(User.getId(), entity) ||
                    userHasDeveloperRights(User, entity),
                bulkEditValidateRoles: [BusinessRole.IS_OWNER, BusinessRole.IS_DECLARER, BusinessRole.IS_DEVELOPER],
                bulkableNewUse: true,
                bulkRetirable: true,

                /*buttons: [{
                      label: 'New review',
                      to: '/resource/reviews/add/' + entity.id,
                      icon: 'fa-plus'
                  }]*/
            },
            {
                instanceId: 'my_draft_models',
                name: 'My draft models',
                bulkable: true,
                deletable: true,
                bulkEditable: userHasRoleSTD,
                bulkDeletable: userHasRoleSTD,
                permanentFilters: {
                    my_draft_models: true,
                },
                listFields: [
                    'functionalID',
                    'initialID',
                    'modelOwner',
                    'modelOwnerDelegation',
                    'lastCertificationDate',
                    'certifierName',
                    'modelOwnerDelegationDenyComment',
                ],
                // Si présent, ce champ ajoute des droits pour les deletable, bulkDeletable, bulkEditable ...
                itemAccessCondition: (entity) =>
                    userHasOwnershipRights(User.getId(), entity) ||
                    userHasDeclarerRights(User.getId(), entity) ||
                    userHasDeveloperRights(User, entity),
                bulkEditValidateRoles: [BusinessRole.IS_OWNER, BusinessRole.IS_DECLARER, BusinessRole.IS_DEVELOPER],
            },
            {
                instanceId: 'declaration_models',
                name: 'My declarations in progress',
                bulkable: true,
                bulkEditable: userHasRoleMRM,
                bulkableNewUse: userHasRoleMRM,
                permanentFilters: {
                    my_declarations_in_progress: true,
                },
                listFields: [
                    'modelID',
                    'name',
                    'modelType',
                    'modelOwner',
                    'modelDeveloperTeam',
                    'riskCategory',
                    'modelStatus',
                ],
                itemAccessCondition: (entity) =>
                    userHasOwnershipRights(User.getId(), entity) ||
                    userHasDeclarerRights(User.getId(), entity) ||
                    userHasDeveloperRights(User, entity),
                bulkEditValidateRoles: [BusinessRole.IS_OWNER, BusinessRole.IS_DECLARER, BusinessRole.IS_DEVELOPER],
            },
            {
                instanceId: 'models_pending_request',
                name: 'Pending requests',
                bulkable: true,
                bulkEditable: userHasRoleMRM,
                bulkDeletable: userHasRoleMRM,
                bulkableNewUse: userHasRoleMRM,
                permanentFilters: {
                    models_pending_request: true,
                    //currentUserInMrmTeam: 1,
                },
                listFields: [
                    'modelID',
                    'process',
                    'functionalID',
                    'name',
                    'modelType',
                    'declarer',
                    'modelStatus',
                    'expectedStatus',
                    'treeDeterminationAnswers',
                    'treeDeterminationResult',
                ],
            },
            {
                instanceId: 'models_under_declaration',
                name: 'Models under declaration',
                bulkable: true,
                bulkEditable: userHasRoleMRM,
                bulkDeletable: userHasRoleMRM,
                bulkableNewUse: userHasRoleMRM,
                permanentFilters: {
                    'modelStatus[0]': ParameterStore('MODEL_STATUS_UNDER_DECLARATION'),
                },
                listFields: [
                    'modelID',
                    'process',
                    'functionalID',
                    'name',
                    'modelType',
                    'declarer',
                    'modelStatus',
                    'expectedStatus',
                    'treeDeterminationAnswers',
                    'treeDeterminationResult',
                ],
            },

            {
                instanceId: 'models_val',
                name: 'My validation perimeter',
                bulkable: true,
                bulkEditable: true,
                permanentFilters: {
                    models_val: true,
                },
                // Si présent, ce champ ajoute des droits pour les deletable, bulkDeletable, bulkEditable ...
                itemAccessCondition: (entity) => userIsVal(User, entity),
                bulkEditValidateRoles: [BusinessRole.IS_VALIDATOR],
                listFields: ['modelID', 'process', 'functionalID', 'name', 'modelType', 'declarer'],
            },
            {
                instanceId: 'specific_frameworks',
                name: 'Specific frameworks',
                permanentFilters: {
                    model_specific_framework: true
                },
                listFields: MODEL_LIST_FIELDS_DEFAULT,
                bulkable: true,
                bulkEditable: userHasRoleMRM,
                bulkDeletable: userHasRoleMRM,
                bulkableNewUse: userHasRoleMRM,
                //listTargetDetailResource: "models",
            },
            {
                instanceId: 'non_models',
                name: 'Non-Models',
                icon: 'brain',
                bulkable: true,
                bulkDeletable: userHasRoleMRM,
                permanentFilters: {
                    non_models_confirmed: true,
                },
                listFields: [
                    'modelID',
                    'name',
                    'modelType',
                    'riskCategory',
                    'modelOwner',
                    'modelDeveloperTeam',
                    'nonModelStatus',
                ],
            },
        ];
        views.forEach((view) => {
            const resource = new APIResource({
                ...ApiResourceDefaultParams,
                ...{
                    instanceId: view.instanceId,
                    name: view.name,
                    aclFields: view.aclFields,
                },
            });
            resource
                // merging the right received from the api with the ones existing locally
                .setFields(MODEL_FIELDS(resource))
                .setLayout(MODEL_LAYOUT)
                .genListView({
                    fields: view.listFields,
                    skipButtonAccessCheck: true,
                    targetDetailResource: view.listTargetDetailResource || null,
                    additionalActionButtons:
                        view.instanceId === 'models'
                            ?
                            userHasRoleMRM || userHasRoleADMIN()
                                ? [
                                    {
                                        tooltip: 'Import',
                                        icon: 'download',
                                        className: 'download',
                                        onClick: () => Modal.open({
                                            title: "Import",
                                            content: <Import ressource={'model'}/>,
                                            size: "medium",
                                        }),
                                    },
                                    {
                                        tooltip: 'Full export',
                                        icon: 'upload',
                                        className: 'upload',
                                        onClick: () => Modal.open({
                                            title: "Full export",
                                            content: <Export ressource={'model'}/>,
                                            size: "medium",
                                        }),
                                    },
                                    ...(userHasRoleADMIN() ? [freezeInitialValidationPlanFieldsButton] : []),
                                ]
                                : [
                                    // On autorise tout le monde à déclencher un export.
                                    {
                                        tooltip: 'Full export',
                                        icon: 'upload',
                                        className: 'upload',
                                        onClick: () => Modal.open({
                                            title: "Full export",
                                            content: (
                                                <Export/>
                                            ),
                                            size: "medium",
                                        }),
                                    }
                                ]
                            : [],
                    additionalListActionButtons: view.additionalListActionButtons,
                    insertButton: {
                        path: userHasRoleMRM ? '/resource/models/add' : '/resource/models/test',
                        tooltip: 'Declare a new model',
                    },
                    permanentFilters: view.permanentFilters,
                    neededFields: ['mrasEntities'],
                })
                .genEditView(MODEL_EDIT(resource))
                .genDetailView(MODEL_DETAIL(resource))
                .setValidation((entity) => validationByStatus(entity, resource));

            if (view.insertable) {
                resource.genInsertView({
                    menuItem: {title: 'Add'},
                    additionalRoutes: ['/resource/' + resource.instanceId + '/add/:modelId'],
                    routeAccessControl: () => User.profile.isMemberOfValidatorTeam === true
                        || userHasRoleMRM
                        || userHasSpecificRole(Role.IG)
                        || userHasRole(Role.ADMIN)
                    ,
                    itemAccessCondition: () => User.profile.isMemberOfValidatorTeam === true
                        || userHasRoleMRM
                        || userHasSpecificRole(Role.IG)
                        || userHasRole(Role.ADMIN),
                    onInit: view.insertOnInit,
                    operationTooltipText: 'Declare a new model',
                    insertButtonAction: ({_resource, _event}) => {
                        if (!userHasRoleMRM) {
                            Navigation.router.history.push('/resource/models/test');
                        } else {
                            Modal.open({
                                title: 'Declare a new model',
                                content: <ModalDeclareModel/>,
                                size: "small",
                            });
                        }
                    },
                    fieldsSaveWhitelist: FIELDS_SAVE_WHITE_LIST,
                });
            }

            if (view.deletable && !userHasSpecificRole(Role.IG)) {
                resource.allowDelete({
                    itemAccessCondition: (entity) =>
                        !!entity.activeVersion &&
                        (view?.itemAccessCondition?.(entity) || userHasMRMRights(User, entity)),
                    component: (entity) => {
                        return (
                            <LogicalDeleteButton
                                entity={entity}
                                className="tooltip-top"
                                resource={resource}
                                entityType={'models'}
                            />
                        );
                    },
                });
            }

            if (view.bulkEditable) {
                resource.addBulkAction(BulkEdit, {
                    resource: resource,
                    icon: 'edit',
                    bulkValidateRoles: [...view?.bulkEditValidateRoles || [], BusinessRole.IS_OWNER, BusinessRole.IS_MRMTEAM],
                    itemAccessCondition: (entity) =>
                        view?.itemAccessCondition?.(entity) ||
                        userHasOwnershipRights(User.getId(), entity) ||
                        userHasMRMRights(User, entity),
                    forbiddenAccessMessage: 'Bulk edit unauthorized.',
                    itemAccessConditionEntityFields: [
                        'id',
                        'modelID',
                        'mrmTeams',
                        'modelOwner',
                        'modelOwnerDelegation',
                        'modelOwnerDelegationAccepted',
                        'modelOwnerTeams',
                        'modelDevelopperTeam',
                        'modelValidatorTeams',
                        'validatorTeams',
                        'declarer',
                    ],
                    fields: Object.fromEntries(
                        Object.entries(APIResourceStore.resources.models.fields)
                            .filter(([k, v]) => {
                                if (view.bulkEditFields && !view.bulkEditFields.includes(k)) {
                                    return false;
                                }
                                if (v.bulkable) v.bulk = v.bulkable();
                                return v.bulk && resource.operations.edit.fields.includes(k);
                            })
                            .map(([k, v]) => ((v.resourceId = resource.id), [k, v]))
                    ),
                    callbackNeededFields: [
                        "modelStatus",
                        "validationStatus",
                        "policyExceptionStartDate",
                        "policyExceptionEndDate",
                    ],
                    callback: (ids, changeFields, resource) => {
                        if (changeFields?.productionRun === true) {
                            const models = resource.items.filter(m => ids.includes(m.id) && isModelUnauthorizedInProduction(m))
                            if (!models.length) return;

                            Modal.open({
                                title: `Warning: unauthorized model${models.length > 1 ? 's' : ''} in production`,
                                content: <UnauthorizedModelInProductionModal models={models} showModelsList={true}/>,
                                size: 'medium',
                            })
                        } else {
                            Modal.close();
                        }
                    },
                    allowNullField: (fieldId) => userHasRoleMRM && [
                        'policyExceptionStartDate',
                        'policyExceptionEndDate',
                        'policyExceptionType',
                        'policyExceptionJustification',
                    ].includes(fieldId)
                });
            }

            if (view.bulkable) {


                resource.addBulkAction(ModelExport, {
                    icon: 'file-export'
                });

                if (userHasRoleMRM || userHasRole(Role.ADMIN)) {
                    resource.addBulkAction(ChangeLogExport, {
                        from: 'model', icon: ['fas', 'tasks'],
                    });
                }

                if (
                    ((userHasRoleMRM || userHasRole(Role.ADMIN)) && view.instanceId === 'models') ||
                    (User.profile.isMemberOfValidatorTeam && view.instanceId === 'models_val')
                ) {
                    resource.addBulkAction(NewReview, {
                        resource: resource,
                        icon: 'search',
                        itemAccessCondition: (entity) =>
                            userHasMRMRights(User, entity) ||
                            (User.profile.isMemberOfValidatorTeam && view.instanceId === 'models_val'),
                        itemAccessConditionEntityFields: [
                            'id',
                            'modelID',
                            'mrmTeams',
                        ],
                        forbiddenAccessMessage:
                            "You can't create a new review in bulk for models belonging to another MRM team.", // pas besoin de message d'erreur pour VAL, le cas n'est pas possible.
                        fields: Object.fromEntries(
                            Object.entries(APIResourceStore.resources.reviews.fields)
                                .filter(([_k, v]) => {
                                    return v.bulkEdit;
                                })
                                .map(([k, v]) => ((v.resourceId = resource.id), [k, v]))
                        ),
                    });
                }

                if ((userHasRoleMRM || userHasRole(Role.ADMIN)) && view.instanceId === 'models') {
                    resource.addBulkAction(NewMra, {
                        resource: resource,
                        icon: 'tachometer-alt',
                    });
                }


                if (view.bulkableNewUse) {
                    resource.addBulkAction(NewUse, {
                        resource: resource,
                        icon: 'plus-circle',
                        itemAccessCondition: (entity) =>
                            view?.itemAccessCondition?.(entity) ||
                            userHasOwnershipRights(User.getId(), entity) ||
                            userHasMRMRights(User, entity),
                        itemAccessConditionEntityFields: [
                            'id',
                            'modelID',
                            'mrmTeams',
                            'modelOwner',
                            'modelOwnerDelegation',
                            'modelOwnerDelegationAccepted',
                            'modelOwnerTeams',
                            'modelDevelopperTeam',
                            'modelValidatorTeams',
                            'validatorTeams',
                            'declarer',
                        ],
                        forbiddenAccessMessage:
                            "You can't add a new use in bulk to models belonging to another MRM team.",
                        fields: Object.fromEntries(
                            Object.entries(APIResourceStore.resources.model_uses.fields)
                                .filter(
                                    ([k, v]) =>
                                        v.bulk &&
                                        APIResourceStore.resources.model_uses.operations.edit.fields.includes(k)
                                )
                                .map(([k, v]) => ((v.resourceId = 'model_uses'), [k, v]))
                        ),
                    });
                }
                /*
                // DO not delete, commented because of possible conflicts with certifications campaigns.    .addBulkAction(BulkAddCertifications,{
                fields: {
                    note: { title: 'Note' },
            },
        })
    */

                if (view.bulkCertifiable) {
                    resource.addBulkAction(BulkAddCertifications, {
                        resource,
                        icon: 'stamp',
                        itemAccessCondition: (entity) => {
                            return userHasMRMRights(User, entity)
                        },
                        forbiddenAccessMessage: "You can't certify models belonging to another MRM team.",
                        bulkValidateRoles: [...view?.bulkEditValidateRoles || [], BusinessRole.IS_MRMTEAM],
                    })
                }

                if (view.bulkDeletable) {
                    resource.addBulkAction(BulkDelete, {
                        resource,
                        icon: 'trash-alt',
                        itemAccessCondition: (entity) =>
                            view?.itemAccessCondition?.(entity) || userHasMRMRights(User, entity),
                        forbiddenAccessMessage: "You can't delete models belonging to another MRM team.",
                        entityType: 'model',
                        entityTypeLabel: 'Model',
                    });
                }

                if (view.bulkRetirable) {
                    resource.addBulkAction(BulkRetire, {
                        resource,
                        icon: 'trash-restore',
                        itemAccessCondition: (entity) =>
                            view?.itemAccessCondition?.(entity) || userHasRoleMRM || userHasOwnershipRights(User.getId(), entity),
                        itemAccessConditionEntityFields: [
                            'id',
                            'modelID',
                            'mrmTeams',
                            'modelOwner',
                            'modelOwnerDelegation',
                            'modelOwnerDelegationAccepted',
                            'modelOwnerTeams',
                        ],
                    });
                }
            }
        });
        // Root.rootComponent.forceUpdate();
    }
}

const styles = {
    actionLink: {
        marginLeft: 5,
    },
    customActionButton: {
        marginLeft: 5,
        color: 'white',
    },
};
