import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { Link, useParams } from 'react-router-dom';
import {
    Button,
    Paper,
    FormControlLabel,
    FormGroup,
    RadioGroup,
    Radio,
    Typography,
    TextField,
    Tooltip,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Http from '../../../Services/Http';
import Alert from '../../../Services/Alert';
import Modal from '../../../Services/Modal';
import User from '../../../Services/User/User';
import Navigation from '../../../Services/Navigation';
import FieldProviderStore from '../../../Services/APIResource/FieldProviders/__FieldProviderStore';
import { APIResource, CONTEXT_DETAIL } from '../../../Services/APIResource/APIResource';
import AppBarStore from '../../../Store/AppBarStore';
import ParameterStore, { userHasOwnershipRights, userHasRoleMRM } from '../../../Store/ParameterStore';
import { IssueAddForm } from '../../Issue/IssueButton';
import String from '../../../Services/String';
import Thread from '../../Display/Thread/Thread';
import Icon from '@material-ui/core/Icon';
import CheckRequirementsDetail from './CheckRequirementsDetail';
import { CheckRequirementsSimple } from './CheckRequirementsSimple';
import Banner from '../../Display/Banner/Banner';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import EntityAsyncProvider from '../../../Services/APIResource/FieldProviders/EntityAsyncProvider';
import { getIdFromIri } from '../../../Services/utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ActionButton } from '../../Modal/ActionButton';
import { DataTable } from '../../../Services/APIResource/Components/DataTable/DataTable';
import APIResourceStore from '../../../Store/APIResourceStore';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import { CurrentValuesForm } from './CurrentValuesForm';
import { HourglassEmpty } from '@material-ui/icons';
import { renderAdditionalActionButtons } from '../../../Services/APIResource/Utils';

const entityHumanReadableNames = {
    'modelUses': 'model use',
    'implementations': 'implementation',
    'coveringMitigationActions': 'mitigation action',
    'lastTiering': 'tiering',
};

const getTooltip = (relationName) => {
    if (!relationName) return 'Missing data';
    if(relationName === 'coveringMitigationActions'){
        return 'Add one mitigation action or indicate that this is not applicable';
    }else if(relationName === 'implementations'){
        return 'At least one implementation is required for a model in production';
    }
    return 'At least one ' + entityHumanReadableNames[relationName] + ' is required';
}

const CheckRequiredField = (props) => {
    const { model, relationName, resourceName, campaignId } = props;
    
    if (model && !model.checkRequiredFields[relationName])
        return <>NA</>;

    /**
     * {@see backend ModelCertificationCampaign.getModelsToCertifyEntities().checkRequiredFields for content}
     */
    return <div>
        {
            model && model.checkRequiredFields[relationName].totalErrors > 0 ?
            <Tooltip title={getTooltip()} arrow placement="right">
                <div
                    onClick={() => {
                        Modal.open({
                            title: 'Fields',
                            content: <>
                                <CheckRequirementsDetail
                                    certificationId={campaignId}
                                    entities={model.checkRequiredFields[relationName].entities}
                                    resourceName={resourceName}
                                />
                                <CurrentValuesForm
                                    entity={model}
                                    certificationId={campaignId}
                                    relationName={relationName}
                                    readonly={true}
                                />
                            </>
                        });
                    }}
                >
                    <Icon className={"fa fa-times text-danger"} style={styles.iconWithHand}/>
                </div>
            </Tooltip>
            : ( model
                && (
                    ['modelUses', 'implementations', 'coveringMitigationActions', 'lastTiering'].includes(relationName)
                    && model.checkRequiredFields[relationName].totalErrors === 0
                    && model.checkRequiredFields.model.relations
                    && model.checkRequiredFields.model.relations[relationName + "Entities"] === true
                )
                ?
                <Tooltip title={getTooltip(relationName)} arrow placement="right">
                    <div
                        onClick={() => {
                            Modal.open({
                                title: 'Fields',
                                content: <>
                                    <CheckRequirementsSimple
                                        entity={model}
                                        certificationId={campaignId}
                                        relationName={relationName}
                                        message={getTooltip(relationName)}
                                    />
                                    <CurrentValuesForm
                                        entity={model}
                                        certificationId={campaignId}
                                        relationName={relationName}
                                        readonly={true}
                                    />
                                </>
                            });
                        }}
                    >
                        <Icon className={"fa fa-times text-danger"} style={styles.iconWithHand}/>
                    </div>
                </Tooltip>
                : 
                <Tooltip title={"Data Complete"} arrow placement="right">
                    <div
                        onClick={() => {
                            Modal.open({
                                title: 'Fields',
                                content: <>
                                    <CurrentValuesForm
                                        entity={model}
                                        certificationId={campaignId}
                                        relationName={relationName}
                                        readonly={true}
                                    />
                                </>
                            });
                        }}
                    >
                        {model.checkRequiredFields[relationName]?.checkRequirementsPending ? <HourglassEmpty style={{color: "orange"}}/> : <Icon className="text-success fa fa-check"/>}
                    </div>
                </Tooltip>
            )
        }
    </div>;
}
CheckRequiredField.propTypes = {
    /** Model à traiter */
    model: PropTypes.object,
    /** Nom de la relation depuis le model dans checkRequiredFields, ex: modelUses (donc model.checkRequiredFields['modelUses']) */
    relationName: PropTypes.string,
    /** Nom de la resource associée à relationName, ex: model_uses */
    resourceName: PropTypes.string,
    /** Id de la campagne en cours */
    campaignId: PropTypes.number,
}

const CompletenessOfCertificationSection = (props) => {
    const { isModelOwner, modelCertificationCampaign } = props;

    if (!isModelOwner) return;

    return (
        <Paper style={styles.footerActions} className="container">
            <FormGroup style={styles.checkboxes} row>
                All the models of my perimeter are listed:{' '}
                <b>
                    {modelCertificationCampaign && modelCertificationCampaign.completenessOfCertification
                        ? 'Yes'
                        : 'No'}
                </b>
            </FormGroup>
        </Paper>
    );
}
CompletenessOfCertificationSection.propTypes = {
    isModelOwner: PropTypes.bool,
    modelCertificationCampaign: PropTypes.shape({
        completenessOfCertification: PropTypes.bool
    }),
}

/**
 * Render the buttons at the bottom of the page
 */
const ActionFooter = (props) => {
    const { campaignCanBeEdited, isModelOwner, modelCertificationCampaign, saving } = props;
    const { handleSaveClick, setModelCertificationCampaign } = props;

    if (
        !campaignCanBeEdited
        // || campaignCompleted
    ) {
        if (isModelOwner) {
            return <CompletenessOfCertificationSection
                isModelOwner={isModelOwner}
                modelCertificationCampaign={modelCertificationCampaign}
            />;
        }
        return null;
    }

    return (
        <Paper style={styles.footerActions} className="container">
            {isModelOwner && (
                <FormGroup style={styles.checkboxes} row>
                    <RadioGroup
                        value={`${modelCertificationCampaign.completenessOfCertification}`}
                        onChange={(event) => {
                            setModelCertificationCampaign({
                                ...modelCertificationCampaign,
                                completenessOfCertification: event.target.value === 'true',
                            });
                        }}
                    >
                        <FormControlLabel
                            value={'true'}
                            control={<Radio />}
                            label="All the models of my perimeter are listed"
                        />
                        <FormControlLabel
                            value={'false'}
                            control={<Radio />}
                            label="At least one of the models from my perimeter is not listed"
                        />
                    </RadioGroup>
                </FormGroup>
            )}

            {isModelOwner && modelCertificationCampaign.completenessOfCertification === false && (
                <FormGroup>
                    <TextField
                        id="outlined-helperText"
                        label="Exhaustiveness comment"
                        value={modelCertificationCampaign.missingModelComment}
                        variant="outlined"
                        multiline={true}
                        rows={3}
                        style={styles.comment}
                        disabled={!isModelOwner}
                        onChange={(event) => {
                            setModelCertificationCampaign({
                                ...modelCertificationCampaign,
                                missingModelComment: event.target.value,
                            });
                        }}
                    />
                </FormGroup>
            )}

            <FormGroup>
                <ActionButton
                    disabled={
                        (
                            !isModelOwner 
                            && !modelCertificationCampaign.modelOwnersDelegation.includes(`/api/users/${User.getId()}`)
                        ) ||
                        (modelCertificationCampaign.completenessOfCertification === false &&
                            !modelCertificationCampaign.missingModelComment)
                        || saving
                    }
                    onClick={handleSaveClick}
                    loading={saving}
                >
                    {!saving && "Confirm your certification"}
                </ActionButton>
            </FormGroup>
        </Paper>
    );
};
ActionFooter.propTypes = {
    isModelOwner: PropTypes.bool,
    modelCertificationCampaign: PropTypes.shape({
        "@context": PropTypes.string,
        completenessOfCertification: PropTypes.bool,
        modelOwnersDelegation: PropTypes.arrayOf(PropTypes.string),
        missingModelComment: PropTypes.string,
    }),
    campaignCanBeEdited: PropTypes.bool,
    saving: PropTypes.bool,
    handleSaveClick: PropTypes.func,
    setModelCertificationCampaign: PropTypes.func,
}

/**
 * Render the buttons at the end of each line
 */
const TroubleActions = (props) => {
    const { modelCertificationCampaign, model } = props;

    if (!userHasOwnershipRights(User.getId(), model)) {
        return '';
    }

    return (
        <Tooltip
            title={'Declare an ownership objection or a problem regarding an information I can not modify.'}
            arrow 
            placement={"top"}
        >
            <ActionButton
                style={{ marginRight: 1, marginBottom: 0, marginTop: 0 }}
                size={"small"}
                //className="button-table tooltip tooltip-left"
                onClick={() =>
                    Modal.open({
                        title: 'New certification trouble',
                        content: (
                            <IssueAddForm
                                title={'New certification trouble'}
                                type={ParameterStore('TROUBLE_TYPE_CERTIFICATION')}
                                models={[`/api/models/${model.id}`]}
                                modelCertificationCampaign={modelCertificationCampaign['@id']}
                                entity={model}
                                description=""
                                onClose={() => {
                                    /** @todo */
                                    /* il faut récupérer la mcc depuis le back et éventuellement mettre à jour la liste des troubledModels 
                                    getCampaign(modelCertificationCampaign.id)
                                    let troubledModels = {
                                        ...this.state.modelsWithTroubles,
                                    };
                                    troubledModels[model.id] = true;
                                    this.setState({
                                        modelsWithTroubles: troubledModels,
                                    }); */
                                }}
                            />
                        ),
                    })
                }
            >
                New Trouble
            </ActionButton>
        </Tooltip>
    );
};
TroubleActions.propTypes = {
    model: PropTypes.shape({
            id: PropTypes.number,
    }),
    modelCertificationCampaign: PropTypes.shape({
            '@id': PropTypes.string,
    }),
};

const TroublesActions = (props) => {
    const { troubles = [], model, modelCertificationCampaign, campaignCanBeEdited, checkedModels } = props;
    return (
        <div>
            {troubles.map((trouble) => (
                <div key={trouble.id} style={{ whiteSpace: 'normal' }}>
                    {trouble && !checkedModels[model.id] ? trouble.description : null}
                </div>
            ))}
            {troubles.length && troubles.every((t) => t.statusString === 'Closed') ? <hr /> : null}
            {(!troubles.length || troubles.every((t) => t.statusString === 'Closed'))
                && !checkedModels[model.id]
                && campaignCanBeEdited
             ? (
                <TroubleActions
                    model={model}
                    modelCertificationCampaign={modelCertificationCampaign}
                    campaignCanBeEdited={campaignCanBeEdited}
                />
            ) : null}
        </div>
    );
};
TroublesActions.propTypes = {
    troubles: PropTypes.array,
    model: PropTypes.object,
    modelCertificationCampaign: PropTypes.object,
    campaignCanBeEdited: PropTypes.bool,
    checkedModels: PropTypes.object,
};

/** Prend un "modelEntity" en entrée et autorise ou non la selection pour certification. */
const canBeCertified = (model) => {
    return (model.allRequirementsCheckedToCertify ??
        !(model.checkRequiredFields['model'].totalErrors > 0
        || model.checkRequiredFields['modelUses'].totalErrors > 0
        || model.checkRequiredFields['modelUses'].missingEntities === true
        || model.checkRequiredFields['implementations'].totalErrors > 0
        || model.checkRequiredFields['implementations'].missingEntities === true 
        || model.checkRequiredFields['coveringMitigationActions']?.totalErrors > 0
        || model.checkRequiredFields['coveringMitigationActions']?.missingEntities === true
        || model.checkRequiredFields['lastTiering']?.totalErrors > 0
        || model.checkRequiredFields['lastTiering']?.missingEntities === true)
    );
}

const isAlreadyCertified = (model) => model?.certified === "Yes";

const getModelsWithTrouble = (modelCertificationCampaign) => {
    return (modelCertificationCampaign?.troublesEntities || []).reduce((acc, trouble) => {
        trouble.models.forEach((model) => acc.includes(model) || acc.push(model));
        return acc;
    }, []);
};

const hasTrouble = (model, modelCertificationCampaign) => {
    return getModelsWithTrouble(modelCertificationCampaign).includes(model['@id']);
};

const shouldBeChecked = (model, modelCertificationCampaign) => {
    return isAlreadyCertified(model) && !hasTrouble(model, modelCertificationCampaign);
};

const ModelList = (props) => {
    const { fromMrmList } = props;
    const matchParams = useParams();

    const [id, setId] = useState();
    const [modelCertificationCampaign, setModelCertificationCampaign] = useState(undefined);
    const [modelFields, setModelFields] = useState({});
    const [modelEntities, setModelEntities] = useState([]);
    const [tabsDefinition, setTabsDefinition] = useState([]);
    const [init, setInit] = useState(false);
    const [isModelOwner, setIsModelOwner] = useState(undefined);
    const [currentTab, setCurrentTab] = useState(0);
    const [saving, setSaving] = useState(false);
    const [campaignCanBeEdited, setCampaignCanBeEdited] = useState(false);
    /** stocke {model.id : bool} si le model est checked ou non pour tous les models */
    const [checkedModels, setCheckedModels] = useState({});
    const [areAllCheckboxChecked, setAreAllCheckboxChecked] = useState(false);
    const [troublesByModel, setTroublesByModel] = useState({});
    const [permanentFilters, setPermanentFilters] = useState({ canListMccModelEntities: true, });
    const [triggerHeaderRefresh, setTriggerHeaderRefresh] = useState(false);

    AppBarStore.title = 'Model certification campaign';
    
    /** @type {import('../../../Services/APIResource/APIResource').APIResource} */
    const mccResource = new APIResource({
        id: 'model_certification_campaigns',
    });
    /** @type {{current: import('../../../Services/APIResource/APIResource').APIResource}} */
    const modelEntitiesResource = useRef();

    useEffect(() => {
      setId(parseInt(props.id || matchParams?.id));
    }, [matchParams, props.id])
    

    useEffect(() => {
        if (props.modelCertificationCampaign) {
            setModelCertificationCampaign(props.modelCertificationCampaign);
        } else {
            getCampaign(id);
        }

        return () => {
            mccResource.currentRequestGetOne?.cancel('NoAlert');
        }
    }, [id, props.modelCertificationCampaign])

    useEffect(() => {
        if (!modelCertificationCampaign) return;

        redirectUnlessGranted();
        
        modelEntitiesResource.current = new APIResource({
            id: `campaign/${modelCertificationCampaign.id}/modelEntities`,
            instanceId: `model_certification_campaign_table_${modelCertificationCampaign.id}`,
            name:'Model entities for Model Certification campaign',
            fieldForTitle: 'toString',
        });
        
        setIsModelOwner(modelCertificationCampaign.modelOwner === `/api/users/${User.getId()}`);

        updateTroublesByModel();
        updateTabsDefinition();
    }, [modelCertificationCampaign]);

    useEffect(() => {
        setInit(!!tabsDefinition.length);
    }, [tabsDefinition])

    useEffect(() => {
        setPermanentFilters(!currentTab || !tabsDefinition || currentTab === 0 ?
            { canListMccModelEntities: true, }
            :
            { modelOwnerDelegation: tabsDefinition[currentTab]['apiPath'] }
        )
    }, [currentTab, tabsDefinition])

    useEffect(() => {
        if (!modelCertificationCampaign) return;

        setModelFields(genModelFields());
    }, [modelCertificationCampaign, campaignCanBeEdited, checkedModels, troublesByModel, areAllCheckboxChecked]);
    
    useEffect(() => {
        if (!modelCertificationCampaign) return;

        const keepField = (v) => v.forMrm === !!fromMrmList || v.forMrm === undefined;
        const tableFields = Object.entries(modelFields).reduce((acc, [k, v]) => {
            if (keepField(v)) acc[k] = v;
            return acc;
        }, {});

        modelEntitiesResource.current
            .setFields(tableFields)
            .genListView({
                fields: Object.keys(tableFields),
                doNotDisplayTitle: true
            })
        ;
        // Le composant Checkbox "Certify" est contrôlé, donc on doit forcer le rerender
        // du header de DataTable. La valeur n'a pas d'importance tant qu'elle change.
        setTriggerHeaderRefresh(!triggerHeaderRefresh);
    }, [modelCertificationCampaign, fromMrmList, modelFields, isModelOwner])
    
    
    useEffect(() => {
        // redirect to notice page if the campaign was not acknowledged.
        if (modelCertificationCampaign && !modelCertificationCampaign.acknowledgmentOfReceipt && isModelOwner) {
            Navigation.router.history.push(
                `/resource/my_model_certification_campaigns/${modelCertificationCampaign.id}/notice`
            );
            return;
        }
    }, [isModelOwner, modelCertificationCampaign]);

    useEffect(() => {
       setCampaignCanBeEdited(modelCertificationCampaign && modelCertificationCampaign.statusString !== 'Closed' && !fromMrmList);
    }, [modelCertificationCampaign, fromMrmList])
    
    useEffect(() => {
        /**
         * @todo par défaut on ne "checke" que ceux que l'on voit, cela peut être gênant
         *       on peut conserver ceux que l'on a déjà vus en faisant le reduce sur checkedModels ?
         *       mais on ne pourra pas tous les sélectionner sans faire une requête supplémentaire au back.
         */
        const toCheck = modelEntities.reduce((acc, model) => (acc[model.id] = shouldBeChecked(model, modelCertificationCampaign), acc) , {});
        setCheckedModels(toCheck);
    }, [modelEntities])

    useEffect(() => {
      setAreAllCheckboxChecked(Object.values(checkedModels).length > 0 && Object.values(checkedModels).every(v => v))
    }, [checkedModels])

    const updateTabsDefinition = async () => {
        let tabs = [];
        const users = await Promise.all([
            APIResourceStore.resources.users.getItemFromResourcePath(modelCertificationCampaign.modelOwner),
            ...(modelCertificationCampaign?.modelOwnersDelegation || []).map(u => APIResourceStore.resources.users.getItemFromResourcePath(u))
        ]);
        users.forEach(u => {
            if (u?.['@id'] && tabs.every(tab => u.toString !== tab.name)) {
                tabs.push({
                    apiPath: u['@id'],
                    name: u.toString,
                })
            }
        });
        setTabsDefinition(tabs);
    }

    const updateTroublesByModel = () => {
        const inverseTroubles = (modelCertificationCampaign?.troublesEntities || []).reduce((carry, trouble) => {
            trouble.models.forEach(
                (model) => (carry[getIdFromIri(model)] = [...(carry[getIdFromIri(model)] || []), trouble])
            );
            return carry;
        }, {});
        setTroublesByModel(inverseTroubles);
    };

    const redirectUnlessGranted = () => {
        const userIri = `/api/users/${User.getId()}`;
        if (
            !userHasRoleMRM() &&
            modelCertificationCampaign.modelOwner !== userIri &&
            !(modelCertificationCampaign?.modelOwnersDelegation || []).includes(userIri)
        ) {
            Navigation.router.history.push(`/resource/my_model_certification_campaigns/list`);
        }
    };

    /**
     * @param {Array<object>} models - currentList Items
     */
    const onDataRefreshed = (models = []) => {
        setModelEntities(models);
    }

    /**
     * @return {Object.<string, import('../../../Services/APIResource/APIResource').APIResourceField>}
     */
    const genModelFields = () => {
        return {
            certify: {
                title: (toString) => {
                    if(toString){
                        return 'Certify';
                    }
                    return (
                        <div key={`checkboxall_${areAllCheckboxChecked ? 'checked' : 'unchecked'}`}>
                            <Typography fontWeight="fontWeightBold" style={styles.checkboxHeader}>
                                Certify
                            </Typography>
                            <Checkbox
                                checked={areAllCheckboxChecked}
                                disabled={!campaignCanBeEdited}
                                onChange={(event, checked) => toggleAllCheckboxes(checked)}
                            />
                        </div>
                    )
                },
                type: 'checkbox',
                width: 50,
                noTooltip: true,
                forMrm: false,
                canonicalFieldName: 'certified', // juste pour afficher les éléments de la colonne
                listCanSort: false,
                // styles: { paddingLeft: 10 },
                displayList: (field, value, entity, _props) => {
                    if (!entity) {
                        return true;
                    }
                    return (
                        <div>
                            <Checkbox
                                checked={checkedModels[entity.id] || false}
                                disabled={
                                    !campaignCanBeEdited ||
                                    !userHasOwnershipRights(User.getId(), entity) ||
                                    !canBeCertified(entity) ||
                                    isAlreadyCertified(entity)
                                }
                                onChange={(event, checked) => setCheckedModels({ ...checkedModels, [entity.id]: checked })}
                            />
                        </div>
                    );
                },
                filter: () => null,
            },
            toString: {
                title: 'Model ID',
                width: 180,
                displayList: (field, value, entity) => {
                    if (!entity) {
                        return true;
                    }
                    return (
                        <Link to={`/resource/models/${entity.id}/detail?certification-id=${modelCertificationCampaign.id}`}>
                            <div className="link">
                                <b>{value}</b>
                            </div>
                        </Link>
                    );
                },
            },
            name: {
                title: 'Name',
                styles: {
                    paddingLeft: 10,
                    width: 300,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: 'black',
                    textDecoration: 'none',
                },
                width: 250,
                displayList: (field, value, entity) => {
                    if (!entity) {
                        return true;
                    }
                    return (
                        <Link to={`/resource/models/${entity.id}/detail?certification-id=${modelCertificationCampaign.id}`}>
                            <div className="link">
                                <b>{entity.name}</b>
                            </div>
                        </Link>
                    );
                },
            },
            readableStatus: {
                title: 'Status',
                width: 100,
            },
            modelOwner: {
                title: 'Model Owner',
                type: 'user',
                width: 120,
                params: {
                    resource: 'users',
                    instanceId: 'users_mo',
                    displayField: 'toString',
                    editDisplayField: 'fullNameWithTeam',
                    multi: false,
                    links: false,
                    sortField: 'lastName',
                    endpoints: {
                        getAll: 'users/all-users/mo',
                    },
                },
                filter: () => null,
            },
            modelOwnerDelegation: {
                title: 'Delegated model owner',
                type: 'user',
                width: 120,
                params: {
                    resource: 'users',
                    instanceId: 'users_mo',
                    displayField: 'toString',
                    editDisplayField: 'fullNameWithTeam',
                    multi: false,
                    links: false,
                    sortField: 'lastName',
                    endpoints: {
                        getAll: 'users/all-users/mo',
                    },
                },
                displayList: (field, value, entity, props) => {
                    if (entity && entity.modelOwnerDelegationAccepted) {
                        props.plainText = true;
                        return EntityAsyncProvider.getDisplayList(field, value, entity, props);
                    }
                    return true;
                },
                filter: () => null,
            },
            certified: {
                title: 'Certified',
                type: 'mapped',
                width: 60,
                params: {
                    mapping: {
                        No: 'No',
                        Yes: 'Yes',
                    },
                },
            },
            lastCertificationDate: {
                title: 'Last certification date',
                type: 'date',
                props: { plainText: true },
                filter: () => null,
                width: 160,
            },
            certifierName: {
                title: 'Last certifier',
                filter: () => null,
                width: 120,
            },
            checkRequiredFieldsModel: {
                title: 'Model',
                type: 'bool',
                params: {
                    mapping: {
                        false: 'Incomplete',
                        true: 'Complete',
                    },
                },
                width: 120,
                noTooltip: true,
                displayList: (field, value, entity, _props) => {
                    if (!entity) {
                        return true;
                    }
                    return <CheckRequiredField model={entity} relationName={'model'} resourceName={'models'} campaignId={modelCertificationCampaign.id} />;
                },
                styles: { width: '100px', textAlign: 'center', paddingLeft: 24 },
                headerStyles: { textAlign: 'center' },
            },
            checkRequiredFieldsUses: {
                title: 'Uses',
                type: 'bool',
                params: {
                    mapping: {
                        false: 'Incomplete',
                        true: 'Complete',
                    },
                },
                width: 120,
                noTooltip: true,
                displayList: (field, value, entity, _props) => {
                    if (!entity) {
                        return true;
                    }
                    return <CheckRequiredField model={entity} relationName={'modelUses'} resourceName={'model_uses'} campaignId={modelCertificationCampaign.id} />;
                },
                styles: { width: '100px', textAlign: 'center', paddingLeft: 24 },
                headerStyles: { textAlign: 'center' },
            },
            checkRequiredFieldsImplementations: {
                title: 'Implementations',
                type: 'bool',
                params: {
                    mapping: {
                        false: 'Incomplete',
                        true: 'Complete',
                    },
                },
                width: 120,
                noTooltip: true,
                displayList: (field, value, entity, _props) => {
                    if (!entity) {
                        return true;
                    }
                    return <CheckRequiredField model={entity} relationName={'implementations'} resourceName={'implementations'} campaignId={modelCertificationCampaign.id} />;
                },
                styles: { width: '100px', textAlign: 'center', paddingLeft: 24 },
                headerStyles: { textAlign: 'center' },
            },
            checkRequiredFieldsMitigationActions: {
                title: 'Mitigation Actions',
                type: 'bool',
                params: {
                    mapping: {
                        false: 'Incomplete',
                        true: 'Complete',
                    },
                },
                width: 120,
                noTooltip: true,
                displayList: (field, value, entity, _props) => {
                    if (!entity) {
                        return true;
                    }
                    return <CheckRequiredField model={entity} relationName={'coveringMitigationActions'} resourceName={'mitigation_actions'} campaignId={modelCertificationCampaign.id} />;
                },
                styles: { width: '100px', textAlign: 'center', paddingLeft: 24 },
                headerStyles: { textAlign: 'center' },
            },
            checkRequiredFieldsTierings: {
                title: 'Tierings',
                type: 'bool',
                params: {
                    mapping: {
                        false: 'Incomplete',
                        true: 'Complete',
                    },
                },
                width: 120,
                noTooltip: true,
                displayList: (field, value, entity, _props) => {
                    if (!entity) {
                        return true;
                    }
                    return <CheckRequiredField model={entity} relationName={'lastTiering'} resourceName={'tierings'} campaignId={modelCertificationCampaign.id} />;
                },
                styles: { width: '100px', textAlign: 'center', paddingLeft: 24 },
                headerStyles: { textAlign: 'center' },
            },
            issues: {
                title: 'Trouble',
                canonicalFieldName: 'hasTrouble',
                width: 120,
                noTooltip: true,
                displayList: (field, value, entity, _props) => {
                    if (!entity) {
                        return true;
                    }

                    const troubles = troublesByModel[entity.id];
                    return (
                        <TroublesActions
                            troubles={troubles}
                            model={entity}
                            modelCertificationCampaign={modelCertificationCampaign}
                            campaignCanBeEdited={campaignCanBeEdited}
                            checkedModels={checkedModels}
                        />
                    );
                },
                /** @todo les styles ne sont pas pris en compte */
                // styles: { width: '140px', textAlign: 'center', paddingLeft: 24 },
                // headerStyles: { textAlign: 'center' },
                filter: () => null,
            },
        };
    };

    const toggleAllCheckboxes = (checked) => {
        const canBeCertifiedModels = modelEntities.reduce((acc, model) => {
            acc[model.id] = checked && canBeCertified(model);
            return acc;
        }, {})

        const alreadyCheckedIds = Object.entries(checkedModels).filter(([_k, v]) => v).map(([k, _v]) => k);
        const willBeCheckedIds = Object.entries(canBeCertifiedModels).filter(([_k, v]) => v).map(([k, _v]) => k);

        if (
            (!checked || willBeCheckedIds.length > 0) &&
            willBeCheckedIds.every((id) => alreadyCheckedIds.includes(id))
        ) {
            // Dans le cas où le toggle all ne change rien, on reset tous les models
            setCheckedModels(
                modelEntities.reduce((acc, model) => {
                    acc[model.id] = false;
                    return acc;
                }, {})
            );
            return;
        }
        
        if(checked && Object.values(canBeCertifiedModels).filter(v => v).length !== modelEntities.length) {
            Alert.show({ message: 'Please verify all the requirements', type: "error" });
        } else if (checked) {
            Alert.show({ message: 'You checked ' + Object.keys(canBeCertifiedModels).length + ' of ' + modelEntities.length + ' models.', type: "success" });
        }

        setCheckedModels(canBeCertifiedModels);
    }

    const getCampaign = async (id, forceReload = false) => {
        const campaign = await mccResource.apiGetOne(id, forceReload);
        setModelCertificationCampaign(campaign);
    }

    const handleSaveClick = () => {
        const idsToCerfify = [];
        const idsToRemove = [];
        const idsChecked = [];

        setSaving(true);

        modelEntities.forEach((model) => {
            if (checkedModels[model.id]) {
                idsChecked.push(model.id);
            }
            // do not treat models that are already certified and checked
            if (isAlreadyCertified(model) && checkedModels[model.id]) {
                return;
            }

            if (checkedModels[model.id]) {
                idsToCerfify.push(model.id);
            }

            // Remove unchecked certifications
            // @deprecated
            //if (isAlreadyCertified(model) && !checkedModels[model.id]) {
            //    idsToRemove.push(model.id);
            //}
        });

        certifyModels(idsToCerfify)
            /**
             * @see https://app.asana.com/0/0/1203765565870755/1203786203998140/f on ne supprime plus les troubles 
            .then(() => {
                return removeModelTroubles(idsChecked);
            }) */
            .then(() => {
                if (idsToRemove.length) {
                    return removeCertificationModels(idsToRemove);
                }
            })
            .then(() => {
                Alert.show({
                    message: `You have just certified ${idsToCerfify.length} models.`,
                    type: 'success',
                });

                // if many campaigns then we need a for loop that will certify all of them in one go.
                return mccResource.apiPut({
                    id: modelCertificationCampaign.id,
                    ...modelCertificationCampaign,
                    certifications: undefined,
                    troubles: undefined,
                });
            })
            .then((modelCertificationCampaign) => {
                setSaving(false);
                setModelCertificationCampaign(modelCertificationCampaign);
            });
    }

    const certifyModels = (ids) => {
        return Http.post('models/addCertifications', {
            ids: ids,
            note: '',
            modelCertificationCampaign: parseInt(modelCertificationCampaign.id),
        });
    }

    const removeCertificationModels = (ids) => {
        return Http.post('models/removeCertifications', {
            ids: ids,
            modelCertificationCampaign: parseInt(modelCertificationCampaign.id),
        });
    }

    const renderCampaignSummary = ($excerpt = false) => {
        const fields = [
            { field: 'id', title: 'ID' },
            {
                field: 'modelOwner',
                title: 'Model owner',
                type: 'user',
                required: true,
                params: {
                    resource: 'users',
                    instanceId: 'users_mo',
                    displayField: 'toString',
                    editDisplayField: 'fullNameWithTeam',
                    endpoints: {
                        getAll: 'users/all-users/mo',
                    },
                },
            },
            {
                field: 'status',
                title: 'Status',
                type: 'mapped',
                params: {
                    mapping: {
                        0: 'Ongoing',
                        1: 'Closed',
                    },
                },
            },
            {
                field: 'certificationCampaignState',
                title: 'Campaign State',
                type: 'mapped',
                params: {
                    mapping: {
                        0: 'Partial',
                        1: 'Full',
                    },
                },
            },
            { field: 'startDate', title: 'Start date', type: 'date' },
            { field: 'endDate', title: 'End date', type: 'date' },
            {
                field: 'completenessOfCertification',
                title: 'Exhaustiveness',
                type: 'bool',
            },
            {
                field: 'acknowledgmentOfReceipt',
                title: 'Acknowledgement of receipt',
                type: 'bool',
            },
            {
                field: 'numberOfModels',
                title: 'Total number of models',
                type: 'float',
            },
            {
                field: 'numberOfCertifiedModels',
                title: 'Number of certified models',
                type: 'float',
            },
            {
                field: 'numberOfOpenTroubles',
                title: 'Number of open troubles',
                type: 'float',
            },
        ]
        return fields.map((fieldDefinition) => {
            //Extrait pour la version MO
            if($excerpt === true && ![
                'id',
                'startDate',
                'endDate',
                'status',
                'numberOfCertifiedModels',
                'numberOfModels'
            ].includes(fieldDefinition.field)){
                return null;
            }
            return (
                /** @todo EntityForm ?? */
                <div key={fieldDefinition.field} className={'detail-field ressource-api-field'}>
                    {FieldProviderStore[fieldDefinition.type || 'default'].getDisplay(
                        fieldDefinition,
                        modelCertificationCampaign[fieldDefinition.field],
                        modelCertificationCampaign,
                        fieldDefinition.props
                    )}
                </div>
            );
        });
    }

    const genTabs = () => {
        let tabs = [];
        if (tabsDefinition.length === 0) return <LoadingIndicator />;

        for (let tabKey in tabsDefinition) {
            let tabLabel = tabsDefinition[tabKey]['name'];
            tabs.push(
                <Tab
                    label={tabLabel}
                    key={tabKey}
                />
            );
        }
        return tabs;
    };

    const additionalActionButtons = renderAdditionalActionButtons(
        mccResource,
        modelCertificationCampaign,
        CONTEXT_DETAIL,
        {state: {}, props: {}}
    );

    return <>
        {
            init || <Grid className={'export container'}>
                <div className={"export_progress_container"}>
                    <CircularProgress />
                </div>
            </Grid>
        }

        {
            init && <Grid className={!fromMrmList ? 'container' : ''}>
                {!campaignCanBeEdited && modelCertificationCampaign && !fromMrmList && (
                    <Paper className="resource-detail ressource-api-box" style={styles.headerBlock}>
                        <h1 className="background-linear-gradient" style={{ color: 'white', paddingLeft: 10 }}>
                            Campaign properties
                        </h1>
                        <div className="ressource-api-container">
                            <div className="ressource-api-box">{renderCampaignSummary()}</div>
                        </div>
                    </Paper>
                )}
                {campaignCanBeEdited && !fromMrmList && <Paper className="resource-detail ressource-api-box" style={styles.headerBlock}>
                    <h1 className="background-linear-gradient" style={{ color: 'white', paddingLeft: 10 }}>
                        Campaign information
                    </h1>
                    <div className="ressource-api-container">
                        <div className="ressource-api-box">{renderCampaignSummary(true)}</div>
                    </div>
                </Paper>}
                {campaignCanBeEdited && !fromMrmList && <Banner style={{marginBottom: 20}}>
                    Please find below all models that need your review for this certification campaign.
                    <ul style={{listStyleType: "none"}}>
                        <li>
                            <Icon className="fa fa-check text-success" /> means that all required data for the model’s certification is completed. If all the information is accurate, you can now tick the « Certify » box to certify your model.
                        </li>
                        <li>
                            <Icon className="fa fa-times text-danger" /> means that some data is missing for the model’s certification. Please click on the cross button to see and edit the missing data.
                        </li>
                        <li>
                            <HourglassEmpty style={{color: "orange"}}/> means that it is awaiting validation by MRM.
                        </li>
                    </ul>
                    <strong style={{fontSize: 19}}>Please do not forget to click on the « Certify » box for each model then click on the "Confirm your certification" button below in order to validate.</strong>
                </Banner>}
                {
                    (isModelOwner || userHasRoleMRM()) &&
                    <AppBar position="static" color="default">
                        <Tabs
                            value={currentTab}
                            onChange={(event, val) => setCurrentTab(val)}
                            className="tabs-menu"
                            indicatorColor="primary"
                            textColor="primary"
                            variant="standard"
                            scrollButtons="auto"
                            key={'resourceDetailTab'}
                        >
                            {genTabs()}
                        </Tabs>
                    </AppBar>
                }
                <DataTable
                    resource={modelEntitiesResource.current}
                    permanentFilters={permanentFilters}
                    onDataRefreshed={onDataRefreshed}
                    triggerHeaderRefresh={triggerHeaderRefresh}
                    maxHeight={50}
                />
                {!fromMrmList && <ActionFooter
                    isModelOwner={isModelOwner}
                    campaignCanBeEdited={campaignCanBeEdited}
                    modelCertificationCampaign={modelCertificationCampaign}
                    saving={saving}
                    handleSaveClick={handleSaveClick}
                    setModelCertificationCampaign={setModelCertificationCampaign}
                />}
                {modelCertificationCampaign && !fromMrmList && (<>
                        <Banner>
                            You can use the below chat to communicate with the MRM team following your certification.
                        </Banner>
                        <Paper style={styles.footerActions} className="container">
                            <Thread
                                entityType="model_certification_campaign"
                                entityId={modelCertificationCampaign.id}
                            ></Thread>
                        </Paper>
                    </>
                )}
                {Array.isArray(additionalActionButtons) && additionalActionButtons.length > 0 && (
                    <div className={'button-custom-bar-bottom resource-detail'}>
                        {additionalActionButtons}
                    </div>
                )}
                <div className="button-bar-bottom">
                  <Tooltip
                      title='Declare a new Model'
                      arrow={true}
                      leaveTouchDelay={300}
                  >
                    <span>
                      <Link
                        to={(userHasRoleMRM() ? '/resource/models/add' : '/resource/models/test') + `?certification-id=${modelCertificationCampaign.id}`}
                        style={styles.actionLink}
                      >
                        <Button variant="contained" className="primary tooltip">
                            <i className={'fa fa-plus'}></i>
                        </Button>
                      </Link>
                    </span>
                  </Tooltip>

                    <Tooltip
                        title={String.nlToBr(`
                        In the Model Risk Management framework, a key quality control of the models inventory is the certification process. It aims at ensuring that all models are registered in the inventory and that the information is up to date.

As Model Owner, you are kindly requested to attest the completeness and the accuracy of the models inventory:
• Confirmation that all the models you own are recorded in the inventory. Otherwise, you must notify MRM of any model you own that is not recorded ;
• Confirmation that the data on your scope of models in the inventory is complete and accurate. Otherwise, you should update any information that may be missing, incomplete or incorrect ;
• Information on any new model under development and/or model to be retired.

If you want to delegate the certification of a model, you may appoint a delegate model owner in the inventory.
                        `)}
                        arrow={true}
                        leaveTouchDelay={300}
                    >
                        <span>
                            <Button variant="contained" className="item valid ">
                                <i className={'fa fa-question'}></i>
                            </Button>
                        </span>
                    </Tooltip>
                </div>
            </Grid>
        }
    </>;
}
ModelList.propTypes = {
    /** Match les paramètres du Router */
    match: PropTypes.object,
    modelCertificationCampaign: PropTypes.shape({
        id: PropTypes.number,
        '@id': PropTypes.string,
        '@context': PropTypes.string,
        troublesEntities: PropTypes.array,
    }),
    id: PropTypes.any,
    fromMrmList: PropTypes.bool,
};

const styles = {
    blockHeightStyle: {
        paddingBottom: 5,
        marginBottom: 35,
    },
    headerBlock: {
        margin: 15,
    },
    title: {
        color: 'white',
        padding: 10,
    },
    gridPaper: {
        marginTop: 15,
        marginBottom: 15,
        height: '100%',
    },
    gridBody: {
        padding: 15,
        paddingTop: 0,
        color: 'black',
        fontSize: 18,
        textAlign: 'center',
    },
    mrDetailBtn: {
        margin: 1,
    },
    footerActions: {
        margin: 15,
        marginBottom: 30
    },
    checkboxes: {
        justifyContent: 'center',
    },
    checkboxHeader: {
        color: 'white',
        fontWeight: 'bold'
    },
    comment: {
        minHeight: 100,
        marginTop: 15,
        marginBottom: 15
    },
    iconWithHand: {
        cursor: "pointer",
    }
};

export default ModelList;// observer(ModelList);
