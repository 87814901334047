import React from "react";
import _ from "lodash";
import { decorate, observable, action } from "mobx/lib/mobx";
import ApiResourceStore from "../../Store/APIResourceStore";
import RouteStore, { Route } from "../../Store/RouteStore";
import Http from "../Http";
import { ResourceList } from "./Components/ResourceList/ResourceList";
import {isFieldEmpty, ResourceEdit} from "./Components/ResourceEdit/ResourceEdit";
import { ResourceDetail } from "./Components/ResourceDetail/ResourceDetail";
import FieldProviderStore from "./FieldProviders/__FieldProviderStore";
import Modal from "../Modal";
import { ResourceDelete } from "./Components/ResourceDelete/ResourceDelete";
import Alert from "../Alert";
import { LocalCurrentEditing } from "./Components/LocalCurrentEditing/LocalCurrentEditing";
import { fieldTypeFormatValidate, hasRightsForField, hasRightsForOperation } from "./Utils";
import User from "../../Services/User/User";
import { createHandlerObject } from "../utils";

/** @module APIResource */

export const CONTEXT_EDIT = 'edit';
export const CONTEXT_ADD = 'add';
export const CONTEXT_CUSTOM = 'custom';
export const CONTEXT_DETAIL = 'detail';
export const CONTEXT_LIST = 'list';
export const CONTEXT_DELETE = 'delete';
export const CONTEXT_ALL = 'all';//CONTEXT_ADD + CONTEXT_EDIT
export const CONTEXT_PRELOAD = 'preload';
export const CONTEXT_FILTER = 'filter';

const DEFAULT_ROWS_PER_PAGE = 25;

/**
 * Settings API
 * @todo on pourrait aussi l'utiliser dans RouteStore ou Component/Root pour la gestion des routeAccessControl
 */
const settingsApi = { ...User, hasRole: User.hasOneRole };

/**
 * @typedef APIResourceField
 * @type {object}
 * @property {string} title - Titre affiché à l'utilisateur
 * @property {string} [type] - Type du champ, sert à déterminer le Provider (default : 'text')
 * @property {boolean|function} [required] - Peut passer (entity, fieldId, context)
 * @property {Function|boolean} [displayCondition] - Peut passer (entity, entity2, key, context, fieldId) {@see ApiResource.filter}
 * @property {Function|boolean|Array} [displayConditions] - Peut passer (entity, entity2, key, context, fieldId) {@see ApiResource.filter}
 * @property {(field: APIResourceField, value: any, entity: any, props: { plainText: boolean }, resource: APIResource) => null|boolean|JSX.Element} [displayList] - Renvoie le composant à afficher dans la vue list, désactivé si null ou false. {@todo il faudrait séparer en displayListConditions et displayListComponent ou "list"}
 * @property {Function} [editConditions] - Peut passer (field, value, entity) {@see ResourceEdit.genField}
 * @property {Object} [params] - Objet de paramètres dépendant du type du champ
 * @property {boolean} [params.allowPaste] - Autorise ou non le collage dans le champ en édition, avec recherche suivant les "params.searchedFields" pour renvoyer les bonnes valeurs
 * @property {string} [params.resource] - Utilisé comme resourceId pour les champs de type entity
 * @property {boolean} [params.multi] - Si le field peut contenir plusieurs valeurs
 * @property {(item: any, entity: any, key: string, context: string, fieldId: string) => boolean} [params.filters] - Filtres pour filterItems()
 * @property {Function} [params.listFilterTransform] - Prend l'input du filtre dans la vue liste et renvoie la valeur transformée à ce même input.
 * @property {boolean} [params.filterMulti] - Décompose la valeur de l'input du filtre en tableau (split " " et ","), si vrai.
 * @property {boolean} [params.orExistsMultiFilterNotAllowed] - Indique qu'on ne peut pas utiliser le filtre orExists sur le champ (si le back n'est pas migré en particulier)
 * @property {Object} [params.mapping] - Uniquement pour MappedProvider.
 * @property {(instanceId: string) => Object} [params.mappingByInstance] - Uniquement pour MappedProvider. Renvoie le mapping pour le instanceId donné. Si ne renvoie rien, on prend field.params.mapping.
 * @property {() => Array<string>} [params.limitStatuses] - Uniquement pour ModelProvider : limite les modelStatus recherchables aux iri renvoyées par la fonction. Default ParameterStore("MODEL_STATUS_ACTIVE").
 * @property {Array<string>} [params.neededFields] - Pour certains providers (ex : entityProvider), permet d'ajouter des champs aux items récupérés, notamment pour filtrer
 * @property {Array<string>} [params.searchedFields] - Si le copier-coller dans le champ est activé (params.allowPaste), utilise les searchedFields pour renvoyer les iri associés et les remplir dans le champ
 * @property {string} [params.sortField] - Si le tri s'effectue sur un champ particulier
 * @property {boolean} [params.links] - Si vrai affiche un lien vers l'entité
 * @property {(entity: any) => str } [params.linkPath] - Permet de modifier l'url utilisée pour le "params.links"
 * @property {boolean} [params.nullIsFalse] - Pour un champ mapped (ou bool - TODO), force le filtre de displayList à considérer "null" comme "false", donc envoie or_exist.. au back 
 * @property {*} [display] - Peut passer (field, value, entity, props, resourceDetailComponent, context), renvoie un composant React
 * @property {*} [edit] - Peut passer (field, value, onChange, entity, routeParams, context), renvoie un composant React
 * @property {(field: APIResourceField, onChange: (value) => void, value: *) => JSX.Element} [filter] - Renvoie un composant custom pour l'input filtre de DataTable.
 * @property {boolean} [listCanSort] - Si false désactive la possibilité de "order by" le champ en question, par défaut autorise.
 * @property {Function} [highlighted] - Cette fonction renvoie true si le champ est surligné. Prototype de fonction : (entity, fieldId, queryParams, queryParamAttribute = 'highlight')
 * @property {string|function} [helperText] - Texte d'aide affiché en Display et en Edit, la fonction prend (entity) en entrée.
 * @property {string|function} [helperTextDisplay] - Texte d'aide affiché en Display, la fonction prend (entity) en entrée.
 * @property {string|function} [helperTextEdit] - Texte d'aide affiché en Edit, la fonction prend (entity) en entrée.
 * @property {string} [canonicalFieldName] - Champ identifiant le fieldId en back (utile pour les champs d'entités liées)
 * @property {boolean} [doNotResetValueWhenNotDisplayed] - N'envoie pas le champ vide au back s'il n'est pas affiché.
 * @property {(field: APIResourceField, fieldValue: any, entity: Object, { plainText: boolean }, resource: APIResource) => JSX.Element|boolean} [displayList]
 * @property {boolean} [displayListIfNotInLayout] - Force l'affichage du champ dans les champs disponibles en vue liste même s'il n'est pas dans le layout.
 * @property {boolean} [issueButton] - Affiche ou non le bouton "+" à côté du select en édition pour remonter les issues sur les valeurs.
 * 
 * @todo continuer à remplir ...
 */

/**
 * @typedef {Object} APIResourceBulkParams
 * @property {APIResource} resource - Ressource des entités sélectionnées dans la table
 * @property {string|JSX.Element} icon - Icone cliquable pour afficher le bulk, peut être soit une string (ex: "search") pour font awesome, soit un element JSX pour MaterialUi (ex: <NetworkCheckIcon style={{fontSize: '0.875rem'}}/>)
 * @property {Array<string>} fields - Champs affichés et modifiables dans le bulk
 * @property {(entity: Object) => boolean} [itemAccessCondition] - Fonction qui renvoie true si l'entité selectionnée est bulkable
 * @property {Array<string>} [itemAccessConditionEntityFields] - Tableau des champs remplis dans `entity` passé à `itemAccessCondition()`
 *                                                               Si vide, toute l'entité récupérée est passée.
 * @property {string} [forbiddenAccessMessage]
 * @property {Array<string>} [bulkValidateRoles] - valide les ids à bulk modifier via validation back pour les rôles donnés, voir les roles dans ParameterStore~BusinessRole
 * @property {(ids: Array<number>, changeFields: Object.<string, any>, resource: APIResource) => void} [callback] - Fonction appelée après que le bulk a été effectué, récupère les ids modifiés et les champs / valeurs modifiés ainsi que la resource après refresh des données
 */

/**
 * - @todo Créer un vrai composant associé pour gérer les boutons d'action de bas de page
 * - @fixme On utilise ce format dans ResourceList directement, mais aussi pour ResourceEdit mais cette fois dans 
 *   {@see Utils.renderButton} qui peut être un peu différent (par ex : links/link n'est pas tjs valable). Il faut uniformiser.
 * 
 * @typedef {Object} AdditionalActionButton
 * @property {({ resource: APIResource, index: number, operation: string}) => any} [onClick] - ou "to", ou "link", ou "links", l'un des 4 est nécessaire
 * @property {Object} [style]
 * @property {string} [to] - {@see "onClick"}
 * @property {Array<string>} [links] - {@see "onClick"}
 * @property {string} [link] - {@see "onClick"}
 * @property {string} [icon] - {@fixme } ATTENTION si "onClick" alors doit pas contenir "fa-", sinon oui (eg: "fa-plus") 
 * @property {*} [tooltip] - string ou JSX ?
 * @property {*} [label] - string ou JSX.Element ?
 * @property {string} [class]
 * @property {string} [className]
 */

/**
 * Paramètres communs à toutes les opérations de APIResource
 * 
 * @typedef {Object} APIResourceOperationCommon
 * @property {Array<string>} [fields] - Tableau des "fieldId" des champs à afficher
 * @property {'edit'|'detail'|'list'|(resource: APIResource, item: any, urlParams: any) => string} [postSaveRedirect] - Nom de la vue, ou fonction qui renvoie l'url, défaut = 'detail' 
 * @property {import("../../Store/RouteStore").RouteParams['accessControl']} [routeAccessControl] - {@fixme } En fait est aussi utilisé par hasRole de {@see Utils.hasRightsForOperation()} donc le format n'est pas toujours lié à RouteStore qui peut aussi prendre une fonction mais pas dans tous nos cas ici. (voir {@see settingsApi} plus haut)
 * @property {(entity: Object) => boolean} [itemAccessCondition] - Utilisé par {@see Utils.hasRightsForOperation()}
 * @property {*} [header] - TODO
 */

/**
 * Paramètres commun aux opérations "edit" et "add".
 * 
 * @typedef {Object} APIResourceOperationEditCommon
 * @property {boolean} fieldsDefinition - Vrai si "fields" est rempli, faux si on a pris par défaut tous les champs
 * @property {(entity: Object, resource: APIResource, context: any, routeParams: Object) => void} [onInit] - "context" contient le ResourceEdit
 * @property {({entity: Object, resource: APIResource, context: any, routeParams: Object}) => void} [onLoad] - Appel déclenché avant le chargement de la page d'édition avec l'entité déjà en mémoire dans ApiResource. "context" contient le ResourceEdit.
 * @property {(field: string, oldValue: any, value: any, entity: Object, resource: APIResource, resourceEditComponent: any) => void} [onUpdate] - Déclenchée à chaque handleChange, partagée entre Insert et Edit si l'un des deux ne possède pas de fonction onUpdate
 * @property {(entity: Object, resource: APIResource, resourceEditComponent: any, urlSearchParams: URLSearchParams) => Array<AdditionalActionButton>} [additionalActionButtons] - Boutons de bas de page {@see Utils.renderAdditionalActionButtons()}. ATTENTION format différent pour ResourceList
 * @property {(entity: Object, resource: APIResource, resourceEditComponent: any) => Array<AdditionalActionButton>} [additionalLinkButton] - Boutons "liens" en bas de page {@see Utils.renderAdditionalLinkButton()}.
 * @property {Array<string>} [fieldsSaveWhitelist] - Champs whitelistés à envoyer lors de la validation front et sauvegarde en back (en particulier les champs définis dans onInit, onUpdate ou les actions).
 *                                                   * NB: propriété partagée entre Insert et Edit si l'un des deux n'en a pas. 
 *                                                   * NB: ne prend pas la précédence sur doNotResetValueWhenNotDisplayed. 
 *                                                   * NB: modifie l'entité en cours avant validation, {@see ResourceEdit.cleanEntity()}.
 */

/**
 * Paramètres custom à l'opération "list".
 * 
 * @typedef {Object} APIResourceOperationListCustom
 * @property {*} [filters] - {@deprecated} 
 * @property {Object.<string, string|number|boolean|Array<string|number|boolean>>} [permanentFilters] - Ajoutés à currentList.permanentFilters, {@see APIResource.apiGetCollection} {@todo revoir type}
 * @property {Object.<string, string|number|boolean|Array<string|number|boolean>>} [defaultFilters] - Ajoutés à currentList.filters : comme si un filtre avait déjà été tapé par l'utilisateur, {@see APIResource.apiGetCollection}
 * @property {*} [defaultActionButtons] - {@fixme } devrait être un boolean, sert à afficher la colonne "actions" dans DataTable, renommer en showDefaultActionButtons
 * @property {(resource: APIResource) => Array<AdditionalActionButton>|Array<AdditionalActionButton>} [additionalActionButtons] - Boutons de bas de page {@see DataTable.renderAdditionalButtons()}. ATTENTION format différent pour ResourceEdit 
 * @property {({entity: any, resource: APIResource}) => Array<AdditionalActionButton>} [additionalListActionButtons] - Boutons d'action supplémentaires à afficher dans la colonne des actions. Voir {@see Utils.renderAdditionalListActionButtons()}.
 * @property {boolean} showDefaultAddButton - Affiche le bouton d'insertion par défaut (! n'écrase pas les droits), défaut = vrai
 * @property {*} [insertButton] - TODO Ecrase le path et le tooltip du bouton d'insertion par défaut
 * @property {*} [skipButtonAccessCheck] - TODO
 * @property {string} [targetDetailResource] - resource cible au double clic sur un élément de la liste, link : "/resource/ + targetDetailResource + /{id}/detail"
 * @property {*} [title] - TODO
 * @property {Array<string>} [neededFields] - Champs nécessaires au bon fonctionnement, seront récupérés obligatoirement durant les apiGetCollection
 * @property {boolean} [doNotDisplayTitle] - if true, the list will display an empty title
 */

/**
 * Paramètres custom à l'opération "add".
 * 
 * @typedef {Object} APIResourceOperationInsertCustom
 * @property {'edit'|'detail'|'list'|(resource: APIResource, item: any, urlParams: any) => string} [postSaveRedirect] - Nom de la vue, ou fonction qui renvoie l'url, défaut = "edit"
 * @property {string} tooltipText - Texte affiché en tooltip sur le bouton de "list" associé à l'opération d'insertion, défaut = "Add"
 * @property {({resource: APIResource, event: any}) => void} insertButtonAction - Action custom éventuelle à réaliser au clic sur le bouton de "list" associé à l'opération d'insertion
 */

/**
 * Paramètres custom à l'opération "edit".
 * 
 * @typedef {Object} APIResourceOperationEditCustom
 * @property {'edit'|'detail'|'list'|(resource: APIResource, item: any, urlParams: any) => string} [postSaveRedirect] - Nom de la vue, ou fonction qui renvoie l'url, défaut = "detail"
 */

/**
 * @typedef {APIResourceOperationCommon & APIResourceOperationListCustom} APIResourceOperationList
 * @typedef {APIResourceOperationCommon & APIResourceOperationEditCommon & APIResourceOperationInsertCustom} APIResourceOperationInsert
 * @typedef {APIResourceOperationCommon & APIResourceOperationEditCommon & APIResourceOperationEditCustom} APIResourceOperationEdit
 */

/**
 * @class
 * @constructor
 */
export class APIResource {
    /**
     * Constructeur
     *
     * The ResourceDetail and the resource edit component will load the api rules for entity based on it's ID at the componentWillMount step.
     * See getAclForDetailFields andgetAclForEditFields for more details
     *
     * @typedef {Object} APIResourceParams
     * @property {*} [instanceId] La plupart du temps on n'utilise pas instanceId, pour mutualiser le cache principalement.
     *                                   Dans ce cas new APIResource ne crée pas de nouvelle instance mais retourne l'instance existante.
     *                                   Mais lorsqu'on ne filtre pas ou ne pagine pas de la même manière que l'instance existante,
     *                                   alors on précise un instance ID qui va créer une nouvelle instance d'API resource.
     * @property {string} id - stocké dans this.resourceId
     * @property {*} [name]
     * @property {string} [canonicalName]
     * @property {string} icon - Icone pour le menu ! inutilisé pour l'instant !
     * @property {*} [fieldForTitle]
     * @property {(entity: any, resource: APIResource) => any} [componentForTitle] - Renvoie un composant React, stocké dans AppBarStore.title. Voir ResourceDetail
     * @property {*} [breadcrumbName] - Nom affiché dans le breadcrumb (en particulier si le componentForTitle est un Breadcrumb)
     * @property {string} [fieldsAclLocation] - the url where the resource can go fetch the display rules for each field.
     *     must be provided as a string. Example => `annotations/model`
     * @property {*} [aclFields] - TODO
     * @property {*} [endpoints] - TODO
     * @property {*} [context] - TODO
     *
     * @param {APIResourceParams} params
     */
    constructor(params) {
        if(!params.context || params.context !== CONTEXT_PRELOAD){
            if (!params.instanceId && ApiResourceStore.resources[params.id]) {
                return ApiResourceStore.resources[params.id];
            } else if (params.instanceId && ApiResourceStore.resources[params.instanceId]) {
                return ApiResourceStore.resources[params.instanceId];
            }
        }
        //Si preload exist alors clone de preload puis init
        this.init(params);
    }

    /**
     * Méthode d'initialisation de la Resource
     * @param {APIResourceParams} params
     */
    init(params) {
        this.instanceId = params.instanceId ? params.instanceId : params.id;
        this.resourceId = params.id;
        this.name = params.name || params?.id?.replace('_', " ").replace(/s$/, "");
        this.canonicalName = params.canonicalName ? params.canonicalName : this.name;
        this.icon = params.icon;
        this.fieldForTitle = params.fieldForTitle ? params.fieldForTitle : null;
        this.componentForTitle = params.componentForTitle ? params.componentForTitle : null;
        this.breadcrumbName = params.breadcrumbName || this.name;
        this.keepQueryParams = params.keepQueryParams ? params.keepQueryParams : null;
        this.fieldsAclLocation = params.fieldsAclLocation ? params.fieldsAclLocation : null;
        this.aclFields = params.aclFields ? params.aclFields : null;
        /** @type {Object.<string, APIResourceField>} */
        this.fields = {};
        this.validation = null;
        /** @type {{ v: Object.<string, { rows: Array<{ panels: Object.<string, { cols: number, fields: Array<string> }>}>}}} - Mise en page du Detail*/
        this.layout = null;
        /** @type {{add: APIResourceOperationInsert, edit: APIResourceOperationEdit, list: APIResourceOperationList, detail: any, delete: boolean}} - Attention pour "delete" il y a d'autres champs, mais l'opération n'est pas normalisée */
        this.operations = {};
        this.items = [{ id: null, __init: false }];
        this.totalItems = 0;
        /** 
         * @typedef {Object} APIResourceCurrentList
         * @property {Array} [items]
         * @property {number} [page] - default 1
         * @property {number} [rowsPerPage] - default 25
         * @property {Object.<string, string|number|boolean|Array<string|number|boolean>>} [filters] - comme si un filtre avait déjà été tapé par l'utilisateur, utilisé dans {@see APIResource.apiGetCollection}
         * @property {Object} [permanentFilters] - Utilisés dans chaque appel à apiGetCollection
         * @property {date} [lastUpdate] - default null,
         * @property {Array<string>} [initialDisplayFields] - FieldNames TODO
         * @property {Array<string>} [displayFields] - FieldNames TODO
         * @property {Array} [bulkActions] - TODO
         * @property {*} [lastUsedEndpoint] - default null TODO
         * @property {Object.<string, 'asc'|'desc'>} [order] - Order by pour la clé donnée
         */ 
         /** @type {APIResourceCurrentList} */
        this.currentList = {
            items: [],
            page: 1,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            filters: {},
            permanentFilters: {},
            lastUpdate: null,
            initialDisplayFields: [],
            displayFields: [],
            bulkActions: [],
            lastUsedEndpoint: null,
        };
        this.currentRequestList = null;
        this.currentRequestGetOne = null;
        this.endpoints = params.endpoints || null;
        this.context = params.context || null;
        /** @type {{save: Function, handleChange: Function}} - Rempli par ResourceEdit TODO */
        this.currentEditingActions = null;

        this.setItems(params.items);

        if(params)
        ApiResourceStore.resources[this.instanceId] = this;
    }

    isItemsInit() {
        return this.items.length && this.items[0].__init !== false;
    }

    /**
     * Permets de définir la configuration des champs de la ressource
     * @param {Object.<string, APIResourceField>} fields - Champs disponibles {@link APIResourceField}
     *                                                    NB: la clé est le "fieldId"
     * @memberof APIResource
     * 
     * @description fields example :
     *      {
     *          fieldName1: {title: 'MRM ID'},
     *          fieldName2: {
     *              title: 'Options',
     *              required: true,
     *              display: (field, value, entity, props, resourceDetailComponent, context) => <EntityDisplay resourceId="options" resourceLabel="name" flat={true} value={value}/>,
     *              edit: (field, value, onChange, entity, routeParams, context) => <EntitySelect resourceId="options" resourceLabel="name" value={value} onChange={onChange} clearable={true} />
     *              filter: (value, onChange) => <EntitySelect resourceId="options" resourceLabel="name" value={value} onChange={onChange} clearable={true} />
     *              displayCondition: predicate,
     *              displayConditions: [ …predicates ],
     *              rights: {
     *                all: predicate (String[] |  function (entity) => Boolean),
     *                display: predicate (String[] |  function (entity) => Boolean),
     *                detail: predicate (String[] |  function (entity) => Boolean),
     *                addOrEdit: predicate (String[] |  function (entity) => Boolean),
     *                add: predicate (String[] |  function (entity) => Boolean),
     *                edit: predicate (String[] |  function (entity) => Boolean),
     *              }
     *          }
     *      }
     *
     *      Key 'display' est utilisée pour rendre le champs dans les vues devant l'afficher (listes, details)
     *      Key 'edit' est utilisée dans les listes permettant la saisie du champs (filtres dans les listes, édition)
     *
     *
     * avec predicates qui est Array|Collection|null|string|function
     * un élément ou bien un tableau de ce type d'éléments :
     *      - Object {field: value}, retourne tous les items dont le field correspond à la value
     *      - Object {field: function(value, entity, item)}, retourne tous les items dont le field correspond au retour de la function
     *      - String "field" : retourne tous les items possédant ce field s'il n'est pas faux ou nul
     *      - Function (item, entity, key) => … : retourne tous les items pour lesquels la fonction retourne vrai
     *      - null => est ignoré
     * avec entity facultatif : valeur de l'argument entity dans les fonctions de predicats
     *
     * Exemples:
     * - { type:'text', displayCondition: 'name' },
     * - { type:'text', displayConditions: [ {name: v => v.match(/A/)}, {initialID: 'B'}, model => model.systemID == 'C'] },
     * - { type: 'parameter', params: { type:3, filter: { label: 'DAV', type: 3 }} },
     * - { type: 'parameter', params: { type:3, filters: [{ label: (value, entity, item) => value.match(/^D/) }] },
     * - { type: 'entity', params: { filters: (item, entity, key) => (item.label || '').match(/V$/) },
     */
    setFields(fields) {
        this.fields = {};
        for (let fieldId in fields) {
            fields[fieldId].id = fieldId;
            if (
                fields[fieldId].type &&
                FieldProviderStore[fields[fieldId].type] &&
                FieldProviderStore[fields[fieldId].type].getValidationPattern
            ) {
                fields[fieldId].validationPattern = FieldProviderStore[fields[fieldId].type].getValidationPattern();
            }

            // Si helperText est rempli, on le oopie dans helperTextDisplay et helperTextEdit s'ils sont vides.
            if (fields[fieldId].helperText){
                fields[fieldId].helperTextDisplay = fields[fieldId].helperTextDisplay || fields[fieldId].helperText;
                fields[fieldId].helperTextEdit = fields[fieldId].helperTextEdit || fields[fieldId].helperText;
            }

            this.fields[fieldId] = fields[fieldId];
        }
        if (!this.currentList.displayFields.length || this.currentList.displayFields.length !== Object.keys(fields).length) {
            this.currentList.displayFields = Object.keys(fields);
        }

        return this;
    }

    /**
     * Permet de fournir une callback de validation qui sera appelée avant la sauvegarde d'un élément de la ressource.
     *
     * La callback fournie prend en paramètre l'entité en cours d'édition et retourne :
     *    - true si la saisie est valide
     *    - un array d'erreurs de type [{field: 'Nom du champ', detail: 'Description de l'erreur rencontrée'}] si la saisie contient des erreurs
     *    - une promesse dont le resolve est appelé avec un paramètre unique qui vaut true si la saisie est valide ou un Array d'erreurs tel que décrit à la ligne ci-dessus.
     *
     * @param validationCallback
     * @returns {APIResource}
     */
    setValidation(validationCallback) {
        this.validation = validationCallback;
        return this;
    }

    /**
     * Définit le layout (tabs, panels, fields) par défaut qui sera utilisé pour les rendus des vues Insert/Edit/Detail
     * @param layout
     *
     * @description layout definition example :
     *    {
     *        tabs: {
     *             'Personal Informations': {
     *                 rows: [
     *                     {
     *                         panels: {
     *                             'Identity': {
     *                                 cols: 4,
     *                                 fields: ['firstName', 'lastName']
     *                             },
     *                             'Contacts': {
     *                                 cols: 4,
     *                                 fields: ['email']
     *                             },
     *                             'Permissions': {
     *                                 cols: 4,
     *                                 fields: ['roles']
     *                             }
     *                         }
     *                     }
     *                 ]
     *             }
     *         }
     *    }
     */
    setLayout(layout) {
        this.layout = layout;
        return this;
    }

    /**
     * Génère l'objet Layout pour une opération (insert/edit/detail) selon les champs à afficher
     * @param operationId
     * @returns {null|{}}
     */
    genOperationLayout(operationId) {
     console.log('genOperationLayout null', operationId, !this.operations[operationId], !this.layout)
        if (!this.operations[operationId] || !this.layout) {
            return null;
        }
        let operationFields = this.operations[operationId].fields;
        let operationLayout = JSON.parse(JSON.stringify(this.layout));
        let viewTabs = {};

        for (let currentTab in operationLayout.tabs) {
            if (
                !User.profile.env ||
                (operationLayout.tabs[currentTab].env && operationLayout.tabs[currentTab].env != User.profile.env)
            ) {
                continue;
            }
            let currentTabRows = [];
            for (let currentRow in operationLayout.tabs[currentTab].rows) {
                let currentRowPanels = {};
                for (let currentPanel in operationLayout.tabs[currentTab].rows[currentRow].panels) {
                    let currentPanelFields = [];
                    for (let currentField in operationLayout.tabs[currentTab].rows[currentRow].panels[currentPanel]
                        .fields) {
                        if (
                            hasRightsForField(currentField, {
                                operationId,
                                hasRole: User.hasOneRole,
                            })
                        ) {
                            for (let operationField in operationFields) {
                                if (
                                    operationLayout.tabs[currentTab].rows[currentRow].panels[currentPanel].fields[
                                        currentField
                                    ] === operationFields[operationField]
                                ) {
                                    currentPanelFields.push(operationFields[operationField]);
                                }
                            }
                        }
                    }
                    operationLayout.tabs[currentTab].rows[currentRow].panels[currentPanel].fields = currentPanelFields;
                    if (operationLayout.tabs[currentTab].rows[currentRow].panels[currentPanel].fields.length) {
                        currentRowPanels[currentPanel] =
                            operationLayout.tabs[currentTab].rows[currentRow].panels[currentPanel];
                    }
                }
                operationLayout.tabs[currentTab].rows[currentRow].panels = currentRowPanels;
                if (Object.keys(operationLayout.tabs[currentTab].rows[currentRow].panels).length) {
                    currentTabRows.push(operationLayout.tabs[currentTab].rows[currentRow]);
                }
            }
            operationLayout.tabs[currentTab].rows = currentTabRows;
            if (this.layout.tabs[currentTab].displayConditions) {
                operationLayout.tabs[currentTab].displayConditions = this.layout.tabs[currentTab].displayConditions;
            }
            if (operationLayout.tabs[currentTab].rows.length) {
                viewTabs[currentTab] = operationLayout.tabs[currentTab];
            }
        }
        operationLayout.tabs = viewTabs;

        return operationLayout;
    }

    FieldIsInLayout(fieldId) {
        if (!this.layout || fieldId.indexOf('.') !== -1) {
            return true;
        }
        let layout = this.layout;
        for (let tab in layout.tabs) {
            for (let row in layout.tabs[tab].rows) {
                for (let panel in layout.tabs[tab].rows[row].panels) {
                    for (let i in layout.tabs[tab].rows[row].panels[panel].fields) {
                        if (layout.tabs[tab].rows[row].panels[panel].fields[i] === fieldId) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    /**
     * Fournit l'index d'un onglet dans le layout selon le nom de l'onglet et l'opération passés en paramètres
     * 
     * @param {'detail'|'edit'|'add'} operation
     * @param {string} tabName
     * @param {Object.<string, number>} [nbFieldsPerTab]
     * @returns {number} Index de l'onglet dans le layout
     */
    getTabId(operation, tabName, nbFieldsPerTab) {
        let layout = this.genOperationLayout(operation);

        if(!tabName){
            return this.getDefaultTabId(nbFieldsPerTab, layout.tabs);
        }

        // On veut tester les onglets en minuscule :
        const lcNbFieldsPerTab = nbFieldsPerTab
            ? Object.entries(nbFieldsPerTab).reduce((acc, [k, v]) => {acc[k.toLowerCase()] = v; return acc;}, {})
            : null;

        let index = 0;
        if(
            !lcNbFieldsPerTab
            || (lcNbFieldsPerTab[tabName.toLowerCase()] && lcNbFieldsPerTab[tabName.toLowerCase()] > 0)
        ){
            for (let tabKey in layout.tabs) {
                if (tabName.toLowerCase() === tabKey.toLowerCase()) {
                    return index;
                }
                index++;
            }
        }

        return this.getDefaultTabId(nbFieldsPerTab, layout.tabs);
    }

    /**
     * Tab à afficher par défaut, vérifie que des champs sont présents
     *
     * @param nbFieldsPerTab
     * @param layout
     * @returns {null|number}
     */
    getDefaultTabId(nbFieldsPerTab, layout){
        if(!nbFieldsPerTab){
            return 0;
        }
        let layoutTabs = Object.keys(layout);
        let tabIndexWithFields = null;
        let tabWithFields =  null;
        layoutTabs.some((tabLabel, tabIndex) => {
            if(nbFieldsPerTab[tabLabel] > 0){
                tabIndexWithFields = tabIndex;
                tabWithFields = tabLabel;
                return tabLabel;
            }
        });
        if(
            tabWithFields
            && layout[tabWithFields]
        ){
            return tabIndexWithFields;
        }

        return 0;
    }

    /**
     * Création de la route racine du CRUD de la ressource si elle n'existe pas
     */
    genCrudRootRoute() {
        if (!RouteStore.routes['resource_' + this.instanceId]) {
            RouteStore.routes['resource_' + this.instanceId] = new Route({
                path: '/resource/' + this.instanceId,
                component: null,
            });
        }
    }

    /**
     * Génération de la vue de listing de la Resource
     * 
     * @typedef {Object} GenListViewParams
     * @property {import("../../Store/RouteStore").RouteParams['menuItem']} [menuItem] - voir src/Store/RouteStore.js pour le format
     * @property {import("../../Store/RouteStore").RouteParams['component']} [component] - voir src/Store/RouteStore.js pour le format
     * @property {boolean} [preLoad] - Déclenche l'appel à apiGetCollection
     * 
     * @param {APIResourceOperationList & GenListViewParams} options
     * @returns {APIResource}
     */
    genListView(options = {}) {
        //Création de la route racine du CRUD de la ressource si elle n'existe pas
        this.genCrudRootRoute();

        // Ajout de l'entrée de menu de la route racine de la ressource si demandé.
        // Sera rempli par les éléments de options.menuItem plus bas.
        if (options.menuItem) {
            RouteStore.routes['resource_' + this.instanceId].menuItem = {
                title: this.name,
                icon: this.icon,
            };
        }

        // Ajout de la configuration de l'opération au registre de la resource
        this.operations.list = {
            fields: options.fields ? options.fields : Object.keys(this.fields),
            filters: {},
            permanentFilters: {},
            defaultFilters: options.defaultFilters || {},
            defaultActionButtons: options.defaultActionButtons ?? false,
            showDefaultAddButton: options.showDefaultAddButton ?? true,
            routeAccessControl: options.routeAccessControl,
            itemAccessCondition: options.itemAccessCondition ? options.itemAccessCondition : null,
            additionalActionButtons: options.additionalActionButtons || null,
            additionalListActionButtons: options.additionalListActionButtons,
            header: options.header || null,
            insertButton: options.insertButton || null,
            skipButtonAccessCheck: options.skipButtonAccessCheck || null,
            targetDetailResource: options.targetDetailResource || null,
            title: options.title || null,
            neededFields: options.neededFields || [],
            _debugNoLimitProperties: options._debugNoLimitProperties || false,
            doNotDisplayTitle: options.doNotDisplayTitle || null
        };

        // Définition des filtres permanents
        if (options.permanentFilters) {
            Object.assign(this.currentList.permanentFilters, options.permanentFilters);
        }

        // Définition des filtres par défaut
        if (options.defaultFilters) {
            Object.assign(this.currentList.filters, options.defaultFilters);
        }

        // Définition des champs à afficher par défaut
        if (options.fields) {
            this.currentList.displayFields = options.fields ? options.fields : this.currentList.displayFields;
            this.currentList.initialDisplayFields = this.currentList.displayFields.slice();
        }

        // Restauration de la config stockée coté serveur (notament les colonnes)
        // This.restoreRemoteConfig();

        // Définition du composant par défaut pour l'opération list
        options.component = options.component
            ? options.component(this)
            : (props) => (
                  <ResourceList
                      resource={this}
                      {...props}
                      settingsApi={settingsApi}
                      additionalListActionButtons={options.additionalListActionButtons}
                  />
              );

        // Préchargement de la liste si demandé
        if (options.preLoad) {
            this.apiGetCollection({ context: 'genListView', filters: this.currentList.filters });
        }
        const path = '/resource/' + this.instanceId + '/list';
        // Création de la route de l'opération
        RouteStore.routes['resource_' + this.instanceId].children['resource_' + this.instanceId + '_list'] = new Route({
            path,
            component: options.component,
            accessControl: options.routeAccessControl,
            menuItem: options.menuItem
                ? {
                      title: options.menuItem.title,
                      icon: options.menuItem.icon,
                  }
                : null,
        });
        return this;
    }

    /**
     * Génération de la vue d'insertion de la Resource
     *
     * @typedef {Object} GenInsertViewParams
     * @property {*} [operationTooltipText] - the tooltip text of the operation associated to the view, defaut "empty"
     * @property {import("../../Store/RouteStore").RouteParams['menuItem']} [menuItem] - voir src/Store/RouteStore.js pour le format
     * @property {import("../../Store/RouteStore").RouteParams['component']} [component] - voir src/Store/RouteStore.js pour le format
     * 
     * @param {APIResourceOperationInsert & GenInsertViewParams} options
     * @returns {APIResource}
     */
    genInsertView(options = {}) {
        //Création de la route racine du CRUD de la ressource si elle n'existe pas
        this.genCrudRootRoute();

        // Ajout de la configuration de l'opération au registre de la resource
        const allFields = Object.keys(this.fields);
        this.operations.add = {
            fields: options.fields ? options.fields : allFields,
            fieldsDefinition: !!options.fields,
            onInit: options.onInit ? options.onInit : null,
            onLoad: options.onLoad ? options.onLoad : null,
            onUpdate: options.onUpdate ? options.onUpdate : null,
            postSaveRedirect: options.postSaveRedirect || CONTEXT_EDIT,
            insertButtonAction: options.insertButtonAction ? options.insertButtonAction : null,
            routeAccessControl: options.routeAccessControl,
            itemAccessCondition: options.itemAccessCondition ? options.itemAccessCondition : null,
            additionalActionButtons: options.additionalActionButtons || null,
            additionalLinkButton: options.additionalLinkButton || null,
            header: options.header || null,
            tooltipText: options.operationTooltipText,
            fieldsSaveWhitelist: options.fieldsSaveWhitelist || [],
        };
        if (this.operations[CONTEXT_EDIT] && !this.operations[CONTEXT_EDIT].onUpdate && options.onUpdate) {
            this.operations[CONTEXT_EDIT].onUpdate = options.onUpdate;
        }
        if (!options.onUpdate && this.operations[CONTEXT_EDIT] && this.operations[CONTEXT_EDIT].onUpdate) {
            this.operations.add.onUpdate = this.operations[CONTEXT_EDIT].onUpdate;
        }

        if (this.operations[CONTEXT_EDIT] && !this.operations[CONTEXT_EDIT].fieldsSaveWhitelist?.length && options.fieldsSaveWhitelist) {
            this.operations[CONTEXT_EDIT].fieldsSaveWhitelist = options.fieldsSaveWhitelist;
        }
        if (!options.fieldsSaveWhitelist && this.operations[CONTEXT_EDIT] && this.operations[CONTEXT_EDIT].fieldsSaveWhitelist.length) {
            this.operations.add.fieldsSaveWhitelist = this.operations[CONTEXT_EDIT].fieldsSaveWhitelist;
        }

        // Vérification de l'existence des champs
        const unknownFields = this.operations.add.fields.filter((f) => !allFields.includes(f));
        if (unknownFields.length) {
            throw new Error('Unknown field: ' + unknownFields.join(', '));
        }

        // Définition du composant par défaut
        options.component = options.component
            ? options.component(this)
            : (props) => <ResourceEdit context={CONTEXT_ADD} resource={this} {...props} settingsApi={settingsApi} />;

        // Création de la route de l'opération
        const path = '/resource/' + this.instanceId + '/add';
        RouteStore.routes['resource_' + this.instanceId].children['resource_' + this.instanceId + '_add'] = new Route({
            path,
            component: options.component,
            accessControl: options.routeAccessControl,
            menuItem: options.menuItem
                ? {
                      title: options.menuItem.title,
                      icon: options.menuItem.icon,
                  }
                : null,
        });

        // Création des routes additionnelles de l'action si fournies
        if (options.additionalRoutes) {
            options.additionalRoutes.forEach((route, index) => {
                RouteStore.routes['resource_' + this.instanceId].children[
                    'resource_' + this.instanceId + '_add_' + index
                ] = new Route({
                    path: route,
                    accessControl: options.routeAccessControl,
                    component: options.component,
                });
            });
        }

        return this;
    }

    /**
     * Génération de la vue d'édition de la Resource
     *
     * @typedef {Object} GenEditViewParams
     * @property {import("../../Store/RouteStore").RouteParams['component']} [component] - voir src/Store/RouteStore.js pour le format
     * 
     * @param {APIResourceOperationEdit & GenEditViewParams} options
     * @returns {APIResource}
     */
    genEditView(options = {}) {
        //Création de la route racine du CRUD de la ressource si elle n'existe pas
        this.genCrudRootRoute();

        // Ajout de la configuration de l'opération au registre de la resource
        const allFields = Object.keys(this.fields);
        this.operations[CONTEXT_EDIT] = {
            fields: options.fields ? options.fields : allFields,
            fieldsDefinition: !!options.fields,
            onInit: options.onInit ? options.onInit : null,
            onLoad: options.onLoad ? options.onLoad : null,
            onUpdate: options.onUpdate ? options.onUpdate : null,
            postSaveRedirect: options.postSaveRedirect || CONTEXT_DETAIL,
            itemAccessCondition: options.itemAccessCondition ? options.itemAccessCondition : null,
            additionalActionButtons: options.additionalActionButtons || null,
            additionalLinkButton: options.additionalLinkButton || null,
            header: options.header || null,
            routeAccessControl: options.routeAccessControl,
            fieldsSaveWhitelist: options.fieldsSaveWhitelist || [],
        };
        if (this.operations.add && !this.operations.add.onUpdate && options.onUpdate) {
            this.operations.add.onUpdate = options.onUpdate;
        }
        if (!options.onUpdate && this.operations.add && this.operations.add.onUpdate) {
            this.operations[CONTEXT_EDIT].onUpdate = this.operations.add.onUpdate;
        }
        if (this.operations.add && !this.operations.add.fieldsSaveWhitelist.length && options.fieldsSaveWhitelist) {
            this.operations.add.fieldsSaveWhitelist = options.fieldsSaveWhitelist;
        }
        if (!options.fieldsSaveWhitelist && this.operations.add && this.operations.add.fieldsSaveWhitelist.length) {
            this.operations[CONTEXT_EDIT].fieldsSaveWhitelist = this.operations.add.fieldsSaveWhitelist;
        }

        const unknownFields = this.operations[CONTEXT_EDIT].fields.filter((f) => !allFields.includes(f));
        if (unknownFields.length) {
            throw new Error('Unknown field: ' + unknownFields.join(', '));
        }

        // Définition du composant par défaut
        let component = options.component
            ? options.component(this)
            : (props) => <ResourceEdit context={CONTEXT_EDIT} resource={this} {...props} settingsApi={settingsApi} key={'resource_edit'}/>;

        // Création de la route de l'opération
        const path = '/resource/' + this.instanceId + '/:id/edit';
        RouteStore.routes['resource_' + this.instanceId].children['resource_' + this.instanceId + '_edit'] = new Route({
            path,
            accessControl: options.routeAccessControl,
            component: component,
        });

        return this;
    }

    /**
     * Retourne une fonction qui ouvre une modale basée sur la Resource lorsqu'elle est appelée
     *
     * @param options { context?: 'add|edit', component?} : options passées au ResourceEdit, ou bien component à créer dans la modale
     *      - modalStyle le style css de la modale
     *      - modalBodyStyle le style css de du body de modale
     * @returns {function(params, callback)} :
     *      - params correspond aux routeParams qui aurait été passés via un appel standard,
     *      - callback l'action à faire lorsque l'appel API de sauvegarde a été réalisé
     *
     * Usage:
     * <Button onClick={() => APIResourceStore.resources.actions.openModal(options)({mainId: entity.id, id: value.id}, () => {
     *      APIResourceStore.resources.workflows.apiGetOne(entity.id, true); // rafraîchit le composant principal
     *   })}>
     *
     */
    openModal(options = {}) {
        return (params, callback) => {
            // Définition du composant par défaut
            let component = options.component ? (
                options.component(this)
            ) : (
                <ResourceEdit
                    className={options.className}
                    style={options.style}
                    context={options.context || CONTEXT_EDIT}
                    resource={this}
                    settingsApi={settingsApi}
                    match={{ params }}
                    value={{}}
                    onSuccess={() => {
                        modal.close();
                        callback && callback();
                    }}
                    display="modal"
                />
            );

            let modal = Modal.open({
                title: this.name + (params.id ? ': #' + params.id : ': New'),
                style: options.modalStyle,
                content: component,
            });
        };
    }

    /**
     * Génération de la vue de détail de la Resource
     *
     * @param {Object} options
     * @param {Array<string>} options.fields - ['nomSystemChamp1', 'nomSystemChamp2']
     * @param {function} options.onInit - `({entity, resource, $context, }) => {entity.nomSystemChamp1 = 'Ma valeur'}`
     * @param {function} options.onUpdate - `(fieldId, oldValue, newValue, entity) => {entity.nomSystemChamp1 = 'Ma valeur';}`
     * @param {import("../../Store/RouteStore").RouteParams['accessControl']} options.routeAccessControl - voir src/Store/RouteStore.js pour le format
     * @param {array|function} options.itemAccessCondition - array of roles or function.
     *        The array of roles represent the roles that are allowed to DETAIL on this item.
     *        The function is a function that takes in an item entity and will return true if the user has access to the related operation (DETAIL in this case).
     * @returns {APIResource}
     */
    genDetailView(options = {}) {
        //Création de la route racine du CRUD de la ressource si elle n'existe pas
        this.genCrudRootRoute();

        // Ajout de la configuration de l'opération au registre de la resource
        const allFields = Object.keys(this.fields);
        this.operations[CONTEXT_DETAIL] = {
            fields: options.fields ? options.fields : allFields,
            routeAccessControl: options.routeAccessControl,
            itemAccessCondition: options.itemAccessCondition ? options.itemAccessCondition : null,
            additionalActionButtons: options.additionalActionButtons || null,
            additionalLinkButton: options.additionalLinkButton || null,
            header: options.header || null,
            onInit: options.onInit ? options.onInit : null,
            onUpdate: options.onUpdate ? options.onUpdate : null,
        };

        const unknownFields = this.operations[CONTEXT_DETAIL].fields.filter((f) => !allFields.includes(f));
        if (unknownFields.length) {
            throw new Error('Unknown field: ' + unknownFields.join(', '));
        }

        // Définition du composant par défaut
        this.detailComponent = options.component
            ? options.component(this)
            : (props) => <ResourceDetail resource={this} {...props} settingsApi={settingsApi} key={'resource_detail'} />;

        // Création de la route de l'opération
        const path = '/resource/' + this.instanceId + '/:id/detail';
        RouteStore.routes['resource_' + this.instanceId].children[
            'resource_' + this.instanceId + '_detail'
        ] = new Route({
            path,
            accessControl: options.routeAccessControl,
            component: this.detailComponent,
        });

        return this;
    }

    getDetailComponent(){
        return this.detailComponent;
    }

    /**
     * Méthode d'activation de la suppression des ressources du type courant
     * 
     * @param {Object} options
     * @param {*} [options.itemAccessCondition] Predicate, idem que les autres méthodes
     * @param {*} [options.component] Composant à afficher pour la suppression
     * @param {string} [options.returnPath] If null the return path is not treated
     * @param {string} [options.confirmation] Confirmation message
     */
    allowDelete(options = {}) {
        // Ajout de la configuration de l'opération au registre de la resource
        this.operations.delete = true;
        this.operations[CONTEXT_DELETE] = {
            itemAccessCondition: options.itemAccessCondition,
            component: options.component ? options.component : null,
            returnPath: options.returnPath,
            confirmation: options.confirmation,
        };

        return this;
    }

    getFieldRightsForEntity = async (entity, context, routeParams) => {
        if (!this.fieldsAclLocation) {
            return;
        }

        let additionnalFields = {};
        let additionnalFieldsString = '';
        if(this.aclFields !== undefined && this.aclFields !== null && entity !== undefined && entity !== null){
            let properties = this.aclFields(entity, routeParams);
            if(properties){
                additionnalFields = Object.entries(properties);
                additionnalFields.forEach((val, _key) => {
                    additionnalFieldsString += "&" + val[0] + "=" + val[1];
                })
            }
        }

        let p1 = Promise.resolve({}),
            p2 = Promise.resolve({});
        switch (context) {
            case CONTEXT_DETAIL:
                p1 = Http.get(`${this.fieldsAclLocation}?operation=detail&entityId=${entity.id}${additionnalFieldsString}`, { cache: 0 });
                break;
            case CONTEXT_EDIT:
                p2 = Http.get(`${this.fieldsAclLocation}?operation=edit&entityId=${entity.id}${additionnalFieldsString}`, { cache: 0 });
                break;
            case CONTEXT_ALL:
            default:
                p1 = Http.get(`${this.fieldsAclLocation}?operation=detail&entityId=${entity.id}${additionnalFieldsString}`, { cache: 0 });
                p2 = Http.get(`${this.fieldsAclLocation}?operation=edit&entityId=${entity.id}${additionnalFieldsString}`, { cache: 0 });
                break;
        }

        const rights = Promise.all([p1, p2]).then(([detailRights, editRights]) => {
            const detailRightsFormatted = {};
            const editRightsFormatted = {};
            for (const prop in detailRights.rights) {
                detailRightsFormatted[prop] = {
                    apiRights: {
                        detail: detailRights.rights[prop].hasAccess,
                    },
                };
            }
            for (const prop in editRights.rights) {
                editRightsFormatted[prop] = {
                    apiRights: {
                        edit: editRights.rights[prop].hasAccess,
                    },
                };
            }
            return _.merge(detailRightsFormatted, editRightsFormatted);
        });
        return rights;
    };

    /**
     * Methode pour télécharger les droits champs par champ, en fonction de l'id de l'entité */
    getAclForEditFields = async ({ entity, _resource, context, routeParams }) => {
        return await this.getFieldRightsForEntity(entity, CONTEXT_ALL, routeParams).then(async (fieldRights) => {
            this.fields = _.merge(fieldRights, this.fields);
            await context.forceUpdate();
        });
    };

    /**
     * Methode pour télécharger les droits champs par champ, en fonction de l'id de l'entité */
    getAclForDetailFields = async ({ entity, _resource, context, routeParams }) => {
        return await this.getFieldRightsForEntity(entity, CONTEXT_DETAIL, routeParams).then((fieldRights) => {
            this.fields = _.merge(fieldRights, this.fields);
            context.forceUpdate();
        });
    };

    hasRightsForOperation(operation, entity) {
        let resource = this;
        return hasRightsForOperation(operation, {
            resource,
            entity,
            hasRole: User.hasOneRole,
        });
    }

    /**
     * 
     * @param {*} handlerClass - Class Bulk qui sera instanciée
     * @param {APIResourceBulkParams} params 
     * @returns {APIResource}
     */
    addBulkAction(handlerClass, params = null) {
        const handlerObject = createHandlerObject(handlerClass, params);
        this.currentList.bulkActions.push(handlerObject);
        return this;
    }

    /**
     * Retourne une promesse fournissant un item selon son ID en base de données API ou null si l'item n'est pas stocké
     * ATTENTION : Cette méthode ne peut pas être utilisée directement par un Observer du fait qu'elle soit asynchrone (utiliser getObservableItem à la place)
     *
     * @param id
     * @returns {null|*}
     */
    getItem(id, forceFromApi = false) {
        let self = this;
        id = parseInt(id);

        return new Promise(function (resolve, _reject) {
            // Recherche dans le stockage local
            let item = null;
            for (let i in self.items) {
                if (parseInt(self.items[i].id) === id) {
                    item = self.items[i];
                }
            }
            if (item && !forceFromApi) {
                resolve(item);
            } else {
                // Recherche via l'API
                self.apiGetOne(id, forceFromApi).then((i) => {
                    resolve(i);
                });
            }
        });
    }

    /**
     * Retourne un item directement observable selon un id donné.
     * Si l'item n'est pas stocké, retourne un observable vide de type {id: 1, __init: false} et demande à l'API la mise à jour
     * Cette méthode est à utiliser dans les methode render() des Observer Components
     *
     * @param id
     * @param forceUpdateFromApi
     * @param {boolean} avoidUpdateApiCall - Empeche l'appel à apiGetOne, dans le cas où on lance un getOne de notre côté. On fait cela car apiGetOne et Http ne savent pas si une requête back identique est déjà en cours.
     * @returns {*}
     */
    getObservableItem(id, forceUpdateFromApi = false, avoidUpdateApiCall = false) {
        id = parseInt(id);
        // if we this item is being deleted then do not refence it
        if (this.deleteProcessing && this.deleteProcessingId === id) {
            return { id: id, __init: false };
        }
        // Recherche dans le stockage local
        for (let i in this.items) {
            if (parseInt(this.items[i].id) === id) {
                if (forceUpdateFromApi) {
                    this.apiGetOne(id, forceUpdateFromApi);
                }
                return this.items[i];
            }
        }

        // Si la recherche n'a rien donné on retourne un item temporaire, on demande l'item à l'API et on retourne l'objet temporaire qui sera mis à jour par l'API
        if (!avoidUpdateApiCall || forceUpdateFromApi ) this.apiGetOne(id, forceUpdateFromApi);
        return { id: id, __init: false };
    }

    /**
     * Retourne un item directement observable selon un path api donné.
     * @see this.getObservableItem
     *
     * @param path
     * @param forceUpdateFromApi
     * @returns {*}
     */
    getObservableItemByPath(path, forceUpdateFromApi = false) {
        let entityPath = path;
        entityPath = entityPath.split('/');
        let id = entityPath[entityPath.length - 1];
        return this.getObservableItem(id, forceUpdateFromApi);
    }

    /**
     * Retourne un item selon son chemin API
     *
     * @param {string} resourcePath
     * @param {boolean} [forceFromApi] - default false
     * @param {Array<string>} [onlyProperties]
     * @returns {Promise<any>}
     */
    getItemFromResourcePath(resourcePath, forceFromApi = false, onlyProperties = null) {
        let self = this;

        return new Promise(function (resolve, _reject) {
            if (!resourcePath) {
                resolve(null);
                return;
            }
            // Recherche dans le stockage local
            if (!forceFromApi) {
                let item = self.items.find(elm => elm['@id'] === resourcePath);
                if(item && (!onlyProperties || onlyProperties.every(property => Object.keys(item).includes(property)))){
                    resolve(item);
                    return;
                }
            }

            // Recherche via l'API
            if (resourcePath) {
                let id = resourcePath.split('/');
                id = id[id.length - 1];
                self.apiGetOne(id, forceFromApi, onlyProperties).then((item) => {
                    resolve(item);
                    return;
                });
            }
        });
    }

    /**
     * Retourne la clé du tableau de stockage des items de la ressource pour un item donné ou null si l'item n'est pas stocké
     *
     * @param item
     * @returns {string|null}
     */
    getInternalItemId(item, createTemporaryItem = false) {
        // On recherche l'id de l'item stockée
        for (let i in this.items) {
            if (parseInt(this.items[i].id) === parseInt(item.id)) {
                return i;
            }
        }

        // Si l'item n'est pas stockée, et que createTemporaryItem == true
        if (createTemporaryItem) {
            this.items.push({ id: parseInt(item.id) });
            return this.getInternalItemId(item);
        }

        return null;
    }

    /**
     * Retourne la clé du tableau de stockage de la currentList des items de la ressource pour un item donné ou null si l'item n'est pas stocké
     *
     * @param item
     * @returns {string|null}
     */
    getInternaltCurrentListItemId(item) {
        for (let i in this.currentList.items) {
            if (parseInt(this.currentList.items[i].id) === parseInt(item.id)) {
                return i;
            }
        }
        return null;
    }

    /**
     * Stocke localement un item
     *
     * @param item
     */
    setItem(item) {
        item.__init = true;
        let internalItemId = this.getInternalItemId(item);
        if (internalItemId != null) {
            if (!_.isEqual(this.items[internalItemId], item)) this.items[internalItemId] = item;
        } else {
            this.items.push(item);
        }
        let internalCurrentListItemId = this.getInternaltCurrentListItemId(item);
        if (internalCurrentListItemId != null) {
            if (!_.isEqual(this.currentList.items[internalCurrentListItemId], item)) this.currentList.items[internalCurrentListItemId] = item;
        }
    }

    setItems(items = []){
        let self = this;
        self.items = [...items];
        self.currentList.items = self.items;
        for (let i in items) {
            self.items[i].__init = true;
        }
        self.totalItems = self.items.length;
        self.currentList.items = self.currentList.items.slice(0, self.currentList.rowsPerPage);
    }

    /**
     * Supprime localement un item
     *
     * @param item
     */
    deleteItem(item) {
        let internalItemId = this.getInternalItemId(item);
        if (internalItemId != null) {
            this.items.splice(internalItemId, 1);
        }
        let internalCurrentListItemId = this.getInternaltCurrentListItemId(item);
        if (internalCurrentListItemId != null) {
            this.currentList.items.splice(internalCurrentListItemId, 1);
        }
    }

    /**
     * Supprime un item (localement et dans l'API) après avoir demandé une confirmation à l'utilisateur
     *
     * @param {Object} item - Item à supprimer
     * @param {function(item)} [beforeDelete] - Callback appelée juste après la confirmation utilisateur et avant le déclenchement de la suppression
     * @returns {Promise<unknown>}
     */
    deleteItemWithModalConfirmation(item, beforeDelete = null) {
        return new Promise((resolve, reject) => {
            Modal.open({
                title: 'Deletion confirmation',
                content: (
                    <ResourceDelete
                        item={item}
                        resourceName={this.canonicalName}
                        confirmation={this.operations[CONTEXT_DELETE].confirmation}
                        onDelete={(item) => {
                            this.deleteProcessing = true;
                            this.deleteProcessingId = item.id;
                            if (beforeDelete) {
                                beforeDelete(item);
                            }
                            this.apiDelete(item)
                                .then((deletedEntity) => {
                                    Modal.close();
                                    Alert.show({
                                        message: 'Item successfully deleted.',
                                        type: 'success',
                                    });
                                    resolve(deletedEntity);
                                    this.deleteProcessing = false;
                                    this.deleteProcessingId = null;
                                })
                                .catch((err) => {
                                    this.deleteProcessing = false;
                                    this.deleteProcessingId = null;
                                    reject(err);
                                });
                        }}
                    />
                ),
            });
        });
    }

    /**
     * Restaure une version donnée d'un item (si la ressource est versionnée)
     *
     * @param versionId
     * @returns {Promise<unknown>}
     */
    revertItemVersion(versionId) {
        return new Promise((resolve, _reject) => {
            this.getItem(versionId, true).then((itemToRevert) => {
                let currentVersionId = itemToRevert.currentVersion.split('/');
                currentVersionId = currentVersionId[currentVersionId.length - 1];
                this.getItem(currentVersionId, true).then((currentVersion) => {
                    let newVersion = { ...itemToRevert };
                    newVersion.id = currentVersionId;
                    newVersion.currentVersion = null;
                    newVersion.activeVersion = true;
                    for (let fieldId in currentVersion) {
                        if (
                            Array.isArray(currentVersion[fieldId]) &&
                            currentVersion[fieldId].length > 0 &&
                            typeof currentVersion[fieldId][0] === 'string' &&
                            currentVersion[fieldId][0].match(/^\/api\/.+/)
                        ) {
                            delete newVersion[fieldId];
                        }
                    }
                    this.apiPut(newVersion).then((entity) => {
                        this.apiGetCollection({ forceReload: true });
                        resolve(entity);
                    });
                });
            });
        });
    }

    getAllItems() {
        if (!this.isItemsInit()) {
            setTimeout(() =>
                this.apiGetCollection({
                    page: 1,
                    rowsPerPage: this.endpoints && this.endpoints.getAll ? -1 : 10000,
                    context: 'getAllItems',
                    cache: true,
                })
            );
        }
        return this.items;
    }

    /**
     * Retourne l'id d'un élément selon son systemId
     *
     * Charge un seul item depuis le serveur si la liste complète n'est pas déjà pré-chargée
     *
     * @param systemId
     * @returns {boolean|string}
     */
    getIdFromSystemId(systemId) {
        //this.getAllItems();
        let param = this.filterItems({ systemId: systemId });
        param = param.pop();
        if (!param) {
            // Always at first sight, before re-render from mobx
            // throw new Error('No parameters with systemId '+systemId);
            return false;
        }
        return param['@id'];
    }

    /**
     * Filtre localement les items déjà chargées de la ressource
     * @param predicates
     * @param entity
     * @param {'edit'|'detail'|'none'} [context] Contexte de l'appel, defaut 'none'
     * @param {string} [fieldId] - FieldId qui référence cette liste d'items de la resource
     * @returns {}
     * @see #filter
     */
    filterItems(predicates, entity, context = 'none', fieldId = null) {
        return this.filter(this.items, predicates, entity, context, fieldId);
    }

    /**
     * Filtre des items, en particulier un tableau d'entities, selon des predicats
     *
     * @param {array} items Items à filtrer
     * @param {Array|Object|string|function} [predicates] Le filtre peut être un élément ou bien un tableau de ce type d'éléments :
     *      - Object {field: value}, retourne tous les items dont le field correspond à la value
     *      - Object {field: function(value, entity, item)}, retourne tous les items dont le field correspond au retour de la function
     *      - String "field" : retourne tous les items possédant ce field s'il n'est pas faux ou nul
     *      - Function (item, entity, key, context, fieldId) => … : retourne tous les items pour lesquels la fonction retourne vrai
     *      - null => est ignoré
     * @param {Object} [entity] facultatif : valeur de l'argument entity2 dans les fonctions de predicats
     * @param {'edit'|'detail'|'none'} [context] Contexte de l'appel, defaut 'none'
     * @param {string} [fieldId] - FieldId qui référence cette liste d'items de la resource
     * @returns {array}
     */
    filter(items, predicates, entity, context = 'none', fieldId = null) {
        if (!predicates) {
            return items || [];
        }

        // Convert to array if not
        predicates = Array.isArray(predicates) ? predicates : [predicates];
        items = Array.isArray(items) ? items : [items];

        // Convert predicates to functions
        predicates = predicates.map((predicate) => {
            if (typeof predicate == 'function') {
                return predicate;
            }
            if (predicate == null) {
                return (item) => item;
            }
            if (typeof predicate == 'object') {
                // predicate is like { field: 'expectedValue', otherField: 'otherValue' }
                return (item, entity) => {
                    return (
                        item === predicate ||
                        Object.entries(predicate).filter(([key, value]) => {
                            if (typeof value == 'function') {
                                return !value(item[key] || '', entity, item);
                            }
                            return item[key] != value;
                        }).length === 0
                    );
                };
            }
            return (item) => item[predicate];
        });

        // Apply all the predicates one after the other on the original items
        let filteredItems = items || [];
        predicates.map((predicate) => {
            filteredItems = filteredItems.filter((item, key) => predicate(item, entity, key, context, fieldId));
        });
        return filteredItems;
    }

    /**
     * @return {string}
     */
    fieldGetCanonical(fieldId) {
        return this.fields[fieldId] && this.fields[fieldId].canonicalFieldName
            ? this.fields[fieldId].canonicalFieldName
            : fieldId;
    }

    /**
     * Implémentation de l'opération API GET Collection.
     * 
     * NB: petite précision sur le cache :
     * Contrairement aux opérations apiGetOne ou getObservableItem etc., cette méthode ne va pas regarder dans les 
     * items de APIResource. Ce qu'elle fait c'est regarder dans le cache du module Http.
     * Or ce cache est dépendant du endpoint _complet_, donc un changement de endpoint (ex: perPage ..) pour une même
     * APIResource cherchera un cache différent et probablement donnera lieu à une nouvelle requête API.
     *
     * @param {Object} options
     * @param {number} [options.page] - Default 1
     * @param {number} [options.rowsPerPage] - Default 25
     * @param {Object.<string, Array<string|number|boolean>|string|number|boolean} [options.filters] - Filtres temporaires, ex: {fieldId: ['1', '2'], fieldId2: 'null', ...}
     * @param {Array<string>} [options.fields] - Permet de limiter les données reçues de l'api à certains champs (ie properties[]=xx de ApiPlatform). Tous les champs si undefined.
     * @param {boolean} [options.forceReload] - Default false
     * @param {boolean|undefined|number} [options.cache] - Valeur utilisée si forceReload est false, exprimée en s si number, {@see Http.get()}
     * @param {Object.<string, 'asc'|'desc'>} [options.order] - Order by pour la clé donnée
     * @param {string} [options.context] - Default null, context éventuel rajouté à la requête back
     * @param {boolean} [options.saveConfig] - Si true sauvegarde la config dans le SessionStorage
     * 
     * @returns {Promise<Array<Object.<string, *>>}
     */
    apiGetCollection(
        options = {
            page: 1,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE,
            filters: {},
            fields: [],
            forceReload: false,
            cache: undefined,
            order: {},
            context: null,
            limitProperties: false,
        }
    ) {
        const defaultOptions = {};
        // merge default options to ensure to backwards compatibility
        options = _.merge({}, defaultOptions, options);
        if (this.deleteProcessing) {
            return Promise.resolve();
        }

        let self = this;
        let now = new Date();
        const { page, rowsPerPage, filters, fields, forceReload, cache, order, saveConfig, limitProperties } = options;
        return new Promise((resolve, reject) => {
            // Si on ne peut pas retourner les données du cache, on les fournit depuis l'API
            // self.currentList.page = page || self.currentList.page;
            // self.currentList.rowsPerPage = rowsPerPage
            //   ? rowsPerPage
            //   : self.currentList.rowsPerPage;
            // self.currentList.filters = filters || [];
            // self.currentList.order = order;

            const requestedPage = page || self.currentList.page;
            const requestedRowsPerPage = rowsPerPage || self.currentList.rowsPerPage;
            const requestedFilters = filters || {};
            const requestedOrder = order;
            self.currentList.lastUpdate = now;

            let endPoint = null;
            if ((rowsPerPage === -1 || requestedRowsPerPage === 'All') && self.endpoints && self.endpoints.getAll) {
                endPoint = self.endpoints.getAll;
                let filters = '';
                if (options.context) {
                    filters += 'context=' + options.context + '&';
                } else if (this.context) {
                    filters += this.context ? 'context=' + this.context + '&' : '';
                }
                for (let i in options.filters) {
                    filters += i + '=' + options.filters[i] + '&';
                }
                filters = filters.substring(0, filters.length - 1);
                // on pourrait se donner la possiblité d'ajouter des filtres permanents dans le endpoint
                // éventuellement :  endPoint += (endPoint.includes('?') ? '&' : '?') + filters;
                endPoint += '?' + filters;

                // Ajout des paramètres de tri
                if (requestedOrder) {
                    for (let field of Object.keys(requestedOrder)) {
                        endPoint += '&order[' + field + ']=' + requestedOrder[field];
                    }
                }

                // Ajout des paramètres de propriétés à récupérer
                if (fields && Array.isArray(fields)) {
                    for (let field of fields) {
                        endPoint += `&properties[]=${field}`;
                    }
                }
            } else {
                endPoint = (self?.endpoints?.getAll ?? self.resourceId) + '?page=' + requestedPage;
                if (requestedRowsPerPage === 'All') {
                    endPoint += '&pagination=' + false;
                } else {
                    endPoint += '&perPage=' + requestedRowsPerPage;
                }

                // Ajout des paramètres de filtrage
                for (let id in requestedFilters) {
                    let fieldId = this.fieldGetCanonical(id);
                    if (requestedFilters[fieldId] instanceof Array) {
                        const isFilterMulti = this.fields?.[fieldId]?.params?.filterMulti || false;
                        requestedFilters[fieldId].forEach((filterValue) => {
                            if(filterValue === 'null'){
                                endPoint += '&or_exists[' + fieldId + ']=false';
                            } else if (!isFilterMulti || filterValue) {
                                endPoint += '&' + fieldId + '[]=' + filterValue;
                            }
                        });
                    } else {
                        if(requestedFilters[fieldId] === 'null') {
                            endPoint += '&or_exists[' + fieldId + ']=false';
                        }else{
                            endPoint += '&' + fieldId + '=' + requestedFilters[id];
                        }
                    }
                }

                // Ajout des paramètres de filtrage permanent
                for (let id in self.currentList.permanentFilters) {
                    if (id.replace(/\[.+\]/gi, '[]') === 'exists[]') {
                        endPoint += '&' + id + '=' + self.currentList.permanentFilters[id];
                    } else {
                        let preparedID = id.replace(/\[.+\]/gi, '[]');
                        endPoint += '&' + preparedID + '=' + self.currentList.permanentFilters[id];
                    }
                }

                // Ajout des paramètres de tri
                if (requestedOrder) {
                    for (let field of Object.keys(requestedOrder)) {
                        endPoint += '&order[' + field + ']=' + requestedOrder[field];
                    }
                }

                // Si oui, on limite les properties demandées à celles affichées et les plus fréquentes ou nécessaires
                if (limitProperties) {
                    const properties = [
                        'id',
                        'toString',
                        ...this.currentList.displayFields,
                        ...this.operations.list.neededFields,
                        // Récupération des fields pour la première tab
                        ...(Object.values(this?.layout?.tabs || {})?.[0]?.rows.reduce(
                            (arr, r) => [...arr, ...Object.values(r.panels).reduce((a, f) => [...a, ...f.fields], [])],
                            []
                        ) || []),
                    ];
                    // s'il existe on utilise le canonicalFieldName, et on ne garde que les uniques :
                    const canonicalFieldNames = properties.map((p) => this.fieldGetCanonical(p));
                    endPoint += [...new Set(canonicalFieldNames)].map((p) => {
                        /**
                         * @todo ici on peut rencontrer un pb : on va faire remonter des infos "nested"
                         * mais souvent on veut un IRI, et donc on casse le fonctionnement en remontant une info.
                         * Pourtant il faut bien,comme pour modelUse.model.functionalID le récupérer/limiter/afficher...
                         */
                        const nested = p.split('.');
                        const leaf = nested.pop();
                        return `&properties${nested.map(n => `[${n}]`)}[]=${leaf}`;}).join('');
                }

                // Ajout des paramètres de propriétés à récupérer
                if (fields && Array.isArray(fields)) {
                    for (let field of fields) {
                        field = this.fieldGetCanonical(field);
                        endPoint += `&properties[]=${field}`;
                    }
                }
            }

            this.currentList.lastUsedEndpoint = endPoint;

            // save the config in session storage
            if (saveConfig) {
                self.currentList.page = requestedPage;
                self.currentList.rowsPerPage = requestedRowsPerPage;
                self.currentList.filters = requestedFilters;
                self.currentList.order = requestedOrder;

                self.saveConfig();
            }

            this.currentRequestList = Http.getRequest();
            Http.get(endPoint, {
                cache: forceReload ? false : cache,
                request: this.currentRequestList
            })
                .then((data) => {
                    self.currentList.items = [];
                    self.items = [];
                    for (let i in data['hydra:member']) {
                        data['hydra:member'][i].__init = true;
                    }
                    self.totalItems = data['hydra:totalItems'];
                    self.currentList.items = data['hydra:member'];
                    self.items = self.currentList.items;

                    resolve(self.currentList.items);
                })
                .catch(reject);
        });
    }

    apiGetCollectionDebounced = _.debounce(this.apiGetCollection, 300);

    /**
     * Implémentation de l'opération API GET One
     * @param {Array<string>} onlyProperties - Limite aux propriétés demandées
     */
    apiGetOne(id, forceReload, onlyProperties = null) {
        let self = this;
        return new Promise((resolve, reject) => {
            if (id && id !== 'null') {
                let endPoint = this.resourceId + '/' + id;
                if(onlyProperties !== null){
                    if (onlyProperties && Array.isArray(onlyProperties)) {
                        if(!onlyProperties.includes('id')){
                            onlyProperties.push('id');
                        }
                        endPoint += `?properties[]=${onlyProperties.join("&properties[]=")}`;
                    }
                }
                self.currentRequestGetOne = Http.getRequest();
                Http.get(endPoint, { cache: !forceReload, request: self.currentRequestGetOne })
                    .then((data) => {
                        data.__init = true;
                        this.setItem(data);
                        let index = self.getInternalItemId(data);
                        resolve(index ? self.items[index] : data);
                    })
                    .catch(reject);
            } else {
                resolve(null);
            }
        });
    }

    /**
     * Implémentation de l'opération API POST.
     * 
     * @returns {Promise} Met à jour l'item dans APIResource et le retourne
     */
    apiPost(entity) {
        let self = this;
        return new Promise((resolve, reject) => {
            let endPoint = this.resourceId;

            Http.post(endPoint, entity)
                .then((data) => {
                    self.setItem(data);
                    resolve(self.items[self.getInternalItemId(data)]);
                })
                .catch(reject);
        });
    }

    /**
     * Implémentation de l'opération API PUT
     */
    apiPut(entity) {
        return new Promise((resolve, reject) => {
            let endPoint = this.resourceId + '/' + entity.id;
            Http.put(endPoint, entity)
                .then((data) => {
                    this.setItem(data);
                    resolve(data);
                })
                .catch(reject);
        });
    }

    /**
     * Implémentation de l'opération API PUT ALL
     */
    apiPutAll() {
        return new Promise((resolve, reject) => {
            let endPoint = this.resourceId;
            Http.put(endPoint)
                .then((data) => {
                    resolve(data);
                })
                .catch(reject);
        });
    }

    /**
     * Implémentation de l'opération API POST FILE (multipart/form-data)
     */
    apiPostFile(entity) {
        let self = this;
        return new Promise((resolve, reject) => {
            let endPoint = this.resourceId;
            Http.postFile(endPoint, entity)
                .then((data) => {
                    self.setItem(data);
                    resolve(self.items[self.getInternalItemId(data)]);
                })
                .catch(reject);
        });
    }

    /**
     * Implementation de l'opération API DELETE
     *
     * @param {Object} entity - Entité à supprimer, via la ressource API possédée
     * @returns {Promise<Object>} renvoie l'entité supprimée (par exemple pour retrouver la route d'un parent)
     */
    apiDelete(entity) {
        return new Promise((resolve, reject) => {
            let endPoint = this.resourceId + '/' + entity.id;
            Http.delete(endPoint)
                .then(() => {
                    this.deleteItem(entity);
                    resolve(entity);
                })
                .catch(reject);
        });
    }

    /**
     * Save the settings (curently only the datatable columns) in the api for future usage.
     *
     */
    saveRemoteConfig = async (type) => {
        const code = `resourceConfig_${this.resourceId}_${this.instanceId}`;

        let remoteConfig = await User.getSettingByCode(code);
        if (!remoteConfig) {
            remoteConfig = {};
        }
        remoteConfig.code = code;
        this.updateFiltersList();
        remoteConfig.type = type || 'datatable-config';
        remoteConfig.value = {
            ...remoteConfig.value,
            displayFields: this.currentList.displayFields,
        };
        if (User && remoteConfig.id) {
            User.editSetting(remoteConfig);
        } else {
            User.addSetting(remoteConfig);
        }
    };

    /**
     * restore the settings (curently only the datatable columns) from the api.
     *
     */
    restoreRemoteConfig = async () => {
        const code = `resourceConfig_${this.resourceId}_${this.instanceId}`;
        const savedConfig = await User.getSettingByCode(code);
        if (
            savedConfig &&
            savedConfig.value &&
            savedConfig.value.displayFields &&
            savedConfig.value.displayFields.length > 0
        ) {
            this.currentList.displayFields = savedConfig.value.displayFields.filter((f) => this.fields[f]);
            // sending config to localstorage afterwards
            this.saveConfig();
        }
    };

    /**
     *
     */
    saveConfig = () => {
        const { page, rowsPerPage, filters, order, displayFields } = this.currentList;
        this.updateFiltersList();
        window.sessionStorage.setItem(
            `ApiResourceConfig_${this.resourceId}_${this.instanceId}`,
            JSON.stringify({
                page,
                rowsPerPage,
                filters,
                order,
                displayFields,
            })
        );
    };

    reloadSavedConfig = () => {
        const listConfig = this.getSavedConfig();
        if (listConfig) {
            this.currentList = _.merge(this.currentList, listConfig);
            this.updateFiltersList();
        }
    };

    getSavedConfig = () => {
        const config = window.sessionStorage.getItem(`ApiResourceConfig_${this.resourceId}_${this.instanceId}`);
        try {
            return config ? JSON.parse(config) : null;
        } catch (e) {
            console.warn(e.message);
            return null;
        }
    };

    /**
     * Update the filters list to remove fields that are not currently displayed
     */
    updateFiltersList() {
        const { filters, displayFields } = this.currentList;
        if (filters && displayFields) {
            for (const i in filters) {
                const cleanedField = i.replace(/\[.*\]/, '');
                if (displayFields.indexOf(cleanedField) === -1) {
                    delete filters[i];
                }
            }
        }
    }

    /**
     * Renvoie une nouvelle instance de APIResource, avec un nouvel instanceId.
     * 
     * @param {string} instanceId 
     * @returns {APIResource}
     */
    clone(instanceId) {
        const apiResource = _.cloneDeep(this);
        apiResource.instanceId = instanceId;
        /** @todo Si la resource est déjà dans le store que fait-on ? */
        ApiResourceStore.resources[instanceId] = apiResource;
        return apiResource;
    }

    /**
     * Sauvegarde localement l'entité en cours d'édition
     * @param entity Entité
     * @param instanceId
     * @param resourceName
     * @param entityType Type de resource
     */
    static setLocalCurrentEditingCopy(entity, instanceId, resourceName, entityType) {
        window.localStorage.setItem(
            'currentEditingEntity',
            JSON.stringify({
                date: new Date(),
                entity: entity,
                instanceId: instanceId,
                resourceName: resourceName,
                entityType: entityType,
            })
        );
    }

    /**
     * Retourne la sauvegarde locale de la dernière entité en cours d'édition
     * @returns {string}
     */
    static getLocalCurrentEditingEntity() {
        let local = window.localStorage.getItem('currentEditingEntity');
        return local ? JSON.parse(local) : null;
    }

    /**
     * Détruit la sauvegarde locale de la dernière entité en cours d'édition
     */
    static deleteLocalCurrentEditingCopy() {
        window.localStorage.removeItem('currentEditingEntity');
    }

    /**
     * Vérifie si une sauvegarde locale d'une entité existe et propose sa restauration à l'utilisateur si c'est le cas
     */
    static checkExistingLocalCurrentEditingEntity() {
        let local = APIResource.getLocalCurrentEditingEntity();
        if (local) {
            Modal.open({
                title: 'Interrupted editing session',
                content: <LocalCurrentEditing local={local} />,
                onClose: this.deleteLocalCurrentEditingCopy,
            });
        }
    }

    validate(entity, validationErrorIntroTxt = '', context = CONTEXT_EDIT, showAlert = true, predicateAdditionalArg = {}, checkFieldType = false) {
        return new Promise((resolve, reject) => {
            let errors = [];

            // Méthode gérant le traitement des erreurs et la résolution de la promesse
            let manageErrors = function (validationErrors) {
                if (validationErrors !== true) {
                    errors = errors.concat(validationErrors);
                }
                if (errors.length > 0) {
                    errors.push();
                    let errorsTxt = errors.map((item) => item.field + ': ' + item.detail).join('\n\n');
                    if(showAlert){
                        Alert.show({
                            message: (validationErrorIntroTxt ? validationErrorIntroTxt + '\n\n' : '') + errorsTxt,
                            type: 'warning',
                        });
                    }
                    reject(errors);
                } else {
                    resolve(true);
                }
            };

            // Test des règles de validation déclarées via this.setFields()
            this.operations[context].fields.forEach((fieldId) => {
                let field = this.fields[fieldId];
                field.errorHelperText = '';
                if (!field) {
                    console.error('Champ inconnu', this.instanceId, fieldId);
                    return;
                }
                if (
                    (field.displayConditions || field.displayCondition) &&
                    !this.filter(
                        [entity],
                        field.displayConditions || field.displayCondition,
                        predicateAdditionalArg, /** @todo jamais utilisé a priori */
                        CONTEXT_EDIT,
                        fieldId,
                    ).length
                ) {
                    return;
                }

                if (
                    !hasRightsForField(field, {
                        hasRole: settingsApi && settingsApi.hasRole,
                        operation: CONTEXT_EDIT,
                        entity: entity,
                    })
                ) {
                    return;
                }

                if (
                    ((typeof field.required === 'function' && field.required(entity, fieldId, context)) ||
                        (typeof field.required !== 'function' && field.required)) &&
                    isFieldEmpty(field, entity, fieldId)
                ) {
                    errors.push({
                        field: field.title,
                        fieldId: fieldId,
                        detail: 'This field is required',
                    });
                    field.errorHelperText = 'This field is required';
                }

                if (checkFieldType){
                    let error = fieldTypeFormatValidate(field, entity[fieldId]);
                    if (error !== false){
                        errors.push(error);
                    }
                }

                if (
                    field.validationPattern &&
                    entity[fieldId] !== undefined &&
                    entity[fieldId] !== ''
                ) {
                    if (entity[fieldId]) {
                        let stringValue = String(entity[fieldId]);
                        if (!stringValue.match(field.validationPattern)) {
                            errors.push({
                                field: field.title,
                                fieldId: fieldId,
                                detail: 'The provided value is not valid',
                            });
                            field.errorHelperText = 'The provided value is not valid';
                        }
                    }
                }
            });

            // Exécution de la callback de validation si celle-ci existe
            if (this.validation) {
                let callbackReturn = this.validation(entity);
                // Si la callback passée est une promesse
                if (callbackReturn.then) {
                    callbackReturn.then((validationErrors) => {
                        manageErrors(validationErrors);
                    });
                }
                // Si la callback passée n'est pas une promesse
                else {
                    let validationErrors = this.validation(entity);
                    manageErrors(validationErrors);
                }
            } else {
                manageErrors(true);
            }
        });
    }

    /**
     * Génère l'objet Layout pour une opération (insert/edit/detail) selon les champs à afficher
     * @returns {null|{}}
     */
    genErrorLayout() {
        if (!this.operations[CONTEXT_DETAIL] || !this.layout) {
            return null;
        }
        let operationFields = this.operations[CONTEXT_DETAIL].fields;
        let operationLayout = JSON.parse(JSON.stringify(this.layout));
        let errorLayout = [];

        for (let currentTab in operationLayout.tabs) {
            if (
                !User.profile.env ||
                (operationLayout.tabs[currentTab].env && operationLayout.tabs[currentTab].env != User.profile.env)
            ) {
                continue;
            }
            for (let currentRow in operationLayout.tabs[currentTab].rows) {
                for (let currentPanel in operationLayout.tabs[currentTab].rows[currentRow].panels) {
                    let panelFields = [];
                    for (let currentField in operationLayout.tabs[currentTab].rows[currentRow].panels[currentPanel]
                        .fields) {
                        if (
                            hasRightsForField(currentField, {
                                CONTEXT_DETAIL,
                                hasRole: User.hasOneRole,
                            })
                        ) {
                            for (let operationField in operationFields) {
                                if (
                                    operationLayout.tabs[currentTab].rows[currentRow].panels[currentPanel].fields[
                                        currentField
                                        ] === operationFields[operationField]
                                ) {
                                    panelFields.push(operationFields[operationField]);
                                }
                            }
                        }
                    }
                    if(!Array.isArray(errorLayout[currentTab])){
                        errorLayout[currentTab] = [];
                    }
                    errorLayout[currentTab][currentPanel] = panelFields;
                }
            }
        }

        return errorLayout;
    }

    getQueryParams(context, tabName = null){
        let queryParams = new URLSearchParams(window.location.search);
        const params = [];
        if (tabName) params.push(`tab=${tabName}`);

        if (this.keepQueryParams) {
            let allowParams = this.keepQueryParams();
            for (let param of queryParams.entries()) {
                if (allowParams.includes(param[0])) {
                    params.push(`${param[0]}=${param[1]}`);
                }
            }
        }

        return `?${params.join('&')}`;
    }
}

decorate(APIResource, {
    items: observable,
    totalItems: observable,
    currentList: observable,
    operations: observable,
    getItem: action,
});
