import React from 'react';
import PropTypes from 'prop-types';
import String from '../../../Services/String';
import { ScorePicker } from './ScorePicker';

import { Paper, TextField, AppBar, Chip, Toolbar, Typography } from '@material-ui/core';
import { TreeView, TreeItem } from '@material-ui/lab';
import { InfoOutlined, Notes, FormatListNumbered, NetworkCheck, ExpandMore, ChevronRight, VerifiedUserOutlined } from '@material-ui/icons';
import { MRA_PROCESS, MRA_SCORES_TYPES, MRA_STATUS } from '../../../Services/MRA';
import ParameterStore, { getParamByIri, getParamBySystemId } from '../../../Store/ParameterStore';
import { useTranslation } from 'react-i18next';
import { ParameterSelect } from '../../Forms/ParameterSelect/ParameterSelect';
import { PARAMETER_TYPE_MRASUBDIMENSION_REMEDIATION } from '../../../Admin/ParameterAdmin';
import Modal from '../../../Services/Modal';
import { PropagateOneNotchDowngradeToNoticeModal } from './PropagateOneNotchDowngradeToNoticeModal';

export const SubdimensionContent = (props) => {
    const {
        subDimension,
        onScoreChange,
        isNTXDimension,
        isGROUPDimension,
        mraStatus,
        mraProcess,
        scoreJustificationDisabled = false,
        readonly,
        allowedScoreValues = [],
        isMraImported,
    } = props;
    /** @type {{mraScoresToEdit: Array<import('../../../Services/MRA').MraScore>, mraScoresToShow: Array<import('../../../Services/MRA').MraScore>}} props */
    const { mraScoresToEdit, mraScoresToShow } = props;
    const allScoreValues = [0, 1, 2, 3, 4, 5];

    const { t } = useTranslation();

    const getParameterLabel = (parameterIri) => getParamByIri(parameterIri).label;

    const handleScoreChange = (mraScore, value, field = "score") => {
        // il ne peut y avoir qu'un MRA score par type, donc on le remplace dans le state
        mraScore[field] = value;
        if (field === "score") mraScore.auto = false;
        onScoreChange(mraScore, field === "remediation");
    };

    const handleJustificationChange = (mraScore, event) => {
        mraScore.justification = event.target.value;
        onScoreChange(mraScore);
    };

    const showOneNotchDowngradeModal = (mraScore) => {
        Modal.open({
            title: "One-notch downgrade",
            content: <PropagateOneNotchDowngradeToNoticeModal
                mraScore={mraScore}
                callback={() => Modal.close()}
            />,
        })
    }

    return (
        <div className="sub-dimension container">
            <Paper>
                <AppBar position="static" className="background-bpce-red">
                    <Toolbar>
                        <Typography variant="h6" style={{ color: 'white' }}>
                            {t(subDimension.title)}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <TreeView
                    defaultCollapseIcon={<ExpandMore style={{ color: '#57599C' }} />}
                    defaultExpandIcon={<ChevronRight style={{ color: '#57599C' }} />}
                    className="tree-view"
                    defaultExpanded={['5', '6']}
                >
                    {/* prettier-ignore */}
                    <HelpTreeItem nodeId="1" icon={<InfoOutlined />} title="Description" content={String.fmtGuide(t(`${subDimension.title}_description`, subDimension.description))} />
                    {/* prettier-ignore */}
                    <HelpTreeItem nodeId="2" icon={<FormatListNumbered />} title={t('Scoring Guide')} content={String.fmtGuide(t(`${subDimension.title}_scoring_guide`, subDimension.scoringGuide))} />
                    {/* prettier-ignore */}
                    {!isNTXDimension && !isGROUPDimension && (
                        <HelpTreeItem
                            nodeId="3"
                            icon={<Notes />}
                            title="Scoring Frequency"
                            content={String.fmtGuide(subDimension.scoringFrequency)}
                        />
                    )}

                    <TreeItem
                        nodeId="5"
                        className="tree-item Mui-expanded"
                        label={
                            <div className="label-container">
                                <div className="icon">
                                    <NetworkCheck />
                                </div>
                                <Typography variant="h6" className="text">
                                    Score
                                </Typography>
                            </div>
                        }
                    >
                        <div className="content">
                            <div style={styles.sliderContainer}>
                                {/** en mode lecture, on essaie d'afficher tous les scores : edit + show */
                                [...mraScoresToShow, ...(readonly ? mraScoresToEdit : [])].map((s, i) => {
                                    if (!s.type) {
                                        return null;
                                    }
                                    let label = getParameterLabel(s.type);
                                    // remove residual mention from label
                                    label = label?.replace('residual ', '');
                                    if (!label || label.indexOf('inherent') >= 0) {
                                        return null;
                                    }
                                    // LOD1 should not see other scores if status is ON_GOING
                                    if (label.indexOf('LOD1') === -1 && mraProcess.systemId !== MRA_PROCESS.LOD2 && mraStatus.systemId === MRA_STATUS.ON_GOING) {
                                        return null;
                                    }

                                    return (<React.Fragment key={i}>
                                        <Chip
                                            color="primary"
                                            label={label + ' : ' + (s.score ? s.score : 'N/A')}
                                            variant="outlined"
                                            style={styles.chip}
                                        />
                                        {(MRA_SCORES_TYPES[getParamByIri(s.type).systemId].showJustification ===
                                            undefined ||
                                            MRA_SCORES_TYPES[getParamByIri(s.type).systemId].showJustification ===
                                                true) && (
                                            <TextField
                                                id="outlined-full-width"
                                                label="Score justification"
                                                className="input-score-justification"
                                                placeholder="Please explain here the given score."
                                                multiline
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                InputLabelProps={{shrink: true,}}
                                                value={s.justification || undefined} // ici le composant est contrôlé dès que la justification existe
                                                disabled={true}
                                            />
                                        )}
                                    </React.Fragment>);
                                })}
                            </div>
                            {!readonly && mraScoresToEdit.map((s, i) => (
                                <div key={i}>
                                    <div style={styles.sliderContainer} key={i}>
                                        <Typography variant={"h6"} color="primary">
                                            {getParameterLabel(s.type).replace('residual ', '')}
                                        </Typography>
                                        <ScorePicker
                                            onChange={(value) => handleScoreChange(s, value)}
                                            initValue={s.score}
                                            max={Math.max(...allScoreValues)/* isNTXDimension || isGROUPDimension ? 5 : 4 */}
                                            allowedScoreValues={allowedScoreValues}
                                            isMraImported={isMraImported}
                                        />
                                    </div>

                                    {(MRA_SCORES_TYPES[getParamByIri(s.type).systemId].showJustification ===
                                        undefined ||
                                        MRA_SCORES_TYPES[getParamByIri(s.type).systemId].showJustification ===
                                            true) && (
                                        <TextField
                                            id="outlined-full-width"
                                            label="Score justification"
                                            className="input-score-justification"
                                            placeholder="Please explain here the given score."
                                            multiline
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => handleJustificationChange(s, event)}
                                            // value undefined par defaut pour que le composant ne soit pas contrôlé ou utiliser defaultValue
                                            // defaultValue={s.justification}
                                            value={s.justification || undefined} // ici le composant est contrôlé dès que la justification existe
                                            disabled={scoreJustificationDisabled}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </TreeItem>
                    {subDimension.title !== 'Monitoring results (at design and periodically)' && (
                        <TreeItem
                            nodeId="6"
                            className="tree-item Mui-expanded"
                            label={
                                <div className="label-container">
                                    <div className="icon">
                                        <VerifiedUserOutlined />
                                    </div>
                                    <Typography variant="h6" className="text">
                                        {t('Override justification')}
                                    </Typography>
                                </div>
                            }
                        >
                            <div className="content">
                                {
                                    /**
                                     * On ne devrait plus avoir qu'un seul score (LoD2) à modifier,
                                     * mais dans le doute on filtre sur LOD2 malgré tout.
                                     * NB: les remédiations NA ou null ou undefined doivent se comporter pareil
                                     */
                                    [...mraScoresToEdit, ...(readonly ? mraScoresToShow : [])]
                                        .filter(
                                            (s) => getParamByIri(s.type)?.systemId === 'MRA_SCORE_TYPE_LOD2_RESIDUAL'
                                        )
                                        .map((s, i) => (
                                            <ParameterSelect
                                                key={`remediation_${i}`}
                                                label=""
                                                field={{
                                                    params: {
                                                        type: PARAMETER_TYPE_MRASUBDIMENSION_REMEDIATION,
                                                        filter: (p) =>
                                                            allowedScoreValues.length === 1 || (allowedScoreValues.length === 2 && allowedScoreValues.includes(0))
                                                                ? s.score === 0
                                                                    ? ['REMEDIATION_NA'].includes(p.systemId)
                                                                    : [
                                                                        'REMEDIATION_NA',
                                                                        'REMEDIATION_1NOTCH_DOWNGRADE_APPROVED',
                                                                    ].includes(p.systemId)
                                                                : !['REMEDIATION_1NOTCH_DOWNGRADE_APPROVED'].includes(
                                                                      p.systemId
                                                                )
                                                        ,
                                                    },
                                                }}
                                                value={s?.remediation || getParamBySystemId('REMEDIATION_NA')['@id']}
                                                disabled={readonly || s.oneNotchDowngradeFromMostSevereNotice}
                                                onChange={(remediation) => {
                                                    if (remediation === ParameterStore('REMEDIATION_1NOTCH_DOWNGRADE_APPROVED') && subDimension.title !== 'Upstream models') showOneNotchDowngradeModal(s);
                                                    handleScoreChange(s, remediation, 'remediation');
                                                }}
                                                required={true}
                                                clearable={false}
                                            />
                                        ))
                                }
                            </div>
                        </TreeItem>
                    )}
                </TreeView>
            </Paper>
        </div>
    );
};
SubdimensionContent.propTypes = {
    subDimension: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        scoringGuide: PropTypes.any,
        scoringFrequency: PropTypes.any,
    }),
    onScoreChange: PropTypes.func,
    /*mraSubdimensionScore: PropTypes.shape({
        score: PropTypes.number,
        justification: PropTypes.string,
    }),*/
    mraStatus: PropTypes.shape({systemId: PropTypes.string}),
    mraProcess: PropTypes.shape({systemId: PropTypes.string}),
    mraScoresToEdit: PropTypes.arrayOf(
        PropTypes.shape({
            score: PropTypes.number,
            justification: PropTypes.string,
            /** Iri du Parameter associé */
            type: PropTypes.string,
            id: PropTypes.number,
            mraSubdimension: PropTypes.string,
        })
    ),
    mraScoresToShow: PropTypes.arrayOf(
        PropTypes.shape({
            score: PropTypes.number,
            justification: PropTypes.string,
            /** Iri du Parameter associé */
            type: PropTypes.string,
            id: PropTypes.number,
            mraSubdimension: PropTypes.string,
        })
    ),
    // Disable the "justification" field (default: false)
    scoreJustificationDisabled: PropTypes.bool,
    isNTXDimension: PropTypes.bool,
    isGROUPDimension: PropTypes.bool,
    readonly: PropTypes.bool,
    /** Liste des valeurs autorisées pour le score de cette sous dimension */
    allowedScoreValues: PropTypes.array,
    isMraImported: PropTypes.bool,
};

/**
 *
 * @param {*} nodeId
 * @param {*} icon
 * @param {string} title
 * @param {string|JSX.Element|Array<string>|Array<JSX.Element>} [content]
 */
const HelpTreeItem = ({ nodeId, icon, title, content }) => {
    return (
        <TreeItem
            nodeId={nodeId}
            className="tree-item"
            label={
                <div className="label-container">
                    <div className="icon">{icon}</div>
                    <Typography variant="h6" className="text">
                        {title}
                    </Typography>
                </div>
            }
        >
            <div className="content">{content}</div>
        </TreeItem>
    );
};
HelpTreeItem.propTypes = {
    nodeId: PropTypes.any,
    icon: PropTypes.any,
    title: PropTypes.string,
    content: PropTypes.any,
}

let styles = {
    paper: {
        margin: 10,
    },
    sliderContainer: {
        marginTop: 20,
    },
    scoreContainer: {
        display: 'block',
        paddingLeft: 20,
    },
    chip: {
        marginBottom: '1rem',
        fontSize: '1rem',
        fontWeight: 'bold',
    },
};
