import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { APIResource, CONTEXT_DETAIL } from "../../../Services/APIResource/APIResource";
import { DisplayTextField } from "../DisplayTextField/DisplayTextField";
import { Link } from "react-router-dom";
import {IssueButton} from "../../Issue/IssueButton";
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import {OpenModal} from "../../Modal/OpenModal";
import APIResourceStore from "../../../Store/APIResourceStore";
import { genEntitySelectInstanceId, getDetailRoute } from "../../../Services/APIResource/Utils";

export class EntityDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.showLoading ? <LoadingIndicator /> : '',
        }

        this._instanceId = this.props.resourceId;
        const instanceId = genEntitySelectInstanceId(this._instanceId);
        this.resource = new APIResource({
            instanceId,
            id: this.props.resourceId,
        });
    }

    componentDidMount() {
        this.updateValue(this.props.value);
        if (this.props.issueButton === undefined) {
            this.setState({ issueButton: false });
        } else {
            this.setState({ issueButton: this.props.issueButton });
        }
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if ((this.props.value !== prevProps.value) || (this.props.entity !== prevProps.entity)) {
            this.updateValue(this.props.value);
            if (this.props.issueButton === undefined) {
                this.setState({ issueButton: false });
            } else {
                this.setState({ issueButton: this.props.issueButton });
            }
        }
    }

    addTooltip(component, entity, index) {
        let title = this.props.tooltip && this.props.tooltip(entity);
        if (!title) return component;

        return (
            <Tooltip key={'tooltip' + (index || '')} title={title} arrow placement="top-start">
                <div className="item">
                    {component}
                </div>
            </Tooltip>
        );
    }

    updateValue(value) {
        const onlyProperties = [...(this.props.defaultFields || [])];
        if (this.props.resourceLabel) onlyProperties.push(this.props.resourceLabel);
        if (this.props.neededFields) onlyProperties.push(...this.props.neededFields);

        if (Array.isArray(value) && typeof value[0] != 'object') {
            let promises = [];
            let values = [];
            let maxPreload = this.props.preloadMaxItem ?? 10000;
            let itemPreload = 0;
            value.forEach((val, index) => {
                let promise = this.resource.getItemFromResourcePath(val, false, onlyProperties.length ? onlyProperties : null).then((entity) => {
                    if (!entity) {
                        return;
                    }
                    let valLabel = entity[this.props.resourceLabel] ? entity[this.props.resourceLabel] : '-';
                    if (this.props.links === true) {
                        if(!this.props.linkAction && this.props.linkPath){
                            var linkPath = this.props.linkPath(entity);
                            //Preload
                            if(this.props.preload){
                                let route = getDetailRoute(linkPath);
                                if(route !== null && this.props.context === CONTEXT_DETAIL){
                                    if(itemPreload < maxPreload){
                                        APIResourceStore.resources[route.resourceId].apiGetOne(entity.id, true);
                                        itemPreload++;
                                    }
                                }
                            }
                        }
                        values.push(
                            this.addTooltip(
                                <div key={index}>
                                    {this.props.modalLinks && <OpenModal
                                        instanceId={this.props.resourceId} // si on utilise un instanceId sans "operations" ou "layout" cela risque de planter, alors que la resource on sait qu'elle est bien remplie
                                        id={entity.id}
                                        context={CONTEXT_DETAIL}
                                        modalTitle={valLabel}
                                        flat={true}
                                    />} 
                                    {!this.props.modalLinks && this.props.linkAction && !this.props.linkPath ? (
                                        <a onClick={(e) => {
                                            this.props.linkAction(entity);
                                            e.stopPropagation();
                                        }}>
                                            {valLabel}
                                        </a>
                                    ) : null}
                                    {!this.props.modalLinks && !this.props.linkAction && this.props.linkPath ? (
                                        <Link to={linkPath} onClick={(e) => e.stopPropagation()}>
                                            {valLabel}
                                        </Link>
                                    ) : null}
                                    {!this.props.modalLinks && !this.props.linkAction && !this.props.linkPath ? (
                                        <Link
                                            to={'/resource/' + this.resource.instanceId + '/' + entity.id + '/detail'}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            {valLabel}
                                        </Link>
                                    ) : null}
                                </div>,
                                entity, 
                                index
                            )
                        );
                    } else {
                        values.push(this.addTooltip(<div key={index}>{valLabel}</div>, entity, index));
                    }
                });
                promises.push(promise);
            });
            Promise.all(promises).then(() => {
                this.setState({ value: <div style={{display: 'inline-block'}}>{values}</div> });
            });
        }
        else if (!this.props.entity) {
            if (!value) {
                this.setState({ value: '' });
            }
            else {
                this.resource.getItemFromResourcePath(value, false, onlyProperties.length ? onlyProperties : null).then((entity) => {
                    if (entity) {
                        let newValue;
                        if (this.props.links === true) {
                            let link = null;
                            if(this.props.modalLinks === true){
                                link = <OpenModal
                                    instanceId={this.props.instanceId ?? this.props.resourceId}
                                    id={entity.id}
                                    context="detail"
                                    modalTitle={entity[this.props.resourceLabel]}
                                    flat={true}
                                />
                            }else{
                                if(!this.props.linkAction && this.props.linkPath){
                                    var linkPath = this.props.linkPath(entity);
                                    //Preload
                                    if(this.props.preload) {
                                        let route = getDetailRoute(linkPath);
                                        if (route !== null && this.props.context === CONTEXT_DETAIL) {
                                            APIResourceStore.resources[route.resourceId].apiGetOne(entity.id, true);
                                        }
                                    }
                                }
                                if(this.props.linkAction && !this.props.linkPath){
                                    link = <a onClick={() => this.props.linkAction(entity)}>{entity[this.props.resourceLabel]}</a>
                                }
                                if(!this.props.linkAction && this.props.linkPath){
                                    link = <Link to={linkPath}>{entity[this.props.resourceLabel]}</Link>
                                }
                                if(!this.props.linkAction && !this.props.linkPath){
                                    link = <Link to={'/resource/' + this.resource.instanceId + '/' + entity.id + '/detail'}>{entity[this.props.resourceLabel]}</Link>
                                }
                            }
                            newValue = link;
                        } else {
                            if (!entity[this.props.resourceLabel]) {
                                console.error('missing resource label', this.props.resourceLabel, 'on', this.resource.instanceId, entity.id);
                            }
                            newValue = entity[this.props.resourceLabel] || '';
                        }
                        this.setState({ value: this.addTooltip(newValue, entity) });
                    }
                });
            }
        }
        else {
            if (Array.isArray(this.props.entity[this.props.resourceLabel])) {
                let valueComponents = [];
                for (let i in this.props.entity[this.props.resourceLabel]) {
                    let val = this.props.entity[this.props.resourceLabel][i] ? this.props.entity[this.props.resourceLabel][i] : '-';
                    valueComponents.push(this.addTooltip(<div key={i}>{val}</div>, this.props.entity, i))
                }
                this.setState({ value: <div>{valueComponents}</div> });
            }
            else {
                this.setState({ value: this.addTooltip(this.props.entity[this.props.resourceLabel], this.props.entity) });
            }
        }
    }


    render() {
        return (
            (this.props.flat || this.props.plainText) ?
                this.state.value :
                <div
                    className={
                        (this.state.issueButton
                            ? "with-issue-button"
                            : "")
                         + " " + this.props.className
                    }
                    style={this.props.style}
                >
                    <DisplayTextField
                        fieldName={this.props.label + (this.props.required ? ' *' : '')}
                        value={this.state.value}
                    />
                    {this.state.issueButton ? (
                        <IssueButton field={this.props.label} issueButton={this.state.issueButton} entity={this.props.parentEntity} />
                    ) : null}
                </div>
        );
    }
}
EntityDisplay.propTypes = {
    /** 
     * En mode affichage on ne devrait pas avoir besoin de cette prop, mais dans de rares cas il
     * peut être intéressant d'afficher cette information malgré tout (ex: un champ display en mode edition)
     */
    required: PropTypes.bool,
    className: PropTypes.string,
    /** Fonction qui prend une entité en paramètre et renvoie une string à afficher en tooltip */
    tooltip: PropTypes.func,
    /** Champs par défaut à récupérer */
    defaultFields: PropTypes.arrayOf(PropTypes.string),
    /** Champs à récupérer obligatoirement du back */
    neededFields: PropTypes.arrayOf(PropTypes.string),
}
