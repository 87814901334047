import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalContent } from '../../Modal/ModalContent';
import { Typography } from '@material-ui/core';
import { EntityForm } from '../../Forms/EntityForm/EntityForm';
import APIResourceStore from '../../../Store/APIResourceStore';
import ParameterStore from '../../../Store/ParameterStore';
import { mitigationActionsMap, MODEL_FIELDS } from '../../../Admin/ModelAdmin';
import { TableDisplay } from '../../Display/TableDisplay/TableDisplay';
import { getIdFromIri } from '../../../Services/utils';
import { ActionLink } from '../../ActionLink/ActionLink';

const fieldsByRelationName = (relationName, readonly, modifier = "model") => {
    const fieldsConfigModel = {
        fieldsToShow: {
            models: [
                'businessSponsor',
                'modelStatus',
                'upstreamModels',
                'downstreamModels',
                'foregroundModelRelations',
                'isLegacy',
            ],
            modelUses: ['modelUsesEntities'],
            implementations: ['firstImplementationDate', 'productionRun'],
            coveringMitigationActions: ['mitigationActions', 'coveringMitigationActionsEntities'],
            lastTiering: ['tieringsEntities', 'lastTiering'],
        },
        sourceFields: {
            models: MODEL_FIELDS(),
            modelUses: {
                modelUsesEntities: {
                    title: 'Model uses',
                    display: (field, value) => {
                        return (
                            <TableDisplay
                                filter={false}
                                rows={value}
                                cols={[
                                    { 
                                        label: 'ID',
                                        field: 'id',
                                        display: (field, value, entity) => (
                                            <ActionLink to={`/resource/model_uses/${entity.id}/detail`} target="_blank">
                                                {value}
                                            </ActionLink>
                                        ),
                                    },
                                    { label: 'Use', field: 'useLabel' },
                                    {
                                        label: 'Detailed use',
                                        field: 'detailedUseLabel',
                                    },
                                    { 
                                        label: 'Application date',
                                        field: 'applicationDate',
                                    },
                                ]}
                            />
                        );
                    },
                },
            },
            implementations: MODEL_FIELDS(),
            coveringMitigationActions: {
                mitigationActions: {
                    title: 'Mitigation actions',
                    type: 'bool',
                    params: {
                        mapping: mitigationActionsMap,
                    },
                },
                coveringMitigationActionsEntities: {
                    title: 'Mitigation actions',
                    displayCondition: (entity) => !entity.mitigationActions,
                    display: (field, value) => {
                        // On affiche les mitigations closed à la fin du tableau.
                        const mitigationsNotClosed =
                            value?.filter((v) => v.status !== ParameterStore('MITIGATION_ACTION_STATUS_CLOSED')) || [];
                        const mitigationsClosed =
                            value?.filter((v) => v.status === ParameterStore('MITIGATION_ACTION_STATUS_CLOSED')) || [];

                        return (
                            <TableDisplay
                                filter={false}
                                rows={[...mitigationsNotClosed, ...mitigationsClosed]}
                                cols={[
                                    {
                                        label: 'ID',
                                        field: 'id',
                                        display: (field, value, entity) => (
                                            <ActionLink to={`/resource/mitigation_actions/${entity.id}/detail`} target="_blank">
                                                {value}
                                            </ActionLink>
                                        ),
                                    },
                                    {
                                        label: 'Mitigation measure type',
                                        field: 'mitigationMeasureTypeString',
                                    },
                                    {
                                        label: 'Mitigation models',
                                        field: 'reserveModels',
                                        type: 'entity',
                                        params: {
                                            resource: 'models',
                                            displayField: 'name',
                                            links: true,
                                            modalLinks: true,
                                            multi: true,
                                        },
                                    },
                                ]}
                            />
                        );
                    },
                },
            },
            lastTiering: {
                tieringsEntities: {
                    title: 'Tierings',
                    display: (field, value, entity) => {
                        value = value?.filter(v => v.id === (entity.lastTiering ? parseInt(getIdFromIri(entity.lastTiering)) : null));
                        return (
                            <TableDisplay
                                filter={false}
                                rows={value}
                                cols={[
                                    {
                                        label: 'ID',
                                        field: 'id',
                                        display: (field, value, entity) => (
                                            <ActionLink to={`/resource/tierings/${entity.id}/detail`} target="_blank">
                                                {value}
                                            </ActionLink>
                                        ),
                                    },
                                    {
                                        label: 'Metrics',
                                        field: 'metricsFinal',
                                    },
                                    {
                                        label: 'Materiality quantification date',
                                        field: 'materialityQuantifyingDateFinalString',
                                    },
                                    {
                                        label: 'Quantified materiality',
                                        field: 'quantifiedMaterialityFinal',
                                    },
                                    {
                                        label: 'Materiality level',
                                        field: 'materialityLevelFinal',
                                    },
                                ]}
                            />
                        );
                    },
                },
                lastTiering: {
                    // Sert juste à faire remonter la valeur dans l'appel api du back pour l'utiliser au dessus
                    display: () => null,
                }
            },
        },
    }
    const fieldsConfigSpecificFramework = {
        fieldsToShow: {
            models: [
                'modelType',
            ],
            modelUses: ['modelUsesEntities'],
            implementations: ['productionRun'],
            coveringMitigationActions: [],
            lastTiering: ['tieringsEntities', 'lastTiering'],
        },
        sourceFields: {
            models: MODEL_FIELDS(),
            modelUses: {
                modelUsesEntities: {
                    title: 'Model uses',
                    display: (field, value) => {
                        return (
                            <TableDisplay
                                filter={false}
                                rows={value}
                                cols={[
                                    {
                                        label: 'ID',
                                        field: 'id',
                                        display: (field, value, entity) => (
                                            <ActionLink to={`/resource/model_uses/${entity.id}/detail`} target="_blank">
                                                {value}
                                            </ActionLink>
                                        ),
                                    },
                                    { label: 'Use', field: 'useLabel' },
                                ]}
                            />
                        );
                    },
                },
            },
            implementations: MODEL_FIELDS(),
            coveringMitigationActions: {
            },
            lastTiering: {
                tieringsEntities: {
                    title: 'Tierings',
                    display: (field, value, entity) => {
                        value = value?.filter(v => v.id === (entity.lastTiering ? parseInt(getIdFromIri(entity.lastTiering)) : null));
                        return (
                            <TableDisplay
                                filter={false}
                                rows={value}
                                cols={[
                                    {
                                        label: 'ID',
                                        field: 'id',
                                        display: (field, value, entity) => (
                                            <ActionLink to={`/resource/tierings/${entity.id}/detail`} target="_blank">
                                                {value}
                                            </ActionLink>
                                        ),
                                    },
                                    {
                                        label: 'Materiality quantification date',
                                        field: 'materialityQuantifyingDateFinalString',
                                    },
                                    {
                                        label: 'Quantified materiality',
                                        field: 'quantifiedMaterialityFinal',
                                    },
                                ]}
                            />
                        );
                    },
                },
                lastTiering: {
                    // Sert juste à faire remonter la valeur dans l'appel api du back pour l'utiliser au dessus
                    display: () => null,
                }
            },
        },
    }

    const fields = {
        models: {},
        modelUses: {},
        implementations: {},
        coveringMitigationActions: {},
        lastTiering: {},
    };

    const fieldsConfig = modifier === 'specific_framework' ? fieldsConfigSpecificFramework : fieldsConfigModel;

    for (let fieldId in fieldsConfig.sourceFields[relationName]) {
        if (fieldsConfig.fieldsToShow[relationName].includes(fieldId)) {
            fields[relationName][fieldId] = fieldsConfig.sourceFields[relationName][fieldId];
            if (readonly) fields[relationName][fieldId].editConditions = () => false;
            if (relationName === 'models')
                // comme on prend tout MODEL_FIELDS on doit être un peu plus strict
                delete fields[relationName][fieldId].display;
        }
    }

    return {
        fieldsToShow: fieldsConfig.fieldsToShow[relationName],
        fields: fields[relationName] ?? {},
    };
};

export const CurrentValuesForm = (props) => {
    const { entity, readonly } = props;
    const relationName = props.relationName === 'model' ? 'models' : props.relationName;

    const { fieldsToShow, fields } = fieldsByRelationName(relationName, readonly, entity.specificFramework ? "specific_framework" : 'model');

    const [entityState, setEntityState] = useState({});
    const [loading, setLoading] = useState(false);

    const resourceId = 'models'; /** @todo en fonction de relationName ? */

    useEffect(() => {
        const loadEntity = async () => {
            const loaded = await APIResourceStore.resources?.[resourceId]?.apiGetOne(entity.id, true, fieldsToShow);
            console.log('loaded : ', loaded);
            setEntityState({ ...loaded });
        };

        loadEntity();
    }, [resourceId, entity]);

    const updateEntity = async (fromPrevious) => {
        setLoading(true);
        const entityUpdated = fromPrevious();
        const e_ = {};
        for (let f of fieldsToShow) { // NB: ne fonctionne pas avec les champs "xxEntities"
            e_[f] = entityUpdated[f];
        }
        const updated = await APIResourceStore.resources?.[resourceId]?.apiPut({ id: entity.id, ...e_ });
        setLoading(false);
        return updated;
    };
    const onUpdateEntityState = async (fromPrevious) => {
        const entity_ = readonly ? fromPrevious : await updateEntity(fromPrevious);
        setEntityState(entity_);
    }

    return (
        <ModalContent className="resource-detail critical-values-modal">
            <Typography component="h2" variant="h6" style={{marginTop: "15px"}}>
                Critical values :
            </Typography>
            <EntityForm
                entity={entityState}
                fields={fields}
                onUpdate={onUpdateEntityState}
                loading={loading}
                resourceId={resourceId}
            />
        </ModalContent>
    );
};
CurrentValuesForm.propTypes = {
    entity: PropTypes.object,
    relationName: PropTypes.string,
    readonly: PropTypes.bool,
};
